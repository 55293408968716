import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { ConfirmPassword } from '../utils/realmFunctions'
import { toast } from 'react-toastify'

export default function ResetPassword( props ) {
    
    const [ tokenId, setTokenId ] = useState( undefined )
    const [ token, setToken ] = useState( undefined )
    const [ errors, setErrors ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    
    useEffect( () => {
        let query = queryString.parse( window.location.search ) 
        
        if( query.token ) {
            setToken( query.token )
        } else { 
            setToken( false )
        }

        if( query.tokenId ) {
            setTokenId( query.tokenId )
        } else { 
            setTokenId( false )
        }
    }, [ window.location.search ] )

    const resetPasswordHandler = async () => {
        let password = document.getElementById( 'resetPassword' ).value
        let passwordConfirmation = document.getElementById( 'resetPasswordConfirmation' ).value

        if( errors ) setErrors( false )
        setLoading( true )

        if( !password || password === '' || !passwordConfirmation || passwordConfirmation === '' ) {
            setErrors( 'Please enter all fields' )
            return
        }

        if( password !== passwordConfirmation ) {
            setErrors( 'Entered passwords do not match' )
            return
        }
        
        let res = await ConfirmPassword( token, tokenId, password )
        
        if( res && res.error ) {
            setErrors( res.error )
            return
        }

        setTimeout( () => {
            setLoading(false)
            toast( "Password successfully changed!" )
            props.history.push( '/' )
        }, 1000)
    }

    if( token === false && tokenId === false ) {
        <main style={{marginTop:150,marginRight:'10%',marginLeft:'10%',color:'white'}}>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </main>
    }

    if( token === false && tokenId === false ) {
        <main style={{marginTop:150,marginRight:'10%',marginLeft:'10%',color:'white'}}>
            There was an error processing your password reset.
        </main>
    }

    return (
        <main style={{marginTop:150,marginRight:'10%',marginLeft:'10%',color:'white'}}>
            <div>
                <span style={{color:'#AFB9CC',fontSize:'1.4rem'}}>
                    Please reset your password
                </span>
            </div>
            <div className="form-group mt-2 w-25" style={{color:"white"}}>
                <label for="exampleInputPassword1">Password</label>
                <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="password" className="form-control" id="resetPassword"></input>
            </div>
            <div className="form-group mt-2 w-25" style={{color:"white"}}>
                <label for="exampleInputPassword1">Re-enter password</label>
                <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="password" className="form-control" id="resetPasswordConfirmation"></input>
            </div>
            <div className='d-flex justify-content-between mt-4 w-25'>
                <button type="button" class="btn btn-jfa-cancel" onClick={ () => props.history.push( '/' ) }>Cancel</button>
                <button type="button" class="btn btn-primary" onClick={resetPasswordHandler} > Reset Password </button>
            </div>
            { errors && errors }
        </main>
    )
}
import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import { GetHistoricalDividends } from '../utils/realmFunctions'
import moment from 'moment'

export default function StockDetailsPayoutChart( props ) {

    const { timeFrame } = props
    const [ payoutChart, setPayoutChart ] = useState( false )

    const { createPayoutChart } = props.globalContext

    useEffect( () => {
        getHistoricalPayout()
    }, [ timeFrame, props.match.params.stockSymbol ] )

    const getHistoricalPayout = async () => {
        let startDate = moment().add( timeFrame, 'days' ).format( 'YYYY-MM-DD' )
        let endDate = moment().format('YYYY-MM-DD')

        const historicalPayout = await GetHistoricalDividends( props.match.params.stockSymbol, startDate, endDate )

        if( historicalPayout.status !== 200 ) {
            //handle error
            return
        }

        // //Create our price chart data
        let payoutChartData = createPayoutChart( historicalPayout.body )
        
        // //Update the price chart
        if( payoutChart ) payoutChart.destroy()
        var ctx = document.getElementById( `PayoutChart` ).getContext('2d')
        var myChart = new Chart(ctx, payoutChartData )
        setPayoutChart( myChart )
    }

    return (
        <div style={{width:'50rem'}}>
            <div>
                <canvas id={ `PayoutChart` }></canvas>
            </div>
        </div>
    )
}
import React, { useEffect } from 'react'

export default function HomepageIndexs( props ) {

    const { user } = props.globalContext.state
    const { homepageIndexes } = props.globalContext.state
    const { getMajorIndexes } = props.globalContext

    useEffect( () => {
        getMajorIndexes()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user ] )

    if( !homepageIndexes ) return <main>Loading...</main>

    const setIndexName = ( symbol ) => {
        if( symbol === '^DJI' ) return 'DOW J'
        if( symbol === '^IXIC' ) return 'NASDAQ'
        if( symbol === '^GSPC' ) return 'S&P 500'
        if( symbol === '^RUATR' ) return 'Russel 3000'
        return symbol
    }

    return ( 
            <div style={{paddingTop:10}}>
                <div className='d-flex justify-content-around'>
                { homepageIndexes.map( index => {
                    if( index.change < 0 ) {
                        return (
                            <div key={index.symbol} class="card mb-1" style={{color:"#181F32",backgroundColor:"#D0765D",minWidth:'10rem',maxWidth:'25rem'}}>
                                <div class="" style={{padding:'.5rem'}}>
                                    <div class="card-text d-flex justify-content-between">
                                        <span style={{fontWeight:'600',fontSize:'1rem'}}>{ setIndexName( index.symbol ) }</span>
                                        <span>{ index.change.toFixed( 2 ) }</span>
                                        <span>{ index.changesPercentage.toFixed( 2 ) }%</span>
                                    </div>
                                    <div style={{fontSize:'1.5rem'}} className='d-flex justify-content-end'>
                                        - ${ index.price.toLocaleString() }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if( index.change >= 0 ) {
                        return (
                            <div key={index.symbol} class="card mb-1" style={{color:"#181F32",backgroundColor:"#56AF66",minWidth:'10rem',maxWidth:'25rem'}}>
                                <div style={{padding:'.5rem'}}>
                                    <div class="card-text d-flex justify-content-between">
                                        <span style={{fontWeight:'600',fontSize:'1rem'}}>{ setIndexName( index.symbol ) }</span>
                                        <span>+{ index.change.toFixed( 2 ) }</span>
                                        <span>{ index.changesPercentage.toFixed( 2 ) }%</span>
                                    </div>
                                    <div style={{fontSize:'1.5rem',fontWeight:'600'}} className='d-flex justify-content-end'>
                                        + ${ index.price.toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    
                })}
            </div>
        </div> 
    )
}
import React, { useEffect } from 'react'
import * as Realm from 'realm-web'

export default function GoogleRedirect( props ) {

    console.log( 'here')
    useEffect( () => {
        Realm.handleAuthRedirect()
    } )

    return (
        <div>asdfasd</div>
    )
}
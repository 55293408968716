import React, { useEffect, useState } from 'react'
import { ArrowRightCircle, CheckCircle, PlusSquare, Trash2 } from 'react-feather'
import $ from 'jquery'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
export default withRouter( AddSymbolToWatchlist )


function AddSymbolToWatchlist( props ) {
    const { userWatchlistsByStockPresence } = props.userContext.state
    const { addToWatchlist, removeFromWatchlist, getUserWatchlistsAndDetermineIfSelectedStockInWatchlist } = props.userContext
    const { selectedStock, searchHeldOpenForModal } = props.globalContext.state
    const { setSearchHeldOpenForModal, setSelectedWatchlist } = props.globalContext

    useEffect( () => {
        if ( !userWatchlistsByStockPresence || ( userWatchlistsByStockPresence.length > 0 && userWatchlistsByStockPresence[ 0 ].symbol != selectedStock.symbol ) ) getUserWatchlistsAndDetermineIfSelectedStockInWatchlist( selectedStock.symbol )
    }, [ userWatchlistsByStockPresence, selectedStock ] )

    const addToWatchListHandler = async ( watchlistId ) => {
        let res = await addToWatchlist( selectedStock.symbol, watchlistId )

        if( res.error ) {
            toast( res.error )
            return
        }

        await getUserWatchlistsAndDetermineIfSelectedStockInWatchlist( selectedStock.symbol )
    }

    const removeFromWatchlistHandler = async ( watchlistStockId, watchlistId ) => {
        let res = await removeFromWatchlist( watchlistStockId, watchlistId )

        if( res.error ) {
            toast( res.error) 
            return
        }

        await getUserWatchlistsAndDetermineIfSelectedStockInWatchlist( selectedStock.symbol )
    }
    
    const routeToWatchlistHandler = async ( watchlistId ) => {
        let index = userWatchlistsByStockPresence.findIndex( a => a._id.toString() === watchlistId )
        await setSelectedWatchlist( { index: index, id: watchlistId } )
        $( '#addSymbolToWatchlistModal' ).modal('toggle')
        props.history.push( `/watchlists` )
    }

    const handleModalClose = () => {
        if( searchHeldOpenForModal ) {
            setSearchHeldOpenForModal( false )
        }
    }

    return (
        <div className="modal fade" id="addSymbolToWatchlistModal" data-backdrop="static" aria-hidden="false" aria-labelledby="addSymbolToWatchlistModalLabel" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body" style={{backgroundColor:"#162033",borderRadius:10}}>
                        <div>
                            <span className='text-center' style={{color:'#28AF61',fontSize:'1.5rem'}}>
                                Add to Watchlist(s)
                            </span>  
                        </div>
                        <table className='table mt-4'>
                            <thead>
                                <tr>
                                    <th className='' style={{color:"white"}}>Watchlist Name</th>
                                    <th style={{color:"white",marginLeft:25}}>In Watchlist?</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{color:'white',marginLeft:0}}>
                                { userWatchlistsByStockPresence && userWatchlistsByStockPresence.map( watchlist => {
                                    return (
                                        <tr>
                                            <td style={{fontSize:'1rem'}}>{ watchlist.name } <ArrowRightCircle size={14} onClick={ () => routeToWatchlistHandler( watchlist._id.toString() ) } /> </td>
                                            <td className=''>
                                                { watchlist.symbolAlreadyExists && 
                                                    <React.Fragment>
                                                        <CheckCircle className='' color="green"/>
                                                    </React.Fragment>
                                                }
                                                { !watchlist.symbolAlreadyExists && <PlusSquare onClick={ 
                                                    () => addToWatchListHandler( watchlist._id.toString() ) 
                                                    } 
                                                /> }
                                            </td>
                                            <td>
                                                { watchlist.symbolAlreadyExists && 
                                                    <Trash2 className='mx-2' onClick={ () => removeFromWatchlistHandler( watchlist.watchlistStockId, watchlist._id.toString(), selectedStock.symbol )} />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className='mt-4'>
                            <button onClick={handleModalClose} type="button" className='btn hover-cursor' style={{backgroundColor:'transparent',color:'#F48737'}} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { GetStockGrades } from '../utils/realmFunctions'
import moment from 'moment'

export default function StockDetailsStockGrades( props ) {

    const [ stockGrades, setStockGrades ] = useState( false )

    useEffect( () => {
        getStockGrades()
    }, [ props.match.params.stockSymbol ] )

    async function getStockGrades() {

        if( stockGrades ) setStockGrades( false )

        let grades = await GetStockGrades( [ props.match.params.stockSymbol ] )

        if( grades.status !== 200 ) {
            //handle error
            setStockGrades( [] )
        }

        setTimeout( () => {
            setStockGrades( grades.body )
        }, 500)
        
    }

    if( !stockGrades ) return (
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    )

    const setGradeContainer = ( grade ) => {
        if( !grade ) return ''
        grade = grade.toLowerCase()

        if( grade === 'hold' ) return 'badge bg-secondary'
        if( grade === 'neutral' ) return 'badge bg-secondary'
        if( grade === 'outperform' ) return 'badge bg-success'
        if( grade === 'buy' ) return 'badge bg-success'
        if( grade === 'underperform' ) return 'badge bg-warning'
        if( grade === 'overweight' ) return 'badge bg-info'
        if( grade === 'underweight' ) return 'badge bg-danger'
        if ( grade === 'market outperform' ) return 'badge bg-success' 
        return ''

    }

    return (
        <div style={{paddingTop:20}} ref={ props.ratingsRef }>
            <div className="d-flex">
                <span className="h4">Ratings</span>
            </div>

            <div style={{maxHeight:250,overflowY:'auto'}}>
                <table className="table table-bordered">
                    <thead>
                        <th className='w-25 mx-2 pe-4'>
                            <span className='mx-2'>Company</span>
                        </th>
                        <th className='w-25'>
                            <span className='mx-2'>Rating</span>
                        </th>
                        <th className='w-25'>
                            <span className='mx-2'>Previous Rating</span>
                        </th>
                        <th className='w-25'>
                            <span className='mx-2'>Date</span>
                        </th>
                    </thead>
                    <tbody className='table table-bordered' style={{color:"#A2ACC3",maxHeight:200,overflowY:'auto'}}>
                        { stockGrades.map( grade => {
                            return (
                                <tr>
                                    <td className='w-25'>
                                        <span className=''>{ grade.gradingCompany }</span>
                                    </td>
                                    <td className='w-25'>
                                        <span className={ setGradeContainer( grade.newGrade ) }>{ grade.newGrade }</span>
                                    </td>
                                    <td className='w-25'>
                                        <span>{ grade.previousGrade }</span>
                                    </td>
                                    <td className='w-25'>
                                        <span>{ moment( grade.date, 'YYYY-MM-DD' ).format( 'MM/DD/YYYY' ) }</span>
                                    </td>
                                </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
import React, {useRef, useEffect} from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Homepage from '../pages/Homepage'
import IncomeStatement from '../pages/IncomeStatement'
import BalanceSheet from '../pages/BalanceSheet'
import CashFlow from '../pages/CashFlowStatement'
import KeyFinancialRatios from '../pages/KeyFinancialRatios'
import StockMetrics from '../pages/StockMetrics'
import StockScreener from '../pages/StockScreener'
import StockCharts from '../pages/StockCharts'
import Portfolios from '../pages/Portfolios'
import SinglePortfolio from '../pages/SinglePortfolio'
import TaxLots from '../pages/TaxLots'
import Graph from '../pages/Graph'
import StockDetails from '../pages/StockDetails'
import Watchlists from '../pages/Watchlists'
import WatchlistDetails from '../pages/WatchlistDetails'
import Indexes from '../pages/Indexes'
import IndexDetails from '../pages/IndexDetails'
import FinancialTranscripts from '../pages/FinancialTranscripts'
import Sectors from '../pages/Sectors'
import SectorDetails from '../pages/SectorDetails'
import Treasuries from '../pages/Treasuries'
import IndividualTreasuries from '../pages/IndividualTreasuries'
import MenuBar from '../components/MenuBar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AddSymbolToWatchlist from '../modals/AddSymbolToWatchlist'
import Industries from '../pages/Industries'
import EconomicIndicatorDetails from '../pages/EconomicIndicatorDetails'
import ETFDetails from '../pages/ETFDetails'
import CommodityDetails from '../pages/CommodityDetails'
import CurrencyDetails from '../pages/CurrencyDetails'
import CryptocurrencyDetails from '../pages/CryptocurrencyDetails'
import Commodities from '../pages/Commodities'
import Currencies from '../pages/Currencies'
import Cryptocurrencies from '../pages/Cryptocurrencies'
import RiskPremiums from '../pages/RiskPremiums'
import Signup from '../modals/Signup'
import SignIn from '../modals/SignIn'
import IndustryDetails from '../pages/IndustryDetails'
import EconomicIndicators from '../pages/EconomicIndicators'
import EarningsCalendar from '../pages/EarningsCalendar'
import UserCalendar from '../pages/Calendar'
import GoogleRedirect from '../pages/GoogleRedirect'
import DividendsCalendar from '../pages/DividendCalendar'
import UserProfile from '../pages/UserProfile'
import DeleteAccount from '../modals/DeleteAccount'
import DeleteWatchlist from '../modals/DeleteWatchlist'
import MarketNews from '../pages/MarketNews'
import ResetPassword from '../pages/ResetPassword'
import StockDetailsRouter from '../pages/SymbolDetails/index'

export default function RouteHandler( props ){
  const { globalContext, userContext, portfolioContext } = props
  const { checkAuthenticationStatus, getUserData, loginAsDefaultUser } = globalContext
  const { loggedIn, user } = globalContext.state

  const signInModal = useRef( null )

  useEffect(() => {
    if( loggedIn === undefined ) checkAuthenticationStatus()
    if( loggedIn === false && !user ) loginAsDefaultUser()
    if( loggedIn === true && !user ) getUserData()
  }, [ user, loggedIn ] )

  if( !user ) {
    return <span>Loading...</span>
  }

  return (
    <main style={{minWidth:'100%',maxWidth:1280}}>
        <BrowserRouter>
            <MenuBar { ...props } />
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTops
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            {/* <Sidebar { ...props } /> */}

            {/* MODALS */}
            <AddSymbolToWatchlist { ...props } userContext={ props.userContext } globalContext={ props.globalContext } />
            <SignIn { ...props } globalContext={ globalContext } signInModal={ signInModal } />
            <Signup { ...props } globalContext={ globalContext } />
            <DeleteAccount { ...props } globalContext={ globalContext } />

            <Switch>
                <Route path="/stock-screener" render={(props) => <StockScreener globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-charts" render={(props) => <StockCharts globalContext={ globalContext } { ...props } />}/>

                {/* PORTFOLIO SCREENS */}
                {/* <Route exact path="/portfolios" render={(props) => <Portfolios globalContext={ globalContext } userContext={ userContext } portfolioContext={ portfolioContext } { ...props } />}/>
                <Route exact path="/portfolios/:portfolioId" render={(props) => <SinglePortfolio globalContext={ globalContext } userContext={ userContext } portfolioContext={ portfolioContext } { ...props } />}/>
                <Route exact path="/portfolios/:portfolioId/:symbol/taxlots" render={(props) => <TaxLots globalContext={ globalContext } userContext={ userContext } portfolioContext={ portfolioContext } { ...props } />}/> */}

                <Route path="/graph" render={(props) => <Graph globalContext={ globalContext } { ...props } />}/>

                {/* INDEXES */}
                <Route path="/indexes/:indexSymbol" render={ ( props ) => <IndexDetails { ...props } globalContext={ globalContext } /> } />
                <Route path="/indexes" render={ ( props ) => <Indexes { ...props } userContext={ userContext } globalContext={ globalContext } /> } />

                {/* MARKET NEWS */}
                <Route path="/market-news" render={ ( props ) => <MarketNews { ...props } userContext={ userContext } globalContext={ globalContext } /> } />
                
                {/* WATCHLISTS */}
                <Route exact path="/watchlists/:watchlistId?" render={ ( props ) => <Watchlists { ...props } globalContext={ globalContext } userContext={ userContext } /> } />

                {/* STOCK DETAILS */}
                <Route path="/stock-details/:stockSymbol" render={ ( props ) => <StockDetailsRouter userContext={ userContext } globalContext={ globalContext } { ...props } /> } />


                {/* <Route exact path="/stock-details/:stockSymbol" render={ ( props ) => <StockDetails userContext={ userContext } globalContext={ globalContext } { ...props } /> }/>
                <Route path="/stock-details/:stockSymbol/balance-sheet" render={(props) => <BalanceSheet globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-details/:stockSymbol/income-statement" render={(props) => <IncomeStatement globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-details/:stockSymbol/cash-flow" render={(props) => <CashFlow globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-details/:stockSymbol/key-ratios" render={(props) => <KeyFinancialRatios globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-details/:stockSymbol/stock-metrics" render={(props) => <StockMetrics globalContext={ globalContext } { ...props } />}/>
                <Route path="/stock-details/:stockSymbol/earnings-transcript/:date" render={(props) => <FinancialTranscripts globalContext={ globalContext } { ...props } />}/> */}

                {/* ETF DETAILS */}
                {/* <Route exact path="/etf-details/:stockSymbol" render={ ( props ) => <ETFDetails userContext={ userContext } globalContext={ globalContext } { ...props } /> }/> */}

                {/* SECTORS PERFORMANCE */}
                {/* <Route exact path="/sectors/:sector?" render={ ( props ) => <SectorDetails globalContext={ globalContext } { ...props } /> } /> */}
                {/* <Route path="/sectors" render={ ( props ) => <Sectors globalContext={ globalContext } { ...props } /> } /> */}

                {/* LIFECYCLE */}
                <Route path="/reset-password" render={ ( props ) => <ResetPassword globalContext={ globalContext } { ...props } /> } />

                {/* INDUSTRIES PERFORMANCE */}
                {/* <Route exact path="/industries/:industry" render={ ( props ) => <IndustryDetails globalContext={ globalContext } { ...props } /> } />
                <Route path="/industries" render={ ( props ) => <Industries globalContext={ globalContext } { ...props } /> } /> */}

                {/* TREASURIES */}
                {/* <Route path="/treasuries/:term" render={ ( props ) => <IndividualTreasuries globalContext={ globalContext } { ...props } /> } />
                <Route path="/treasuries" render={ ( props ) => <Treasuries globalContext={ globalContext } { ...props } /> } /> */}

                {/* ECONOMIC INDICATORS */}
                {/* <Route path="/economic-indicators/:indicator" render={ ( props ) => <EconomicIndicatorDetails globalContext={ globalContext } { ...props } /> } />
                <Route path="/economic-indicators" render={ ( props ) => <EconomicIndicators globalContext={ globalContext } { ...props } /> } /> */}

                {/* COMMODITY DETAILS */}
                {/* <Route path="/commodities/:commodity" render={ ( props ) => <CommodityDetails globalContext={ globalContext } { ...props } /> } />
                <Route path="/commodities" render={ ( props ) => <Commodities globalContext={ globalContext } { ...props } /> } /> */}

                {/* CURRENCY DETAILS */}
                {/* <Route path="/currencies/:currency" render={ ( props ) => <CurrencyDetails globalContext={ globalContext } { ...props } /> } />
                <Route path="/currencies" render={ ( props ) => <Currencies globalContext={ globalContext } { ...props } /> } /> */}

                {/* CRYPTOCURRENCY DETAILS */}
                {/* <Route path="/cryptocurrencies/:cryptocurrency" render={ ( props ) => <CryptocurrencyDetails globalContext={ globalContext } { ...props } /> } />
                <Route path="/cryptocurrencies" render={ ( props ) => <Cryptocurrencies globalContext={ globalContext } { ...props } /> } /> */}

                {/* RISK PREMIUMS */}
                {/* <Route path="/risk-premiums" render= { ( props ) => <RiskPremiums globalContext={ globalContext } { ...props } /> } /> */}

                {/* EARNINGS CALENDAR */}
                <Route path="/earnings-calendar" render={ ( props ) => <EarningsCalendar globalContext={ globalContext } { ...props } /> } />

                {/* DIVIDENDS CALENDAR */}
                <Route path="/dividends-calendar" render={ ( props ) => <DividendsCalendar globalContext={ globalContext } { ...props } /> } />

                {/* USER LOGIN AND SIGNUP */}
                <Route path="/sign-up" render= { ( props ) => <Signup globalContext={ globalContext } /> } />

                {/* USER */}
                <Route path="/calendar" render= { ( props ) => <UserCalendar userContext={ userContext } globalContext={ globalContext } /> } />
                <Route path="/user-profile" render= { ( props ) => <UserProfile userContext={ userContext } globalContext={ globalContext } /> } />

                {/* AUTH REDIRECTS */}
                <Route path="/google-redirect" render= { ( props ) => <GoogleRedirect userContext={ userContext } globalContext={ globalContext } /> } />

                {/* CATCH ALL */}
                <Route path="*" render={(props) => <Homepage globalContext={ globalContext } portfolioContext={ portfolioContext } userContext={ userContext } { ...props } />}/>
            </Switch>
        </BrowserRouter>

        
    </main>
  )
}
import React, { useState, useEffect, useRef } from 'react'
import StockDetailsStockPeers from '../components/StockDetailsStockPeers'
import StockDetailsEtfExposure from '../components/StockDetailsEtfExposure'
import StockDetailsStockGrades from '../components/StockDetailsStockGrades'
import StockDetailsHistoricalDividends from '../components/StockDetailsEarningsAndFinancials'
import StockDetailsStockOverview from '../components/StockDetailsStockOverview'
import StockDetailsHeader from '../components/StockDetailsHeader'
import StockDetailsPriceChart from '../components/StockDetailsPriceChart'
import StockDetailsEpsChart from '../components/StockDetailsEpsChart'
import StockDetailsPageMenu from '../components/StockDetailsPageMenu'
import StockDetailsCharts from '../components/StockDetailsCharts'
import StockDetailsEarningsAndFinancials from '../components/StockDetailsEarningsAndFinancials'
import { GetPortfolioPositions } from '../utils/realmFunctions'
import StockDetailsArticles from '../components/StockDetailsArticles'

export default function StockDetails( props ) {
    const { getStockDetails, calculateReturn, getSymbolType } = props.globalContext
    const { stockDetails, symbolType } = props.globalContext.state
    const { addArticleToRecentlyView } = props.userContext
    const [ visibleSection, setVisibleSection ] = useState( 'PriceChart' )

    const priceChartRef = useRef( null )
    const summaryRef = useRef(null)
    const newsRef = useRef(null)
    const earningsAndFinancialsRef = useRef( null )
    const ratingsRef = useRef(null)
    const stockPeersRef = useRef( null )
    const exposureRef = useRef(null)
    const mounted = useRef( false )

    //This is used to remove the event listener when StockDetails unmounts
    useEffect( () => {
        mounted.current = true;

        return() => {
            window.removeEventListener( 'scroll', handleScroll )
            mounted.current = false;
        }
    }, [])

    useEffect( () => {

        // if( !symbolType || symbolType.symbol != props.match.params.stockSymbol ){
        //     getSymbolType( props.match.params.stockSymbol )
        //     return
        // }
        
        // if( symbolType && symbolType.type === 'etf' ) {
        //     props.history.push( `/etf-details/${ props.match.params.stockSymbol }`)
        //     return
        // }

        window.addEventListener( 'scroll', handleScroll )

    }, [ props.match.params.stockSymbol, symbolType ] )

    const handleScroll = (  ) => {
        try {

            if( window.location.pathname.indexOf( 'stock-details' ) === -1 ) {
                window.removeEventListener( 'scroll', handleScroll )
                return
            }

            const scrollPosition = window.scrollY + 200

            const sectionRefs = [ 
                { section: "Summary", ref: summaryRef },
                { section: "News", ref: newsRef },
                { section: "Ratings", ref: ratingsRef },
                { section: "Exposure", ref: exposureRef },
                { section: 'PriceChart', ref: priceChartRef },
                { section: 'EarningsAndFinancials', ref: earningsAndFinancialsRef },
                { section: 'Peers', ref: stockPeersRef }
            ]

            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;

                if ( ele ) {
                    const { offsetBottom, offsetTop } = getDimensions( ele );

                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });

            setVisibleSection( selected ? selected.section : 'PriceChart' );

        } catch ( e ) {
            window.removeEventListener( 'scroll', handleScroll )
            throw e.toString()
        }
        
    }

    const getDimensions = ( ele ) => {
        if( !ele || !ele.getBoundingClientRect ) {
            return
        }

        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop
        const offsetBottom = offsetTop + height
    
        return {
          height,
          offsetTop,
          offsetBottom,
        };
    };

    return (
        <main style={{marginLeft:'10%',marginRight:'10%',marginTop:150,color:"#AFB9CC"}} tabIndex='0' data-bs-spy="scroll" data-bs-target="#list-example">
            {/* STOCK HEADER */}
            <div>
                <StockDetailsHeader 
                    { ...props }
                    userContext={ props.userContext }
                    priceChartRef={ priceChartRef }
                />
            </div>
            
            {/* Structure for page */}
            <div className="d-flex my-4" style={{zIndex:1}}>
                <div className='col-3'>
                    <StockDetailsPageMenu
                        { ...props }
                        visibleSection={ visibleSection }
                        setVisibleSection={ setVisibleSection }
                        priceChartRef={ priceChartRef }
                        summaryRef={ summaryRef }
                        earningsAndFinancialsRef={ earningsAndFinancialsRef }
                        newsRef={newsRef}
                        ratingsRef={ratingsRef}
                        stockPeersRef={ stockPeersRef }
                        exposureRef={exposureRef}
                    />
                </div>

                <div className='col-9' >
                    {/* CHARTS */}
                    <StockDetailsCharts 
                        { ...props }
                    />

                    {/* SUMMARY */}
                    <div style={{paddingTop:25}} id="list-item-2" ref={ summaryRef }>
                        <div className="d-flex" style={{width:'100%'}}>
                            <span className="h4 col-10">Summary</span>
                        </div>
                        <div>
                            <StockDetailsStockOverview { ...props } />
                        </div>
                    </div>
                    
                    {/* NEWS AND ARTICLES */}
                    <div ref={ newsRef }>
                        <StockDetailsArticles { ...props } newsRef={ newsRef } />
                    </div>
                    

                    {/* EARNINGS & DIVIDENDS */}
                    <StockDetailsEarningsAndFinancials { ...props } earningsAndFinancialsRef={ earningsAndFinancialsRef } />

                    {/* RATINGS */}
                    <StockDetailsStockGrades { ...props } ratingsRef={ ratingsRef } />

                    {/* PEERS */}
                    <StockDetailsStockPeers { ...props } stockPeersRef={ stockPeersRef } />

                    {/* EXPOSURE */}
                    <StockDetailsEtfExposure { ...props } exposureRef={ exposureRef } />
                </div>
            </div>
        </main>
    )
}
import React from 'react';
import GlobalStateContext from './contexts/global/GlobalStateContext'
import AuthContext from './contexts/auth/AuthContext'
import UserContext from './contexts/user/UserContext'
import RouteHandler from './routeHandlers'
import PortfolioContext from './contexts/portfolio/PortfolioContext';

export default function App( props ){
  return (
    <GlobalStateContext.Consumer>
      { globalContext => (
        <AuthContext.Consumer>
          {authContext => (
            <UserContext.Consumer>
              { userContext => (
                <PortfolioContext.Consumer>
                  { portfolioContext => (
                    <RouteHandler globalContext={ globalContext } authContext={ authContext } userContext= { userContext } portfolioContext={ portfolioContext } />
                  )}
                </PortfolioContext.Consumer>
              )}
            </UserContext.Consumer>
          )}
        </AuthContext.Consumer>
      )}
    </GlobalStateContext.Consumer>
  )
}
import React, { useState, useEffect, useRef } from 'react'
import { CheckSquare, ChevronDown, Edit, Circle, Edit2, Edit3, RotateCw, Star, Trash2 } from 'react-feather'
import AddWatchlistModal from '../modals/AddWatchlistModal'
import EditWatchlistModal from '../modals/EditWatchlistModal'
import $ from 'jquery'
import SearchBarForAdditions from '../components/SearchBarForAdditions'
import Chart from 'chart.js'
import DeleteWatchlistModal from '../modals/DeleteWatchlist'
import { toast } from 'react-toastify'

export default function Watchlists( props ) {

    const { allUsersWatchlists, watchlistDetails } = props.userContext.state
    const { setWatchlistDetails, loadUserWatchlists, loadWatchlistStocks, removeFromWatchlist, setPrimaryWatchlist, updateWatchlistDetails, addNewWatchlist } = props.userContext
    const { user, isDefaultUser, watchlistSectorGraph, watchlistCountryGraph, selectedWatchlist } = props.globalContext.state
    const { getWatchlistExposure, setSelectedWatchlist } = props.globalContext
    const [ loading, setLoading ] = useState( false )
    const [ watchlistSectorChart, setWatchlistSectorChart ] = useState( false )
    const [ watchlistCountryChart, setWatchlistCountryChart ] = useState( false )
    const [ editMode, setEditMode ] = useState( false )
    const [ addMode, setAddMode ] = useState( false )

    var firstRun = useRef( true )

    useEffect( () => {
        if( user && !firstRun.current ) {
            if( editMode ) setEditMode( false )
            if( addMode ) setAddMode( false )
            showAccordion( selectedWatchlist.index )
            setSelectedWatchlist( false )
            setWatchlistDetails( false )
            loadUserWatchlists()
        } else {
            firstRun.current = false
        }
    }, [ user ] )

    useEffect( () => {
        if( selectedWatchlist ) {
            loadWatchlistStocks( selectedWatchlist.id, false )
        }
    }, [ selectedWatchlist ] )

    useEffect( () => {
        getWatchlistExposure( selectedWatchlist.id )

        //ensure selected watchlist is open
        showAccordion( selectedWatchlist.index )
    }, [ watchlistDetails ] )

    useEffect( () => {

        if( !selectedWatchlist ) return

        if( !watchlistSectorGraph || !watchlistCountryGraph ) return

        var ctx = document.getElementById( `WatchlistSectorChart${ selectedWatchlist.index }` )
        var ctx2 = document.getElementById( `WatchlistCountryChart${ selectedWatchlist.index }` )

        if( !ctx || !ctx ) {
            setTimeout(() => {
                
            }, 1000);
        }

        ctx.hidden = false
        ctx2.hidden = false
        
        //Update the price chart
        if( watchlistSectorChart ) watchlistSectorChart.destroy()
        if( watchlistCountryChart ) watchlistCountryChart.destroy()

        ctx.getContext( '2d' )
        ctx2.getContext( '2d' )

        var sectorChart = new Chart(ctx, watchlistSectorGraph )
        var countryChart = new Chart(ctx2, watchlistCountryGraph )

        setWatchlistSectorChart( sectorChart )
        setWatchlistCountryChart( countryChart )
        setLoading( false )
        
    }, [ watchlistSectorGraph, watchlistCountryGraph ] )
    
    const checkForUnsavedChanges = ( providedName ) => {
        //get the input
        let watchlistName = document.getElementById( 'watchlistName' )
        let originalWatchlistName = false

        if( !watchlistName ) return true

        watchlistName = watchlistName.value
        
        if( !watchlistDetails ) {
            originalWatchlistName = providedName
        } else {
            originalWatchlistName = watchlistDetails.details.name
        }
        
        if( watchlistName !== originalWatchlistName ) {
            alert( "You have unsaved changes" )
            return
        }

        setEditMode( false )
    }

    const handleNewPrimaryWatchlist = async( watchlistId ) => {
        let res = await setPrimaryWatchlist( watchlistId )

        if( res.error ) {
            toast( res.error )
            return
        }

        toast( "Updated!" )

    }

    const showAccordion = ( index ) => {
        
        if( $( `#collapse${index}` ).hasClass( 'show' ) ) {
            $(`#collapse${index}`).collapse( 'hide' )
            $(`#accordionButton${index}`).addClass( 'collapsed' )
        } else {
            $(`#collapse${index}`).collapse( 'show' )
            $(`#accordionButton${index}`).removeClass( 'collapsed' )
        }
    }

    const handleAccordionClick = async( index, id ) => {
        if( selectedWatchlist && selectedWatchlist.id === id ) {
            watchlistSectorChart.destroy()
            watchlistCountryChart.destroy()
            showAccordion( index )
            setSelectedWatchlist( false )
            setWatchlistDetails( false )
            return
        }
        
        if( selectedWatchlist && selectedWatchlist.id !== id ) {
            //hide it
            showAccordion( selectedWatchlist.index )
        }

        await setLoading( true )

        showAccordion( index )

        //start loading data
        await loadWatchlistStocks( id, false )

        setTimeout (() => {
            setLoading( false )
        }, 500 )

        if( selectedWatchlist && selectedWatchlist.id === id ) {
            setSelectedWatchlist( false )
            props.location.state = false
        } else {
            setSelectedWatchlist( { id: id, index: index } )
            props.location.state = { watchlistId: id, index: index }
        }
    }

    const refreshStocks = async( id, index ) => {
        if( selectedWatchlist && selectedWatchlist.id !== id ) {
            //open the new one
            handleAccordionClick( index, id )
            return
        }

        //hide the charts
        var ctx = false
        var ctx2 = false
        if( selectedWatchlist ) {
            ctx = document.getElementById( `WatchlistSectorChart${ selectedWatchlist.index }` )
            ctx2 = document.getElementById( `WatchlistCountryChart${ selectedWatchlist.index }` )
            ctx.hidden = true
            ctx2.hidden = true
        }
        
        await setLoading( true )
        await loadWatchlistStocks( id, false )
        await getWatchlistExposure( id )

        setTimeout (() => {
            setLoading( false )
            if( ctx && ctx2 ) {
                ctx.hidden = false
                ctx2.hidden = false 
            }
            
        }, 500 )
    }

    const handleStockDeletion = async( symbol, watchlistId ) => {

        let res = await removeFromWatchlist( symbol, watchlistId )

        if( res.status !== 200 ) {
            toast( res.error )
            return
        }

        await getWatchlistExposure( watchlistId )

        toast( 'Stock deleted!' )
        setAddMode( false )
    }

    const saveNewWatchlist = async() => {
        let watchlistName = document.getElementById( 'newWatchlistName2' )

        if( !watchlistName || !watchlistName.value ) {
            toast( "Please enter a Watchlist name" )
            return
        }

        watchlistName = watchlistName.value

        let res = await addNewWatchlist( watchlistName )

        if( res.error ) {
            toast( res.error )
            return
        }

        toast( 'Watchlist Added' )
        setAddMode( false )
    }

    const saveChanges = async( watchlistId ) => {

        let watchlistName = document.getElementById( 'watchlistName' )

        if( !watchlistName ) {
            toast( "We couldn't find the new name" )
            return
        }

        let res = await updateWatchlistDetails( watchlistId, watchlistName.value )

        if( res.error ) {
            toast( res.error )
            return
        }

        toast( 'Changes saved' )
        setEditMode( false )
    }

    if ( !allUsersWatchlists ) return <main>Loading....</main>

    return ( 
        <main style={{marginTop:150,marginLeft:'10%',marginRight:'10%'}}>
            <AddWatchlistModal { ...props } />
            <EditWatchlistModal { ...props } userContext={ props.userContext } selectedWatchlist={ selectedWatchlist } />
            <DeleteWatchlistModal { ...props } watchlistDetails={ watchlistDetails } userProvider={ props.userProvider } globalContext={ props.globalContext } />

            <div style={{color:'#AFB9CC',fontSize:'1.4rem',marginTop:20,marginBottom:20}} className='d-flex justify-content-between'>
                <span style={{color:'#AFB9CC',fontSize:'1.4rem'}}>
                    Watchlists
                </span>

                <div>
                    {/* ADD WATCHLIST BUTTON FOR SIGNED UP USERS */}
                    { !isDefaultUser && <button type="button" className="btn btn-jfa-primary" onClick={ () => setAddMode( true )}>Add Watchlist</button> }

                    {/* ADD WATCHLIST BUTTON FOR DEFAULT USERS */}
                    { isDefaultUser && <button type="button" className="btn btn-jfa-primary" data-toggle="modal" data-target="#signUpModal" >Add Watchlist</button> }
                </div>
            </div>

            { addMode && 
                <div class="accordion" id="accordionExample">
                    <div style={{background:"#1E2A45",width:'100%'}} className="accordion-item">
                        <div style={{backgroundColor: "#253353"}}>
                            <button class="accordion-button collapsed" style={{ backgroundColor:"#253353" }}  id={ `accordionButton` }type="button" data-bs-toggle="klj" data-bs-target={`#collapse` } aria-expanded="false" aria-controls={`collapse`}>
                                <div onClick={ ( e ) => {
                                    e.preventDefault()
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation()
                                }} class="input-group" style={{width:'30vh',marginLeft:10}}>
                                    <input id="newWatchlistName2" type="text" class="form-control" />
                                    <span onClick={ ( e ) => {
                                            e.preventDefault()
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation()
                                            saveNewWatchlist()
                                        }} style={{backgroundColor:'#20A5A1',color:"white",border:'none'}} class="input-group-text" id="basic-addon2">Save</span>
                                </div>

                                <span onClick={ ( e ) => {
                                    e.preventDefault()
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation()
                                    setAddMode( false )
                                }} className='btn hover-cursor' style={{backgroundColor:'transparent',color:'#F48737'}}>
                                    Cancel
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div id="accordionExample">
                { allUsersWatchlists.map( ( watchlist, index ) => {
                    return (
                        <div key={ index } style={{background:"#1E2A45",width:'100%'}} className="accordion-item">
                            <div style={{backgroundColor: "#253353"}} id={watchlist._id.toString()}>
                                <button onClick={ () => {
                                    handleAccordionClick( index, watchlist._id.toString() )
                                } } class="accordion-button collapsed" style={{ backgroundColor:"#253353" }}  id={ `accordionButton${index}` }type="button" data-bs-toggle="klj" data-bs-target={`#collapse${index}` } aria-expanded="false" aria-controls={`collapse${index}` }>

                                    {/* IF EDIT MODE IS DISABLED */}
                                    { ( !editMode || editMode.toString() !== index.toString() ) && <span style={{ color:"#AFB9CC", fontSize:20,marginLeft:10 }} > { watchlist.name } </span> }

                                    {/* IF EDIT MODE IS ENABLED */}
                                    { editMode && editMode.toString() === index.toString() && 
                                        <div onClick={ ( e ) => {
                                            e.preventDefault()
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation()
                                        }} class="input-group" style={{width:'30vh',marginLeft:10}}>
                                            <input type="text" class="form-control" id="watchlistName" defaultValue={ watchlist.name } aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <span onClick={ ( e ) => {
                                                    e.preventDefault()
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    e.stopPropagation()
                                                    saveChanges( watchlist._id.toString() )
                                                }} style={{backgroundColor:'#20A5A1',color:"white",border:'none'}} class="input-group-text" id="basic-addon2">Save</span>
                                        </div>
                                    }

                                    { ( !isDefaultUser && ( !editMode || editMode.toString() !== index.toString() ) ) && 
                                        <Edit onClick={ ( e ) => {
                                            e.preventDefault()
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation()
                                            setEditMode( index.toString() )
                                        }} style={{marginLeft:'1rem'}} size={18} color={'#28AF61'} />
                                    }

                                    { editMode && editMode.toString() === index.toString() && 
                                        <button onClick={ ( e ) => {
                                            e.preventDefault()
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation()
                                            checkForUnsavedChanges( watchlist.name )
                                        }} className='btn hover-cursor' style={{backgroundColor:'transparent',color:'#F48737'}}>
                                            Cancel
                                        </button>
                                    }

                                    { editMode && !watchlist.primary && editMode.toString() === index.toString() &&
                                        <button onClick={ ( e ) => {
                                            e.preventDefault()
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation()
                                            handleNewPrimaryWatchlist( watchlist._id.toString() )
                                        }} className='btn btn-tertiary'>
                                            Make Primary
                                        </button>
                                    }

                                    { watchlist.primary && 
                                        <Star className='ms-auto'
                                            color="gold"
                                            fill="gold"
                                            style={{marginRight:50}}
                                        />
                                    }

                                    { watchlist._id.toString() === watchlistDetails.id &&
                                        <RotateCw 
                                            style={{marginRight:25}} 
                                            color={'#28AF61'} 
                                            size={18} 
                                            onClick={ ( e ) => {
                                                e.preventDefault()
                                                e.nativeEvent.stopImmediatePropagation();
                                                e.stopPropagation()
                                                refreshStocks( watchlist._id.toString(), index )
                                            }}
                                        />
                                    }
                                </button>
                            </div>
                            <div id={`collapse${index}`} class="accordion-collapse collapse" aria-labelledby={ watchlist._id.toString() } data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="d-flex flex-nowrap">
                                        <div className='col-6'>
                                            { watchlist._id.toString() === watchlistDetails.id && loading &&
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            }

                                            { watchlist._id.toString() === watchlistDetails.id && !loading &&
                                                <React.Fragment>
                                                    <div className='d-flex justify-content-center' style={ { color:"#AFB9CC",backgroundColor:"#364362",borderRadius:'10px 10px 10px 10px',padding:"5px 0 5px 0" } }>
                                                        <span className="col-3" style={{borderRight:'1px solid #AFB9CC',paddingLeft:'3.5rem'}}>Symbol</span>
                                                        <span className="col-3 text-center" style={{borderRight:'1px solid #AFB9CC'}}>Price</span>
                                                        <span className="col-3 text-center" style={{borderRight:'1px solid #AFB9CC'}}>Change $</span>
                                                        <span className="col-2 text-center" style={{borderRight:'1px solid #AFB9CC'}}>Change %</span>
                                                        <span className="col-1"></span>
                                                    </div>
                                                    <div>
                                                        { watchlistDetails.stocks.map( ( stock, index ) => {
                                                            return (
                                                                <div key={index} className='d-flex justify-content-center my-2'>
                                                                    <span className="col-3 hover-cursor" style={{color:"#AFB9CC",paddingLeft:'3.5rem' }} onClick={ () => props.history.push( `/stock-details/${ stock.symbol }` )}><b>{ stock.symbol }</b></span>
                                                                    <span style={{color:"#AFB9CC"}} className="col-3 text-center">${ stock.price.toLocaleString() }</span>
                                                                    <span style={{color:"#AFB9CC"}} className="col-3 text-center" >${ stock.change.toFixed( 2 ) }</span>
                                                                    <span style={{color:"#AFB9CC"}} className="col-2 text-center">{ stock.changesPercentage.toFixed( 2 ) }%</span>
                                                                    { !isDefaultUser && <span onClick={ () => handleStockDeletion( stock.watchlistStockId, watchlistDetails.id ) } className="col-1"><Trash2 size={18} color={'#AFB9CC'} className="mx-3"/></span> }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    { !isDefaultUser && 
                                                        <div style={{paddingTop:25}}>
                                                            <button data-toggle="modal" data-target="#deleteWatchlistModal" className="btn btn-danger">Delete Watchlist</button>
                                                        </div>
                                                    }
                                                </React.Fragment>                                         
                                            }

                                            { watchlist._id.toString() !== watchlistDetails.id && !loading &&
                                                <span style={{color:'white'}}>lkjas;dkjf</span>
                                            }
                                            
                                        </div>
                                        <div className='col-6' style={{paddingLeft:20,paddingRight:20,maxHeight:400}}>
                                            <div>
                                                <span style={{color:"#AFB9CC"}}>
                                                    Add a Symbol
                                                </span>
                                            </div>
                                            <div className='mt-3 col-12'>
                                                <SearchBarForAdditions 
                                                    globalContext={ props.globalContext }
                                                    userContext={ props.userContext }
                                                    watchlistId={ watchlist._id.toString() }
                                                    getWatchlistExposure={ getWatchlistExposure }
                                                    isDefaultUser={isDefaultUser}
                                                />
                                            </div>
                                            <div className="d-flex">
                                                <div className='col-5'>
                                                    { loading && 
                                                            <div class="spinner-border text-light" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    }
                                                    <canvas style={{width:550,maxHeight:250}} id={ `WatchlistSectorChart${ index }` }></canvas>
                                                    <div>
                                                        { !loading && watchlistSectorGraph && watchlistSectorGraph.data.labels.map( ( label, count ) => {
                                                            return (
                                                                <div key={count}>
                                                                    <Circle fill={ watchlistSectorGraph.data.datasets[0].backgroundColor[ count ] } color={ watchlistSectorGraph.data.datasets[0].backgroundColor[ count ] } />
                                                                    <span class="badge">{ label }</span>
                                                                </div>
                                                            )
                                                        }) }
                                                    </div>
                                                </div>
                                                <div className='col-2' />
                                                <div className='col-5'>
                                                    { loading && 
                                                            <div class="spinner-border text-light" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    }
                                                    <canvas style={{width:550,maxHeight:250}} id={ `WatchlistCountryChart${ index }` }></canvas>
                                                    <div>
                                                        { !loading && watchlistCountryGraph && watchlistCountryGraph.data.labels.map( ( label, count ) => {
                                                            return (
                                                                <div key={count}>
                                                                    <Circle fill={ watchlistCountryGraph.data.datasets[0].backgroundColor[ count ] } color={ watchlistCountryGraph.data.datasets[0].backgroundColor[ count ] } />
                                                                    <span class="badge">{ label }</span>
                                                                </div>
                                                            )
                                                        }) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </main>
    )
}
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'

export default function DeleteWatchlist( props ) {

    const { watchlistDetails } = props
    const { deleteUserWatchlist } = props.userContext
    const [ loading, setLoading ] = useState( false )

    const handleWatchlistDeletion = async() => {
        setLoading( true )
        let res = await deleteUserWatchlist( watchlistDetails.id )
        
        if( res.error ) {
            toast( res.error )
            return
        }
        
        setTimeout( () => {
            toast( 'Watchlist deleted' )
            setLoading( false )
            $('#deleteWatchlistModal').modal('toggle')
        }, 1000)
        
    }

    return (
        <div class="modal fade" id="deleteWatchlistModal" aria-hidden="true" aria-labelledby="deleteWatchlistModalLabel" tabindex="-1">
            <div class="modal-dialog modal-sm" style={{maxWidth:175,paddingTop:'15vh'}}>
                <div class="modal-content">
                    <div class="modal-body" style={{backgroundColor:"#818DA5",borderRadius:10}}>
                        <div className='text-center'>
                            <div>
                                <span className='text-center'>
                                    Are you sure you want to delete the follow watchlist?
                                </span>    
                            </div>
                            <div style={{marginTop:25}}>
                                <span>
                                    { watchlistDetails && watchlistDetails.details.name }
                                </span>
                            </div>
                            <div style={{marginTop:25}}>
                                <button onClick={ handleWatchlistDeletion } style={{minWidth:100}} className='btn btn-danger'>
                                    { !loading && 'Yes, Delete It' }
                                    { loading && 
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                            <div style={{marginTop:25}}>
                                <button disabled={ loading } style={{minWidth:100}} data-dismiss="modal" className='btn btn-dark'>
                                    No, Keep It
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
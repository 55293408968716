import React, { useState, useEffect } from 'react'

export default function EarningsCalendar( props ) {

    const { earningsHistory } = props.globalContext.state
    const { getEarningsHistory } = props.globalContext

    useEffect( () => {
        if( !earningsHistory) getEarningsHistory()
    }, [ earningsHistory ] )

    console.log( earningsHistory )
    return (
        <main style={{marginLeft:300, marginTop:65}}>

            <div>
                <h4>Earnings Calendar</h4>
            </div>
            <div className='d-flex'>
                <div>
                    <table className='table table-sm'>
                        <thead>
                            <th>Date</th>
                            <th>Symbol</th>
                            <th>Time</th>
                            <th>EPS</th>
                            <th>EPS Estimated</th>
                            <th>Revenue</th>
                            <th>Revenue Estimated</th>
                        </thead>
                        <tbody>
                            { earningsHistory && earningsHistory.map( currency => {
                                return (
                                    <tr>
                                        <td>{ currency.date }</td>
                                        <td onClick={ () => props.history.push( `/stock-details/${ currency.symbol }` ) }>{ currency.symbol }</td>
                                        <td>{ currency.time }</td>
                                        <td>{ currency.eps }</td>
                                        <td>{ currency.epsEstimated }</td>
                                        <td>{ currency.revenue }</td>
                                        <td>${ currency.revenueEstimated && currency.revenueEstimated.toLocaleString() }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </main>
    )
}
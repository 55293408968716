import React, { useState } from 'react'
import $ from 'jquery'

export default function DeleteAccount( props ) {
    const { user } = props.userContext.state
    const { deleteAccount } = props.globalContext
    const [ loading, setLoading ] = useState( false )
    
    const deleteAccountHandler = async () => {
        let result = await deleteAccount()

        $('#deleteAccountModal').modal('toggle')
        window.location.replace( process.env.REACT_APP_HOME_URL )
    }

    return (
        <div class="modal fade" id="deleteAccountModal" aria-hidden="true" aria-labelledby="deleteAccountModalLabel" tabindex="-1">
            <div class="modal-dialog modal-sm" style={{maxWidth:175,paddingTop:'15vh'}}>
                <div class="modal-content">
                    <div class="modal-body" style={{backgroundColor:"#818DA5",borderRadius:10}}>
                        <div className='text-center'>
                            <div>
                                <span className='text-center'>
                                    Are you sure you want to delete your account?
                                </span>    
                            </div>
                            <div style={{marginTop:25}}>
                                <button onClick={ deleteAccountHandler } style={{minWidth:100}} className='btn btn-danger'>
                                    { !loading && 'Yes, Delete It' }
                                    { loading && 
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                            <div style={{marginTop:25}}>
                                <button disabled={ loading } style={{minWidth:100}} data-dismiss="modal" className='btn btn-dark'>
                                    No, Keep It
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
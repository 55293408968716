import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FilterBarForMarketNews from './FilterBarForMarketNews'
import { GetMarketNews } from '../utils/realmFunctions'
import { X, ArrowRight, PlusSquare } from 'react-feather'
import $ from 'jquery'

export default function HomepageMarketNews( props ) {

    const { userArticles } = props.userContext.state
    const { saveArticle, getUserArticles, addArticleToRecentlyView } = props.userContext
    const { user, symbolsForMarketNews } = props.globalContext.state
    const { setSymbolsForMarketNews, executeSearch } = props.globalContext

    const [ marketNews, setMarketNews ] = useState( false )
    const [ watchListNews, setWatchlistNews ] = useState( false )
    const [ showMarketNews, setShowMarketNews ] = useState( true )
    const [ showMyArticles, setShowMyArticles ] = useState( false )

    useEffect( () => {
        getMarketNews()
    }, [ symbolsForMarketNews ])

    const getMarketNews = async () => {
        let symbols = false
        let watchlists = false

        if( symbolsForMarketNews ) {
            symbols = symbolsForMarketNews.symbols.map( a => a.symbol )
            watchlists = symbolsForMarketNews.watchlists.map( a => a.value )
        }
        
        let res = await GetMarketNews( 25, false, symbols, watchlists )

        if( res.status !== 200 ) {
            alert( "There was an error!" )
        }

        setMarketNews( res.body )
    }

    const removeItemFromArray = ( index, symbol ) => {
        let type = symbol?.type
        
        if( type && type === 'allWatchlists' ) {
            setSymbolsForMarketNews( { symbols: [ ...symbolsForMarketNews.symbols ], watchlists: [] } )
            return
        } else {
            let array = [ ...symbolsForMarketNews.symbols ]
            array.splice( index, 1 )
            setSymbolsForMarketNews( { symbols: [ array ], watchlists: [  ...symbolsForMarketNews.watchlists ] } )
            return
        }
    }

    const openArticle = async ( article ) => {
        const newWindow = window.open( article.url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        //console.log( window.open( article.url, "_blank" ) )

        //record that the user click on the article
        await addArticleToRecentlyView( article )
    }

    const saveArticleHandler = async( article ) => {

        let res = await saveArticle( article )
        

    }
    console.log( symbolsForMarketNews )
    return (
        <div style={{width:'50%'}} className="ms-4">
            <div style={{borderBottom:'2px solid #45465C'}}>
                <div style={{minWidth:'100%'}} className="d-flex justify-content-between">
                    <div className='mb-3 d-flex'>
                        <span style={{color:'white',fontSize:'1.5rem',letterSpacing:'.05rem'}}>Market News</span>
                        <div className='hover-cursor mt-2 ms-4' onClick={ () => {
                            props.history.push( '/market-news')
                        }} style={{color:'#24A556',fontSize:'.8rem'}}>
                            <b>View All</b> <ArrowRight size={14} />
                        </div>
                    </div>     
                </div>
                <FilterBarForMarketNews
                    { ...props }
                    setSymbolsForMarketNews={ setSymbolsForMarketNews }
                    symbolsForMarketNews={ symbolsForMarketNews }
                    executeSearch={ executeSearch }
                    getMarketNews={ getMarketNews }
                />
                
                { symbolsForMarketNews && 
                    <div className='mb-3'>
                        { [ ...symbolsForMarketNews.symbols, ...symbolsForMarketNews.watchlists].map( ( symbol, index ) => {
                            return (
                                <span key={index} class="badge" style={{backgroundColor:"#45465C"}}>
                                    { symbol.symbol } 
                                    <X onClick={ () => { removeItemFromArray( index, symbol ) } }/> </span>
                            )
                        })}
                    </div>
                }
            </div>

            <div style={{height:'30rem', overflowY:'scroll'}}>
                { marketNews && marketNews.slice(0,10).map( ( news, index ) => {
                    return (
                        <div key={index} style={{borderBottom:'2px solid #45465C',minHeight:'5rem',color:'white'}}>
                            <div className="mt-3">
                                <span className='hover-cursor' onClick={ () => {
                                    //window.open( news.url, "_blank")
                                    openArticle( news ) 
                                }}>{ news.title }</span>
                                <span className='mx-2 hover-cursor'> 
                                    <PlusSquare onClick={ saveArticleHandler } size={14} />
                                </span>
                            </div>
                            <div className="mt-3">
                                <span style={{marginRight:'1rem'}} className="mt-2">{ news.symbol }</span> 
                                <span style={{marginRight:'1rem'}}>|</span>
                                <span style={{marginRight:'1rem'}}>{ news.site }</span>
                                <span style={{marginRight:'1rem'}}>|</span>
                                <span style={{marginRight:'1rem'}}>{ news.publishedDate.toLocaleString() }</span>
                            </div>
                        </div>
                    )
                }) }
            </div>
        </div>
    )
}
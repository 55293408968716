import React, { useState } from "react";
import { components } from "react-select";
import AsyncSelect from 'react-select/async'

const CustomSelect = ( props ) => {
    const globalContext = props.globalContext
    const { executeSearch } = globalContext
    const [ loading, setLoading ] = useState( false )
    const setSymbolsForMarketNews = props.setSymbolsForMarketNews
    const symbolsForMarketNews = props.symbolsForMarketNews
    const getMarketNews = props.getMarketNews

    let defaultOptions = [
        {
            symbol: "My Watchlist Stocks",
            value: 'all_watchlist_stocks',
            label: "My Watchlist Stocks",
            type: "allWatchlists"
        },
        {
            "symbol": "AAPL",
            "name": "Apple Inc.",
            "price": 165.29,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "AAPL",
            "label": "AAPL - Apple Inc. - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        },
        {
            "symbol": "MSFT",
            "name": "Microsoft Corporation",
            "price": 279.83,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "MSFT",
            "label": "MSFT - Microsoft Corporation - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        }
    ]


    const styles = {
        control: ( provided ) => ({
            ...provided,
            backgroundColor: '#7D8AAA',
            color: '#45465C',
            border: 'none',
            width:'90%'
        }),
        placeholder: ( styles ) => ({
            ...styles,
            color:"#45465C"
        }),
        default: ( styles => ({
            ...styles,
            color: "#45465C"
        })),
        clearIndicator: ( styles ) => ({
            ...styles,
            color: '#45465C'
        }),
        option: ( styles ) => ({
            ...styles,
            color: "black"
        })
    }
    
    const ControlComponent = (props) => {
        return (
                <div styles={ styles.control } class="input-group mb-3 d-flex flex-nowrap">
                    <components.Control { ...props } />
                    
                    { !loading && <span onClick={ async ( e ) => {
                            setLoading( true )
                            await getMarketNews()
                            setLoading( false )
                        }} class="input-group-text hover-cursor"  style={{backgroundColor:'#28AF61',color:"#162033",border:'none',minWidth:'10%'}} id="basic-addon1">Filter</span>
                    }

                    { loading && 
                        <span class="input-group-text hover-cursor" style={{backgroundColor:'#28AF61',color:"#162033",border:'none',minWidth:'10%'}} id="basic-addon1">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </span>
                    }
                </div>
        );
    };

    const updateSelectedItems = ( value ) => {
        if( !value ) {
            setSymbolsForMarketNews( false )
            return 
        }

        if( symbolsForMarketNews ) {
            if ( value.type === 'allWatchlists' ) {
                setSymbolsForMarketNews( { symbols: [ ...symbolsForMarketNews.symbols ], watchlists: [ ...symbolsForMarketNews.watchlists, value ] } )
                return
            } else {
                setSymbolsForMarketNews( { symbols: [ ...symbolsForMarketNews.symbols, value ], watchlists: [ ...symbolsForMarketNews.watchlists ] } )
                return
            }

            return
        }

        if( !symbolsForMarketNews ) {
            if ( value.type === 'allWatchlists' ) {
                console.log( 'here we go')
                setSymbolsForMarketNews( { symbols: [], watchlists: [ value ] } )
                return
            } else {
                setSymbolsForMarketNews( { symbols: [ value ], watchlists: [] } )
                return
            }
            return
        }
    }

    return (
        <div class="mb-3" style={{minWidth:'100%'}}>
            <AsyncSelect
                defaultOptions={ defaultOptions }
                placeholder={'Search...'}
                isClearable
                styles={styles}
                value = { false }
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Control: ControlComponent }}
                loadOptions={ ( value ) => executeSearch( value ) }
                onChange={ ( e ) => {
                    updateSelectedItems( e )
                    
                    return
                }}
            />
        </div>
    );
};
export default CustomSelect;

import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import { earningsAndFinancials } from '../utils/dataKeyMapper'

export default function StockDetailsEarningsAndFinancials( props ) {

    const { earningsAndFinancialsRatios } = props.globalContext.state
    const { getEarningsAndFinancialsRatios, createEarningsAndFinancialChart } = props.globalContext
    const [ timeFrame, setTimeFrame ] = useState( -365 )
    const [ dataPoints, setDataPoints ] = useState( [ 'netIncomePerShare', 'revenuePerShare', 'freeCashFlowPerShare', 'bookValuePerShare' ] )
    const [ earningsAndFinancialsChart, setEarningsAndFinancialsChart ] = useState( false )

    useEffect( () => {
        if( !earningsAndFinancialsRatios ) getEarningsAndFinancialsRatios( timeFrame, props.match.params.stockSymbol )

        if( earningsAndFinancialsRatios && timeFrame ) {
            let chartData = createEarningsAndFinancialChart( dataPoints )

            var ctx = document.getElementById( `EarningsAndFinancialsChart` )

            ctx.hidden = true

            //Update the price chart
            if( earningsAndFinancialsChart ) earningsAndFinancialsChart.destroy()

            ctx.getContext( '2d' )
            var myChart = new Chart(ctx, chartData )
            setEarningsAndFinancialsChart( myChart )
            ctx.hidden = false
        }
    }, [ earningsAndFinancialsRatios ] )

    useEffect( () => {
        getEarningsAndFinancialsRatios( timeFrame, props.match.params.stockSymbol )
    }, [ props.match.params.stockSymbol ] )

    const changeTimeframe = async ( frame ) => {
        await setTimeFrame( frame )
        getEarningsAndFinancialsRatios( frame, props.match.params.stockSymbol )
    }

    const setWatchlistReturnChartStyles = ( frame ) => {
        if( frame === timeFrame ) {
            return 'text-center homepageSelectedTimeFrame hover-cursor' 
        }

        return 'text-center homepageDeselectedTimeFrame hover-cursor'
    }

    return (
        <div style={{width:'100%',paddingTop:25}} ref={ props.earningsAndFinancialsRef }>

            <div className="d-flex" style={{paddingBottom:30}}>
                <span className="h4">Earnings and Financials</span>
            </div>

            <div style={{width:'100%'}}>
                <div className='d-flex' style={{borderBottom: '1px solid #45465C',color:'white',justifyContent:'space-evenly',verticalAlign:'bottom'}}>
                    <span className={setWatchlistReturnChartStyles(-365)} onClick={() => changeTimeframe( -365 ) }>1Y</span>
                    <span className={setWatchlistReturnChartStyles(-730)} onClick={() => changeTimeframe( -730 ) }>2Y</span>
                    <span className={setWatchlistReturnChartStyles(-1095)} onClick={() => changeTimeframe( -1095 ) }>3Y</span>
                    <span className={setWatchlistReturnChartStyles(-1825)} onClick={() => changeTimeframe( -1825 ) }>5Y</span>
                    <span className={setWatchlistReturnChartStyles(-3650)} onClick={() => changeTimeframe( -3650 ) }>10Y</span>
                </div>
                <canvas id={ `EarningsAndFinancialsChart` }></canvas>
            </div>
        </div>
    )
}
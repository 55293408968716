import React, { useState, useEffect } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'

export default function HomepageMarketMovers( props ) {

    const { getMostActive, getHighestGainers, getHighestLosers } = props.globalContext
    const { mostActive, highestGainers, highestLosers } = props.globalContext.state
    const [ option, setOption ] = useState( 'active' )

    useEffect( () => {
        if( option ) {
            if( option === 'active' ) getMostActive()
            if( option === 'gainers' ) getHighestGainers() 
            if( option === 'losers' ) getHighestLosers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ option ])

    const setWatchlistReturnChartStyles = ( frame, index ) => {
        if( frame === option ) {
            if( index === 0 ) return 'homepageMarketMoversSelected hover-cursor' 
            else {
                return 'homepageMarketMoversSelected hover-cursor mx-2' 
            }
        }

        if( index === 0 ) return 'hover-cursor'
        else {
            return 'hover-cursor mx-2'
        }
    }

    return (
        <div style={{width:'50%'}}>
            <div style={{borderBottom:'2px solid #45465C',paddingBottom:0}}>
                <div style={{minWidth:'100%'}} className="d-flex justify-content-between">
                    <div>
                        <span style={{color:'white',fontSize:'1.5rem',letterSpacing:'.05rem'}}>Market Movers</span>
                    </div>
                </div>

                <div className='d-flex' style={{color:'white',verticalAlign:'bottom',width:'100%',marginTop:15}}>
                        <div style={{width:'20%'}} className={setWatchlistReturnChartStyles( 'active', 0 )} >
                            <span style={{marginBottom:10}} onClick={() => setOption( 'active', 0 ) }>Most Active</span>
                        </div>

                        <div style={{width:'20%',paddingBottom:10}} className={ setWatchlistReturnChartStyles( 'gainers', 1 ) } >
                            <span  onClick={() => setOption( 'gainers', 1 ) }>Gainers</span>
                        </div>

                        <div style={{width:'20%',paddingBottom:10}} className={setWatchlistReturnChartStyles( 'losers', 2 )} > 
                            <span  onClick={() => setOption( 'losers', 2 ) }>Losers</span>
                        </div>
                    </div>
            </div>
            <div>
                { option && option === 'active' &&
                    <div>
                        <div className='mt-3' style={{ height:'30rem', overflowY:'scroll' }}>

                            { !mostActive && 
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }

                            { mostActive && mostActive.map( ( stock, index ) => {
                                return (
                                    <div key={index} className='d-flex my-2' style={{color:'white', justifyContent:'space-between',height:'2rem', borderBottom: '1px solid #45465C'}}>
                                        <div style={{ width:'10%' }}>
                                            <span className='hover-cursor symbol-container text-center' onClick={ () => props.history.push( `/stock-details/${ stock.symbol }` )} style={{fontSize:'.85rem',width:'100%'}}>
                                                <b>{ stock.symbol }</b>
                                            </span>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <span>{ stock.name }</span>
                                        </div>
                                        <div>
                                            <span style={{width:'10%',fontSize:'1.1rem',color: `${ stock.change > 0 ? 'green' : '#ff8c8c' }`}}>${ stock.price.toFixed( 2 ) }</span>
                                        </div>
                                        <div style={{width:'20%'}} className='d-flex'>

                                            {
                                                stock.change < 0 && 
                                                <span class="badge rounded-pill bg-danger">{ stock.changesPercentage.toFixed( 2 ) }%</span>

                                                // <span className={ 'symbol-container-decline text-center' } style={{fontSize:'1rem',width:'80%'}}>
                                                //     <ArrowDown size={12} />
                                                //     { stock.changesPercentage.toFixed( 2 ) }%
                                                // </span>
                                            }

                                            {
                                                stock.change >= 0 && 
                                                <span class="badge rounded-pill bg-success">{ stock.changesPercentage.toFixed( 2 ) }%</span>


                                                // <span className={ 'symbol-container-increase text-center' } style={{width:'80%'}}>
                                                //     <ArrowUp size={12} />
                                                //     { stock.changesPercentage.toFixed( 2 ) }%
                                                // </span>
                                            }

                                            {/* <span className={ `${ stock.change < 0 ? 'symbol-container-decline text-center' : 'symbol-container-increase text-center'}` } style={{fontSize:'1.1rem',width:'70%'}}>{ stock.changesPercentage.toFixed( 2 ) }%</span> */}
                                        </div>
                                        
                                    </div>
                                )
                            })}
                        </div>
                        {/* <div className='hover-cursor mt-2' onClick={ () => {
                            handleWatchlistRoute( watchlistDetails.id )
                        }} style={{color:'#24A556',fontSize:'.8rem'}}>
                            <b>View All</b> <ArrowRight size={14} />
                        </div> */}
                    </div>
                }

                { option && option === 'gainers' &&
                    <div>
                        <div className='mt-3' style={{ height:'20rem', overflowY:'scroll' }}>

                            { !highestGainers && 
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }

                            { highestGainers && highestGainers.map( stock => {
                                return (
                                    <div className='d-flex mt-2' style={{color:'white', justifyContent:'space-between',height:'2rem', borderBottom: '1px solid #45465C'}}>
                                        <div style={{ width:'10%' }}>
                                            <span className='hover-cursor symbol-container text-center' onClick={ () => props.history.push( `/stock-details/${ stock.symbol }` )} style={{fontSize:'.85rem',width:'100%'}}>
                                                <b>{ stock.symbol }</b>
                                            </span>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <span>{ stock.name }</span>
                                        </div>
                                        <div>
                                            <span style={{width:'10%',fontSize:'1.1rem',color: `${ stock.change > 0 ? 'green' : '#ff8c8c' }`}}>${ stock.price.toFixed( 2 ) }</span>
                                        </div>
                                        <div style={{width:'20%'}} className='d-flex'>

                                            {
                                                stock.change < 0 && 
                                                <span class="badge rounded-pill bg-danger">{ stock.changesPercentage.toFixed( 2 ) }%</span>
                                                // <span className={ 'symbol-container-decline text-center' } style={{fontSize:'1.1rem',width:'80%'}}>
                                                //     <ArrowDown size={12} />
                                                //     { stock.changesPercentage.toFixed( 2 ) }%
                                                // </span>
                                            }

                                            {
                                                stock.change >= 0 && 
                                                <span class="badge rounded-pill bg-success">{ stock.changesPercentage.toFixed( 2 ) }%</span>
                                                // <span className={ 'symbol-container-increase text-center' } style={{fontSize:'1.1rem',width:'80%'}}>
                                                //     <ArrowUp size={12} />
                                                //     { stock.changesPercentage.toFixed( 2 ) }%
                                                // </span>
                                            }

                                            {/* <span className={ `${ stock.change < 0 ? 'symbol-container-decline text-center' : 'symbol-container-increase text-center'}` } style={{fontSize:'1.1rem',width:'70%'}}>{ stock.changesPercentage.toFixed( 2 ) }%</span> */}
                                        </div>
                                        
                                    </div>
                                )
                            })}
                        </div>
                        {/* <div className='hover-cursor mt-2' onClick={ () => {
                            handleWatchlistRoute( watchlistDetails.id )
                        }} style={{color:'#24A556',fontSize:'.8rem'}}>
                            <b>View All</b> <ArrowRight size={14} />
                        </div> */}
                    </div>
                }

                { option && option === 'losers' &&
                    <div>
                        <div className='mt-3' style={{ height:'20rem', overflowY:'scroll' }}>

                            { !highestLosers && 
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }

                            { highestLosers && highestLosers.map( stock => {
                                return (
                                    <div className='d-flex mt-2' style={{color:'white', justifyContent:'space-between',height:'2rem', borderBottom: '1px solid #45465C'}}>
                                        <div style={{ width:'10%' }}>
                                            <span className='hover-cursor symbol-container text-center' onClick={ () => props.history.push( `/stock-details/${ stock.symbol }` )} style={{fontSize:'.85rem',width:'100%'}}>
                                                <b>{ stock.symbol }</b>
                                            </span>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <span>{ stock.name }</span>
                                        </div>
                                        <div>
                                            <span style={{width:'10%',fontSize:'1.1rem',color: `${ stock.change > 0 ? 'green' : '#ff8c8c' }`}}>${ stock.price.toFixed( 2 ) }</span>
                                        </div>
                                        <div style={{width:'20%'}} className='d-flex'>

                                            {
                                                stock.change < 0 && 
                                                <span class="badge rounded-pill bg-danger">{ stock.changesPercentage.toFixed( 2 ) }%</span>
                                            }

                                            {
                                                stock.change >= 0 && 
                                                <span class="badge rounded-pill bg-success">{ stock.changesPercentage.toFixed( 2 ) }%</span>                                          
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
import React, { useState } from 'react'
import AuthContext from './AuthContext'

const GlobalStateProvider = props => {
    const [ isLoggedIn, setIsLoggedIn ] = useState( null )

    return (
        <AuthContext.Provider // this is where we expose state values and functions to the rest of the application
            value={{
                state: { // for organization reasons I like keep all state values inside a state object
                    isLoggedIn
                }
            }}
        >
            { props.children }
        </AuthContext.Provider>
    )
}

export default GlobalStateProvider;
import React, { useEffect } from "react"
import { Trash2 } from "react-feather"

export default function UserCalendar( props ) {

    const { userCalendarEvents } = props.userContext.state
    const { economicCalendar } = props.globalContext.state
    const { getUserCalendarEvents, removeFromUserCalendar } = props.userContext
    const { user } = props.globalContext.state

    useEffect( () => {
        getUserCalendarEvents()
    }, [ user ] )

    if( !userCalendarEvents || !economicCalendar ) return <div></div>

    return ( 
        <main style={{marginLeft:300, marginTop:65}}>
            <div className='d-flex'>
                <div>
                    <label>My Calendar</label>
                    <table className='table table-sm'>
                        <thead>
                            <th>Date</th>
                            <th>Symbol</th>
                            <th>Type</th>
                            <th>Time</th>
                            <th></th>
                        </thead>
                        <tbody>
                            { userCalendarEvents.sort( ( a , b ) => a.date > b.date ).map( event => {
                                return (
                                    <tr>
                                        <td>{ event.date }</td>
                                        <td>{ event.symbol }</td>
                                        <td>{ event.eventType }</td>
                                        <td>{ event.time }</td>
                                        <td> <Trash2 size={ 16 } onClick={ () => removeFromUserCalendar( event._id ) } /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div>
                    <label>Economic Calendar</label>
                    <table className='table table-sm'>
                        <thead>
                            <th>Date</th>
                            <th>Symbol</th>
                            <th>Type</th>
                            <th>Time</th>
                            <th></th>
                        </thead>
                        <tbody>
                            { economicCalendar.sort( ( a , b ) => a.date > b.date ).map( event => {
                                return (
                                    <tr>
                                        <td>{ event.date }</td>
                                        <td>{ event.event }</td>
                                        <td>{ event.country }</td>
                                        <td>{ event.actual || event.estimate }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    )
}
import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import { GetHistoricalEps } from '../utils/realmFunctions'

export default function StockDetailsEpsChart( props ) {

    const { timeFrame } = props
    const [ epsChart, setEpsChart ] = useState( false )

    const { createEpsChartGraph } = props.globalContext

    useEffect( () => {
        getHistoricalEps()
    }, [ timeFrame, props.match.params.stockSymbol ] )

    const getHistoricalEps = async () => {
        const historicalEps = await GetHistoricalEps( timeFrame, props.match.params.stockSymbol )

        if( historicalEps.status !== 200 ) {
            //handle error
        }

        //Create our price chart data
        let epsChartData = createEpsChartGraph( historicalEps.body )

        //Update the price chart
        if( epsChart ) epsChart.destroy()
        var ctx = document.getElementById( `EpsChart` ).getContext('2d')
        var myChart = new Chart(ctx, epsChartData )
        setEpsChart( myChart )
    }

    return (
        <div style={{width:'50rem'}}>
            <div>
                <canvas id={ `EpsChart` }></canvas>
            </div>
        </div>
    )
}
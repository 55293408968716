import React, { useState, useEffect, useRef } from 'react'
import { ArrowLeftCircle } from 'react-feather'
import moment from 'moment'
import Chart from 'chart.js'
import StockDetailsHeader from '../components/StockDetailsHeader'
import StockDetailsPageMenu from '../components/StockDetailsPageMenu'
import EtfDetailsCharts from '../components/EtfDetailsCharts'
import StockDetailsStockOverview from '../components/StockDetailsStockOverview'
import StockDetailsArticles from '../components/StockDetailsArticles'
import StockDetailsStockGrades from '../components/StockDetailsStockGrades'

export default function ETFDetails( props ) {

    const { etfDetails, symbolType } = props.globalContext.state
    const { calculateReturn, createEtfPriceChartGraph, getSymbolType } = props.globalContext
    const [ chartDetails, setChartDetails ] = useState( false )
    const [ visibleSection, setVisibleSection ] = useState( 'PriceChart' )

    const priceChartRef = useRef( null )
    const summaryRef = useRef(null)
    const newsRef = useRef(null)
    const earningsAndFinancialsRef = useRef( null )
    const ratingsRef = useRef(null)
    const stockPeersRef = useRef( null )
    const exposureRef = useRef(null)
    const mounted = useRef( false )

    //This is used to remove the event listener when EtfDetails unmounts
    useEffect( () => {
        mounted.current = true;

        return() => {
            window.removeEventListener( 'scroll', handleScroll )
            mounted.current = false;
        }
    }, [])
    console.log( symbolType )
    // useEffect( () => {

    //     if( !symbolType || symbolType.symbol != props.match.params.stockSymbol ){
    //         getSymbolType( props.match.params.stockSymbol )
    //     }
        
    //     if( symbolType && symbolType.type === 'stock' ) {
    //         props.history.push( `/stock-details/${ props.match.params.stockSymbol }`)
    //         return
    //     }
        
    //     // if( ( symbolType && symbolType.type === 'etf' ) && ( !etfDetails || etfDetails.etfPriceDetails[ 0 ].symbol !== props.match.params.etfSymbol ) ) {
    //     //     getEtfDetails( props.match.params.etfSymbol )
    //     //     calculateReturn( props.match.params.etfSymbol )
    //     // }

    //     // if( ( symbolType && symbolType.type === 'etf' ) && ( etfDetails && etfDetails.etfPriceDetails[ 0 ].symbol == props.match.params.etfSymbol ) ) {
    //     //     //delete the old one
    //     //     if( chartDetails ) chartDetails.destroy()
    //     //     var ctx = document.getElementById( `EtfPriceChart` ).getContext('2d')
    //     //     var myChart = new Chart(ctx, etfDetails.priceChart )
    //     //     setChartDetails( myChart )
    //     // }

    // }, [ props.match.params.stockSymbol, symbolType ] )

    const handleScroll = (  ) => {
        try {

            if( window.location.pathname.indexOf( 'stock-details' ) === -1 ) {
                window.removeEventListener( 'scroll', handleScroll )
                return
            }

            const scrollPosition = window.scrollY + 200

            const sectionRefs = [ 
                { section: "Summary", ref: summaryRef },
                { section: "News", ref: newsRef },
                { section: "Ratings", ref: ratingsRef },
                { section: "Exposure", ref: exposureRef },
                { section: 'PriceChart', ref: priceChartRef },
                { section: 'EarningsAndFinancials', ref: earningsAndFinancialsRef },
                { section: 'Peers', ref: stockPeersRef }
            ]

            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;

                if ( ele ) {
                    const { offsetBottom, offsetTop } = getDimensions( ele );

                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });

            setVisibleSection( selected ? selected.section : 'PriceChart' );

        } catch ( e ) {
            window.removeEventListener( 'scroll', handleScroll )
            throw e.toString()
        }
    }

    const getDimensions = ( ele ) => {
        if( !ele || !ele.getBoundingClientRect ) {
            return
        }

        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop
        const offsetBottom = offsetTop + height
    
        return {
          height,
          offsetTop,
          offsetBottom,
        };
    }

    const changeTimelineForPriceChart = ( period ) => {
        if( period == '1M' ) period = -30
        if( period == '3M' ) period = -90
        if( period == '6M' ) period = -180
        if( period == '1Y' ) period = -365
        if( period == '2Y' ) period = -730
        if( period == '3Y' ) period = -1095
        if( period == '5Y' ) period = -1825

        let startDate = moment().add( period, 'd' ).format( 'YYYY-MM-DD' )

        let stockData = createEtfPriceChartGraph( etfDetails.historicalStockPrices, startDate )
        if( chartDetails ) chartDetails.destroy()
        var ctx = document.getElementById( `EtfPriceChart` ).getContext('2d')
        var myChart = new Chart(ctx, stockData )
        setChartDetails( myChart )
    }

    return (
        <main style={{marginLeft:'10%',marginRight:'10%',marginTop:150,color:"#AFB9CC"}} tabIndex='0' data-bs-spy="scroll" data-bs-target="#list-example">
            {/* STOCK HEADER */}
            <div>
                <StockDetailsHeader 
                    { ...props }
                    userContext={ props.userContext }
                    priceChartRef={ priceChartRef }
                />
            </div>

            {/* Structure for page */}
            <div className="d-flex my-4" style={{zIndex:1}}>
                <div className='col-3'>
                    <StockDetailsPageMenu
                        { ...props }
                        visibleSection={ visibleSection }
                        setVisibleSection={ setVisibleSection }
                        priceChartRef={ priceChartRef }
                        summaryRef={ summaryRef }
                        earningsAndFinancialsRef={ earningsAndFinancialsRef }
                        newsRef={newsRef}
                        ratingsRef={ratingsRef}
                        stockPeersRef={ stockPeersRef }
                        exposureRef={exposureRef}
                    />
                </div>

                <div className='col-9' >
                    {/* CHARTS */}
                    <EtfDetailsCharts 
                        { ...props }
                    />

                    {/* SUMMARY */}
                    <div style={{paddingTop:25}} id="list-item-2" ref={ summaryRef }>
                        <div className="d-flex" style={{width:'100%'}}>
                            <span className="h4 col-10">Summary</span>
                        </div>
                        <div>
                            <StockDetailsStockOverview { ...props } />
                        </div>
                    </div>

                    {/* NEWS AND ARTICLES */}
                    <div ref={ newsRef }>
                        <StockDetailsArticles { ...props } newsRef={ newsRef } />
                    </div>

                    
                </div>

                
            </div>
        </main>
    )

    return (
        <main style={{paddingLeft:275,paddingTop:60}}>
            <div className="d-flex de">
                { props.location.state ? 
                    <React.Fragment>
                        <ArrowLeftCircle size={20} className='featherTableButtons feather-home mt-1' onClick={ () => props.history.push( props.location.state.previousPage )}/> 
                        Back to { props.location.state.previousPage === '/' ? 'Homepage' : '' }
                    </React.Fragment>
                : '' }
            </div>
            <div className="d-flex">
                <div className="invoice-header mt-2 col-8">
                    <ul className="big-fields">
                        <li className="">
                            <span>Name</span>
                            { etfDetails.etfPriceDetails[ 0 ].name }
                        </li>
                        <li className="" >
                            <span>Symbol:</span>
                            { etfDetails.etfPriceDetails[ 0 ].symbol }
                        </li>
                        <li>
                            <span>Price</span>
                            ${ etfDetails.etfPriceDetails[ 0 ].price.toFixed( 2 ) }
                        </li>
                        <li>
                            <span>Change</span>
                            <text style={{color: etfDetails.etfPriceDetails[ 0 ].change > 0 ? 'green' : 'red' }}>${ etfDetails.etfPriceDetails[ 0 ].change.toFixed( 2 ) }</text>
                        </li>
                        <li>
                            <span>P/E</span>
                            { etfDetails.etfPriceDetails[ 0 ].pe && etfDetails.etfPriceDetails[ 0 ].pe.toFixed( 2 ) }
                        </li>
                    </ul>

                    <div className='d-flex de'>
                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input onClick={ () => changeTimelineForPriceChart( '1M' ) } type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio1">1M</label>

                            <input onClick={ () => changeTimelineForPriceChart( '3M' ) } type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio2">3M</label>

                            <input onClick={ () => changeTimelineForPriceChart( '6M' ) } type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio3">6M</label>

                            <input onClick={ () => changeTimelineForPriceChart( '1Y' ) } type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio4">1Y</label>

                            <input onClick={ () => changeTimelineForPriceChart( '2Y' ) } type="radio" class="btn-check" name="btnradio" id="btnradio5" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio5">2Y</label>

                            <input onClick={ () => changeTimelineForPriceChart( '3Y' ) } type="radio" class="btn-check" name="btnradio" id="btnradio6" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio6">3Y</label>

                            <input onClick={ () => changeTimelineForPriceChart( '5Y' ) } type="radio" class="btn-check" name="btnradio" id="btnradio7" autocomplete="off"></input>
                            <label class="btn btn-outline-primary" for="btnradio7">5Y</label>
                        </div>
                        <div>
                            {/* <span>
                                <input style={{width:75}} id="startingAmount" defaultValue={'$1,000'}></input> two years ago, would now be worth: <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"> ${ stockReturn && Number( stockReturn ) }  </span>
                            </span> */}
                            <button type="button" className="ml-4 btn btn-primary btn-sm" onClick={ ( e ) => calculateReturn( props.match.params.etfSymbol, document.getElementById( 'startingAmount' ).value ) }>Update</button>
                        </div>
                    </div>
                    <ul className='small-field mt-2'>
                        <li>
                            <div style={{width:'50rem'}}>
                                <canvas id={ `EtfPriceChart` }></canvas>
                            </div>
                        </li>
                    </ul>
                    <div className='d-flex de'>
                        <div style={ { maxHeight:250,overflow:'auto',marginLeft:25 } }>
                            <table className="table table-sm">
                                <thead>
                                    <th>Quarter Date</th>
                                    <th>Dividend</th>
                                    <th>Payment Date</th>
                                </thead>
                                { etfDetails.etfDividendHistory.map( dividend => {
                                    return (
                                        <tr>
                                            <td>
                                                { dividend.date }
                                            </td>
                                            <td>
                                                { dividend.adjDividend }
                                            </td>
                                            <td>
                                                { dividend.paymentDate.length === 0 ? dividend.date : dividend.paymentDate }
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div>
                        <label>Latest News</label>
                        <ul className="list-group" style={{ maxHeight:150, overflowY:'scroll' }}>
                            {
                                etfDetails.stockNews.map( news => {
                                    return (
                                        <li> - <span onClick={ () => window.open( news.url , '_blank').focus() }> { news.title } </span> </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className='d-flex mt-4'>
                        <div className='mx-2' style={{maxHeight:300,overflowY:'auto'}} >
                            <label>Stock Holdings</label>
                            <table className='table table-sm' style={{maxHeight:275,overflowY:'auto'}}>
                                <thead>
                                    <th>Symbol</th>
                                    <th>Name</th>
                                    <th>Weight</th>
                                </thead>
                                <tbody>
                                    { etfDetails.etfStockHoldings.map( stock => {
                                        return (
                                            <tr onClick={ () => props.history.push( `/stock-details/${ stock.asset }`)}>
                                                <td>{ stock.asset }</td>
                                                <td>{ stock.name }</td>
                                                <td>{ stock.weightPercentage.toFixed( 2 ) }</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='mx-1' >
                        <div style={{maxHeight:300,overflowY:'auto'}}>
                            <label>Sector Weightings</label>
                            <table className='table table-sm'>
                                <thead>
                                    <th>Sector</th>
                                    <th>Weight</th>
                                </thead>
                                <tbody>
                                    { etfDetails.etfSectorWeightings.sort( ( a, b ) => Number(a.weightPercentage.replace( '%','')) < Number(b.weightPercentage.replace('%','') )).map( sector => {
                                        
                                        return (
                                            <tr onClick={ () => props.history.push( `/sectors/${ sector.sector }`)}>
                                                <td>{ sector.sector }</td>
                                                <td>{ sector.weightPercentage }</td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
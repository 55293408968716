import React, { useEffect } from 'react'
import Chart from 'chart.js'

export default function Graph( props ) {

    const { graphData } = props.globalContext.state

    useEffect(() => {
        if( graphData ) {
            var ctx = document.getElementById('myChart').getContext('2d');
            var myChart = new Chart(ctx, graphData)
        }
    })

    return (
        <div style={{ marginLeft: 275, marginTop: 60 }}>
            <canvas id="myChart" width="1200" height="800"></canvas>
        </div>
    )
}
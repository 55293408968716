import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import { ArrowRight } from 'react-feather'
import { toast } from 'react-toastify'

export default function HomepageWatchlist( props ) {

    const { watchlistDetails, allUsersWatchlists } = props.userContext.state
    const { loadWatchlistStocks, loadUserWatchlists } = props.userContext
    const { calculateWatchlistReturn, setSelectedWatchlist } = props.globalContext
    const { homepageWatchlistValue, user, selectedWatchlist } = props.globalContext.state

    const [ watchlistValueChart, setWatchlistValueChart ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ timeFrame, setTimeFrame ] = useState( -30 )
    
    const [ chartLoading, setChartLoading ] = useState( false )
    
    useEffect( () => {
        if( selectedWatchlist ) {
            loadWatchlistStocks( selectedWatchlist.id )
        } else {
            loadWatchlistStocks()
        }

        loadUserWatchlists()
    }, [ user ] )

    useEffect( () => {
        if( watchlistDetails && timeFrame && !chartLoading ) createWatchlistChart()
    }, [ watchlistDetails ] )

    const createWatchlistChart = async ( timeFrameToUse ) => {
        let localTimeFrame = null

        if( !timeFrameToUse || timeFrameToUse === null ) {
            localTimeFrame = timeFrame
        } else {
            localTimeFrame = timeFrameToUse
        }

        setChartLoading( true )

        var ctx = document.getElementById( `WatchlistValueChart` )
        ctx.hidden = true
        let chartData = await calculateWatchlistReturn( watchlistDetails.id, localTimeFrame )

        if( chartData.error ) {
            toast( chartData.error ) 
            return
        }

        //Update the price chart
        if( watchlistValueChart ) watchlistValueChart.destroy()

        ctx.getContext( '2d' )
        var myChart = new Chart(ctx, chartData )
        setWatchlistValueChart( myChart )
        ctx.hidden = false
        setChartLoading( false )
    }

    const handleWatchlistSwitch = async( oId ) => {
        setLoading( true )
        await loadWatchlistStocks( oId )
        setLoading( false )
    }

    const setWatchlistReturnChartStyles = ( frame ) => {
        if( frame === timeFrame ) {
            return 'text-center homepageSelectedTimeFrame hover-cursor' 
        }

        return 'text-center homepageDeselectedTimeFrame hover-cursor'
    }

    const changeTimeframe = async( frame ) => {
        await setTimeFrame( frame )
        createWatchlistChart( frame )
    }

    if( !watchlistDetails ) {
        return (
            <div className='col-5'>
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    const handleWatchlistRoute = async ( watchlistId ) => {
        //get the index
        let index = allUsersWatchlists.findIndex( a => a._id.toString() == watchlistId )
        await setSelectedWatchlist( { index: index, id: watchlistId } )
        props.history.push( { pathname: "/watchlists" } )
    }

    return (
        <div style={{ minWidth:'80%'}}>
            <div>
                <span style={{color:'white',fontSize:'1.5rem',letterSpacing:'.05rem'}}>Watchlists</span>
            </div>

            <div className="d-flex mt-1">
                <div className='col-6 mx-2'>
                    <div className='d-flex justify-content-between'>
                        <button id="btnGroupDrop1" type="button" class="btn btn dropdown-toggle" style={{backgroundColor:'#20A5A1',color:'white'}} data-toggle="dropdown" aria-expanded="false">
                            { watchlistDetails?.details?.name || '' }
                        </button>

                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <React.Fragment>
                                {
                                    !loading && allUsersWatchlists && allUsersWatchlists.map( ( a, index ) => {
                                        return <li key={index} onClick={ () => {
                                            handleWatchlistSwitch( a._id.toString() )
                                        }}><a class="dropdown-item" href="#">{ a.name }</a></li>
                                    })
                                }
                            </React.Fragment>
                        </ul>

                        <span onClick={ () => props.history.push( '/watchlists' ) } className='hover-cursor' style={{color:'#24A556',alignSelf:'flex-end'}}>
                            <b>See All</b> <ArrowRight size={14} />
                        </span>
                    </div>
                    <div>
                        <div className='mt-3' style={{ height:'20rem', overflowY:'scroll' }}>
                            { watchlistDetails.stocks.map( ( stock, index ) => {
                                return (
                                    <div key={index} className='d-flex mt-2' style={{color:'white', justifyContent:'space-between',height:'2rem', borderBottom: '1px solid #45465C'}}>
                                        <span className='hover-cursor' onClick={ () => props.history.push( `/stock-details/${ stock.symbol }` ) } style={{width:'25%',fontSize:'.85rem'}}><b>{ stock.symbol }</b></span>
                                        <span style={{width:'25%'}}>${ stock.price.toLocaleString() }</span>
                                        <span style={{width:'25%',color: `${ stock.change > 0 ? 'green' : '#ff8c8c' }`}}>${ stock.change.toFixed( 2 ) }</span>
                                        <span style={{width:'25%',color: `${ stock.change > 0 ? 'green' : '#ff8c8c' }`}}>{ stock.changesPercentage.toFixed( 2 ) }%</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='hover-cursor mt-2' onClick={ () => {
                            handleWatchlistRoute( watchlistDetails.id )
                        }} style={{color:'#24A556',fontSize:'.8rem'}}>
                            <b>View All</b> <ArrowRight size={14} />
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                { homepageWatchlistValue && 
                    <React.Fragment>
                        <div className='d-flex' style={{borderBottom: '1px solid #45465C',color:'white',justifyContent:'space-evenly',verticalAlign:'bottom'}}>
                            <span className={setWatchlistReturnChartStyles(-30)} onClick={() => changeTimeframe( -30 ) }>1M</span>
                            <span className={setWatchlistReturnChartStyles(-90)} onClick={() => changeTimeframe( -90 ) }>3M</span>
                            <span className={setWatchlistReturnChartStyles(-180)} onClick={() => changeTimeframe( -180 ) }>6M</span>
                            <span className={setWatchlistReturnChartStyles(-365)} onClick={() => changeTimeframe( -365 ) }>1Y</span>
                            <span className={setWatchlistReturnChartStyles(-1825)} onClick={() => changeTimeframe( -1825 ) }>5Y</span>
                            <span style={{fontSize:'1.8rem'}}>${ homepageWatchlistValue && Number( homepageWatchlistValue ).toLocaleString() }</span>
                        </div>
                        
                    </React.Fragment>
                }

                    <div className='mt-4'>
                        <div>
                            { chartLoading && 
                                    <div class="spinner-border text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }
                            <canvas id={ `WatchlistValueChart` }></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
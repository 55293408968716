import React, { useEffect } from 'react'
import $ from 'jquery'

export default function EditWatchlistModal( props ) {
    const { editWatchlist } = props.userContext
    const { selectedWatchlist } = props
    
    const editWatchlistHandler = async () => {
        await editWatchlist( document.getElementById( 'editWatchlistName' ).value, document.getElementById( 'editPrimaryWatchlist' ).checked, selectedWatchlist._id )

        $('#editWatchlistModal').modal('toggle')
    }

    useEffect( () => {
        document.getElementById( 'editPrimaryWatchlist' ).checked = selectedWatchlist.primary
    }, [ selectedWatchlist ] )

    return (
        <div class="modal fade" id="editWatchlistModal" aria-hidden="true" aria-labelledby="editWatchlistModalLabel" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Portfolio</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <label>Name</label>
                        <input id="editWatchlistName" defaultValue={ selectedWatchlist.name }></input>
                    </div>
                    <div>
                        <label>Primary</label>
                        <input type="checkbox" id="editPrimaryWatchlist" ></input>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" onClick={ () => editWatchlistHandler() } > Save </button>
                </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { ArrowRightCircle } from 'react-feather'
import Chart from 'chart.js'

export default function IndexDetails( props ) {
    const { indexDetails } = props.globalContext.state
    const { getIndexDetails } = props.globalContext
    const [ chartDetails, setChartDetails ] = useState( false )
    
    useEffect( () => {
        if( !indexDetails || indexDetails.symbol !== props.match.params.indexSymbol ) getIndexDetails( props.match.params.indexSymbol )

        if( indexDetails && indexDetails.symbol == props.match.params.indexSymbol ) {
            //delete the old one
            if( chartDetails ) chartDetails.destroy()
            var ctx = document.getElementById( `IndexPriceChart` ).getContext('2d')
            console.log( indexDetails.priceChart )
            var myChart = new Chart(ctx, indexDetails.priceChart )
            setChartDetails( myChart )
        }
    }, [ indexDetails, props.match.params.stockSymbol ])

    const changeTimelineForPriceChart = ( period ) => {
        // if( period == '1M' ) period = -30
        // if( period == '3M' ) period = -90
        // if( period == '6M' ) period = -180
        // if( period == '1Y' ) period = -365
        // if( period == '2Y' ) period = -730
        // if( period == '3Y' ) period = -1095
        // if( period == '5Y' ) period = -1825

        // let startDate = moment().add( period, 'd' ).format( 'YYYY-MM-DD' )

        // let stockData = createPriceChartGraph( stockDetails.historicalStockPrices, startDate )
        // if( chartDetails ) chartDetails.destroy()
        // var ctx = document.getElementById( `PriceChart` ).getContext('2d')
        // var myChart = new Chart(ctx, stockData )
        // setChartDetails( myChart )
    }

    if( !indexDetails ) return <main>Loading...</main>

    return (
        <main style={{ marginLeft:275,marginTop:60 }}>
            <div>
                <div>
                    <span>  Indexes <ArrowRightCircle size={ 16 } style={{cursor:"pointer"}} onClick={ () => props.history.push( `/indexes` ) } />  </span>
                </div>

                <span>{ indexDetails[ 'name' ] } Details</span>

                <div className='d-flex'>
                    <div style={{width:'50rem'}}>
                        <canvas id={ `IndexPriceChart` }></canvas>
                    </div>
                    { indexDetails.members && indexDetails.members.length > 0  &&
                        <div className='mx-4' style={{maxHeight:350,overflowY:'auto'}}>
                            <span>Index Members</span>
                            <table className='table table-sm'>
                                <thead>
                                    <th>Symbol</th>
                                    <th>Name</th>
                                </thead>
                                <tbody>
                                    { indexDetails.members && indexDetails.members.map( member => {
                                        return (
                                            <tr>
                                                <td>{ member.symbol }</td>
                                                <td>{ member.name }</td>
                                            </tr> 
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                <div className="d-flex">
                    <div style={{maxHeight:350,overflowY:'auto'}}>
                        <table className='table table-sm'>
                            <thead>
                                <th>Name</th>
                                <th>Value</th>
                            </thead>
                            <tbody>
                                { Object.keys( indexDetails ).map( key => {
                                    if( key === 'members' ) return
                                    if( key === 'priceChart' ) return
                                    if( key === 'historicalPrices' ) return
                                    return ( 
                                        <tr>
                                            <td>{ key }</td>
                                            <td> { indexDetails[ key ] } </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    )
}
import React from 'react'
import $ from 'jquery'

export default function AddWatchlist( props ) {
    const { addNewWatchlist } = props.userContext
    const { user } = props.userContext.state
    
    const addWatchlistHandler = async () => {
        let result = await addNewWatchlist( document.getElementById( 'newWatchlistName' ).value, document.getElementById( 'primaryWatchlist' ).checked )
        $('#addWatchlistModal').modal('toggle')
    }

    return (
        <div class="modal fade" id="addWatchlistModal" aria-hidden="true" aria-labelledby="addWatchlistModalLabel" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Watchlist</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <label>Name</label>
                        <input id="newWatchlistName"></input>
                    </div>
                    <div>
                        <label>Primary</label>
                        <input type="checkbox" id="primaryWatchlist" defaultValue={ false }></input>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" onClick={ () => addWatchlistHandler() } > Save </button>
                </div>
                </div>
            </div>
        </div>
    )
}
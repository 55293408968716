import React, { useState, useEffect } from 'react'
import { PlusCircle } from 'react-feather'
import { GetStockPeers } from '../utils/realmFunctions'
import $ from 'jquery'

export default function StockDetailsStockPeers( props ) {

    const [ stockPeers, setStockPeers ] = useState( false )
    const { setSelectedStock } = props.globalContext

    useEffect( () => {
        getStockPeers()
    }, [ props.match.params.stockSymbol ] )

    async function getStockPeers() {

        if( stockPeers ) setStockPeers( false )

        let peers = await GetStockPeers( props.match.params.stockSymbol )

        if( peers.status !== 200 ) {
            //handle error
            setStockPeers( [] )
        }

        setTimeout( () => {
            setStockPeers( peers.body )
        }, 500)
        
    }

    const handleStockRoute = ( symbol ) => {
        props.history.push( `/stock-details/${ symbol }`)
    }
    
    const openAddToWatchlistModal = ( stock ) => {
        setSelectedStock( stock )
        $( '#addSymbolToWatchlistModal' ).modal( 'toggle' )
        $( '#addSymbolToWatchlistModal' ).modal({
            keyboard: false
        })
    }

    if( !stockPeers ) return (
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    )
    
    return (
        <div style={{maxHeight:250,overflowY:'auto',paddingTop:25}} ref={ props.stockPeersRef } className="mb-4">
            <div className="d-flex" style={{borderBottom: "1px solid #384F80"}}>
                <span className="h4">Peers</span>
            </div>

            <div>
                <div class="d-flex flex-wrap">
                    { stockPeers.map( ( peer, index ) => {
                        return (
                            <div className='col-4 mt-3' style={{height:'20',background: index % 2 == 0 ? 'rgb(24, 31, 50)' : '#10192C' }}>
                                <div className="text-center mt-2">
                                    <span className="text-center h3 hover-cursor" onClick={ () => handleStockRoute( peer.symbol )}>{ peer.symbol }</span>
                                </div>
                                <div className="text-center mt-3">
                                    <span className="text-center h6">{ peer.name }</span>
                                </div>
                                <div className="text-center mt-3 mb-3" onClick={ () => openAddToWatchlistModal( peer ) }>
                                    <button className='btn btn-outline-success'>
                                        <PlusCircle  /> Add to Watchlist
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { ArrowUpRight, PlusCircle } from 'react-feather'
import Select from 'react-select'
import Createable from 'react-select/creatable'
import { exchangeOptions, sectorOptions } from '../utils/dataKeyMapper'

export default function StockScreener( props ) {
    const { stockScreenerOptions, stockScreenerResults, screenerResultsLength, screenerPreFilterResultsLength } = props.globalContext.state
    const { setStockScreenerOptions, getStockScreenerResults, setScreenerResultsLength, setSelectedStock } = props.globalContext
    const [ selectedMarketCap, setSelectedMarketCap ] = useState( [] )

    useEffect( () => {
        if( Object.keys( stockScreenerOptions ).length > 0 ) {
            getStockScreenerResults( true )
        } else if ( Object.keys( stockScreenerOptions ).length === 0 && screenerResultsLength > 0 ) {
            setScreenerResultsLength( false )
        }
    }, [ stockScreenerOptions ] )

    const updateSelectedMarketCap = ( name, e ) => {

        let indexOfOptionClicked = selectedMarketCap.findIndex( a => a == name )
        let tempArray = [ ...selectedMarketCap ]

        if( indexOfOptionClicked === -1 ) {
            tempArray.push( name )
        } else {
            tempArray.splice( indexOfOptionClicked, 1 )
        }

        //update the stock screener options
        setStockScreenerOptions( { ...stockScreenerOptions, marketCap: [ ...tempArray ] } )

        setSelectedMarketCap( tempArray )
    }

    const updateSector = ( value ) => {
        let obj = { ...stockScreenerOptions }

        if( value === null ) {
            delete obj.sector
            setStockScreenerOptions( obj )
            return
        }

        setStockScreenerOptions( { 
            ...stockScreenerOptions, 
            sector: value?.value
        })
    }

    const updatePrice = ( value, key ) => {
        let obj = { ...stockScreenerOptions }

        //price range updates
        if( key === 'priceRange' ) {
            if( value === null ) {
                obj[ 'priceRange' ] = 'priceMoreThan'
            } else {
                obj[ 'priceRange' ] = value?.value
            }
        }

        //price updates
        if( key === 'price' ) {
            if( value === null ) {
                delete obj[ 'price' ]
            } else {
                obj[ 'price' ] = value?.value
            }
        }

        setStockScreenerOptions( obj )
    }

    const updateDividend = ( value, key ) => {

        let obj = { ...stockScreenerOptions }

        //price range updates
        if( key === 'dividendRange' ) {
            if( value === null ) {
                obj[ 'dividendRange' ] = 'dividendMoreThan'
            } else {
                obj[ 'dividendRange' ] = value?.value
            }
        }

        //price updates
        if( key === 'dividend' ) {
            if( value === null ) {
                delete obj[ 'dividend' ]
            } else {
                obj[ 'dividend' ] = value?.value
            }
        }

        setStockScreenerOptions( obj )
    }

    const setMarketCapStyles = ( option ) => {
        if( selectedMarketCap.includes( option ) ) {
            return "btn btn-outline-dark-selected"
        }

        return "btn btn-outline-dark"
    }

    const styles = {
        control: ( provided ) => ({
            ...provided,
            backgroundColor: '#7D8AAA',
            color: '#0B1324',
            border: 'none',
            
        }),
        placeholder: ( styles ) => ({
            ...styles,
            color:"#0B1324"
        }),
        default: ( styles => ({
            ...styles,
            color: "#0B1324"
        })),
        clearIndicator: ( styles ) => ({
            ...styles,
            color: '#0B1324'
        }),
        option: ( styles ) => ({
            ...styles,
            color: '#0B1324'
        }),
        dropdownIndicator: ( styles ) => ({
            ...styles,
            color: "#0B1324"
        }),
        indicatorSeparator: ( styles ) => ({
            ...styles,
            color: "#0B1324",
            backgroundColor: "#0B1324"
        })
    }

    return (
        <main style={{ marginTop:150, marginLeft:'10%', marginRight:'10%',color:'white', color: "#A2ACC3" }}>
            <div style={{color:'#AFB9CC',fontSize:'1.4rem',marginTop:20,marginBottom:20}} className='d-flex justify-content-between'>
                <span style={{color:'#AFB9CC',fontSize:'1.4rem'}}>
                    Stock Screener
                </span>
            </div>

            <div style={{ backgroundColor:"#0B1324",borderRadisu: '50px' }}  className="px-4 py-4">
                <form class="row g-3" style={{ borderRadius: 10 }}>
                    <div class="col-md-3">
                        <label style={{color: "#A2ACC3"}} class="form-label">Exchange</label>
                        <Select
                            onChange={ ( value ) => {
                                if( value === null ) {
                                    let obj = { ... stockScreenerOptions }
                                    delete obj.exchange
                                    setStockScreenerOptions( obj )
                                } else {
                                    setStockScreenerOptions( { 
                                        ...stockScreenerOptions, 
                                        exchange: value?.value
                                    })
                                }
                            }}
                            isClearable
                            styles={ styles }
                            options={ exchangeOptions }
                        />
                    </div>
                    <div class="col-md-3">
                        <label for="inputEmail4" class="form-label">Sector</label>
                        <Select
                            styles={ styles }
                            isClearable
                            onChange={ ( e ) => {
                                updateSector( e )    
                            }}
                            options={ sectorOptions }
                        />
                    </div>
                    <div class="col-md-6">
                        <label for="inputPassword4" class="form-label">Market Cap</label>
                        <br></br>

                        <div className="btn-group active" role="group" aria-label="Basic radio toggle button group">
                            <button type="button" onClick={ (e) => {
                                updateSelectedMarketCap( 'small', e )
                            }} class={setMarketCapStyles( 'small' )} >
                                Small Cap
                            </button>

                            <button type="button" onClick={ (e) => {
                                updateSelectedMarketCap( 'mid', e )
                            }} class={setMarketCapStyles( 'mid' )} >
                                Mid Cap
                            </button>

                            <button type="button" onClick={ (e) => {
                                updateSelectedMarketCap( 'large', e )
                            }} class={setMarketCapStyles( 'large' )} >
                                Large Cap
                            </button>

                            <button type="button" onClick={ (e) => {
                                updateSelectedMarketCap( 'mega', e )
                            }} class={setMarketCapStyles( 'mega' )} >
                                Mega Cap
                            </button>
                        </div>
                    </div>
                    <div class="w-50">
                        <div>
                            <label for="inputPassword4" class="form-label">Price</label>
                        </div>
                        <div className='d-flex'>
                            <div className='w-50 me-3'>
                                <Select
                                    styles={ styles }
                                    onChange={ e => updatePrice( e, 'priceRange' ) }
                                    defaultValue={ { value: 'priceGreaterThan', label: 'Greater Than' } }
                                    options={ [
                                        {
                                            label: "Greater Than",
                                            value: 'priceMoreThan'
                                        },
                                        {
                                            label: "Less Than",
                                            value: 'priceLowerThan'
                                        },
                                    ]}
                                />
                            </div>

                            <div className='w-50'>
                                <Select
                                    isClearable
                                    onChange={ e => updatePrice( e, 'price' ) }
                                    styles={ styles }
                                    options={ [
                                        { 
                                            label: '$0',
                                            value: '0'
                                        },
                                        {
                                            label: "$10",
                                            value: '10'
                                        },
                                        {
                                            label: "$20",
                                            value: '20'
                                        },
                                        {
                                            label: "$50",
                                            value: '50'
                                        },
                                        {
                                            label: "$100",
                                            value: '100'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        
                        

                        {/* <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, price: { ...stockScreenerOptions.price, priceLowerThan: e.target.value } } ) } >Less Than <input></input></span>
                        <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, price: { ...stockScreenerOptions.price, priceMoreThan: e.target.value } } ) } >Greater Than <input></input></span> */}
                    </div>
                    <div class="w-50">
                        <div>
                            <label for="inputPassword4" class="form-label">Dividend</label>
                        </div>
                        <div className='d-flex'>
                            <div className='w-50 me-3'>
                                <Select
                                    styles={ styles }
                                    onChange={ e => updateDividend( e, 'dividendRange' ) }
                                    defaultValue={ { value: 'dividendMoreThan', label: 'Greater Than' } }
                                    options={ [
                                        {
                                            label: "Greater Than",
                                            value: 'dividendMoreThan'
                                        },
                                        {
                                            label: "Less Than",
                                            value: 'dividendLessThan'
                                        },
                                    ]}
                                />
                            </div>

                            <div className='w-50'>
                                <Select
                                    styles={ styles }
                                    onChange={ e => updateDividend( e, 'dividend' ) }
                                    isClearable
                                    options={ [
                                        { 
                                            label: '$0',
                                            value: '0'
                                        },
                                        {
                                            label: "$2",
                                            value: '2'
                                        },
                                        {
                                            label: "$5",
                                            value: '5'
                                        },
                                        {
                                            label: "$10",
                                            value: '10'
                                        },
                                        {
                                            label: "$20",
                                            value: '20'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        
                        

                        {/* <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, price: { ...stockScreenerOptions.price, priceLowerThan: e.target.value } } ) } >Less Than <input></input></span>
                        <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, price: { ...stockScreenerOptions.price, priceMoreThan: e.target.value } } ) } >Greater Than <input></input></span> */}
                    </div>

                    {/* <div class="col-md-6">
                        <label for="inputPassword4" class="form-label">Dividend</label>
                        <br/>
                        <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, dividend: { ...stockScreenerOptions.dividend, dividendLowerThan: e.target.value } } ) } >Less Than <input></input></span>
                        <span onChange={ ( e ) => setStockScreenerOptions( { ...stockScreenerOptions, dividend: { ...stockScreenerOptions.dividend, dividendMoreThan: e.target.value } } ) } >Greater Than <input></input></span>
                    </div> */}
                    <div class="col-12 mt-4">
                        <div className="d-flex justify-content-end">
                            { screenerPreFilterResultsLength &&
                                <div className='mx-4'>
                                    <div>
                                        <span style={{fontSize:'.7rem' }}>Estimated Results</span>
                                    </div>
                                    <span style={{fontSize:'1.3rem'}} className="ms-auto">
                                        { screenerPreFilterResultsLength }
                                    </span>
                                </div>
                            }
                            <button type="button" class="btn btn-jfa-primary" onClick={ () => getStockScreenerResults( false ) }>Screen Stocks</button>
                        </div>
                    </div>
                </form>
            </div>
            

            {/* SEARCH RESULTS */}
            { stockScreenerResults && 
                <div style={{marginTop:25}}>
                    <div>
                        <span style={{color:'white',fontSize:'1.3rem',letterSpacing:'.05rem'}}>{ screenerResultsLength } Results</span>
                    </div>

                    <div className="d-flex w-100 py-2 px-2 mt-2" style={{backgroundColor: "#364362", borderRadius: '8px 8px 0px 0px' }}>
                        <span style={{minWidth:'5rem'}}>Watchlist</span>
                        <span style={{minWidth:'7rem'}}>Symbol</span>
                        <span style={{minWidth:'20rem'}}>Name</span>
                        <span style={{minWidth:'7rem'}}>Market Cap</span>
                        <span style={{minWidth:'7rem'}}>Sector</span>
                        <span style={{minWidth:'7rem'}}>Price</span>
                        <span style={{minWidth:'7rem'}}>Dividend</span>
                        <span style={{minWidth:'20rem'}}>Exchange</span>
                    </div>

                    <div className='w-100'>
                    { stockScreenerResults.map( company => {
                            return (
                                <div className='d-flex' style={{ borderTop: '1px solid #364362',borderLeft: '1px solid #364362',borderRight: '1px solid #364362' }} >
                                    <div style={{minWidth:'5rem',height: '3rem', borderRight: '1px solid #364362' }} >
                                        <span style={{ height:'100%' }} className="mx-auto" > 
                                            <PlusCircle 
                                                className="mx-4 mt-2" 
                                                onClick={ () => { 
                                                    setSelectedStock( { symbol: company.symbol } )
                                                    $( '#addSymbolToWatchlistModal' ).modal( 'toggle' )
                                                 }}
                                                /> 
                                        </span>
                                    </div>
                                    <div style={{minWidth:'7rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2 pt-2" >
                                        <span className='hover-cursor' onClick={ () => props.history.push( `/stock-details/${ company[ 'symbol' ] }` )}  style={{minWidth:'7rem'}} > { company[ 'symbol' ] } </span>
                                    </div>
                                    <div style={{minWidth:'20rem',maxWidth:'20rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2 pt-2" >
                                        <span style={{minWidth:'15rem'}}> { company[ 'companyName' ] } </span>
                                    </div>
                                    <div style={{minWidth:'7rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2 pt-2" >
                                        <span style={{minWidth:'7rem'}}> { ( company[ 'marketCap' ]/1000000000 ).toLocaleString() }b</span>
                                    </div>
                                    <div style={{minWidth:'7rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2  pt-2" >
                                        <span style={{minWidth:'7rem'}}> { company[ 'sector' ] } </span>
                                    </div>
                                    <div style={{minWidth:'7rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2  pt-2" >
                                        <span style={{minWidth:'7rem'}}> { company[ 'price' ] } </span>
                                    </div>
                                    <div style={{minWidth:'7rem',height: '3rem', borderRight: '1px solid #364362' }} className="px-2  pt-2" >
                                        <span style={{minWidth:'7rem'}}> { company[ 'lastAnnualDividend' ]?.toFixed( 2 ) } </span>
                                    </div>
                                    <div style={{minWidth:'20rem',height: '3rem' }} className="px-2 pt-2" >
                                        <span style={{minWidth:'20rem'}}> { company[ 'exchange' ] } </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            }
        </main>
    )
}
import React, { useState, useEffect } from 'react'
import { CheckCircle, Plus, PlusCircle } from 'react-feather'
import { GetMarketNews } from '../utils/realmFunctions'
import { toast } from 'react-toastify'
import $ from 'jquery'

export default function StockDetailsArticles( props ) {

    const { addArticleToRecentlyView } = props.userContext
    const [ stockArticles, setStockArticles ] = useState( false )

    useEffect( () => {
        getStockArticles()
    }, [ props.match.params.stockSymbol ] )

    const getStockArticles = async() => {
        try {
            let res = await GetMarketNews( 20, false, [ props.match.params.stockSymbol ] )

            if( res.status !== 200 ) {
                toast( res.error )
                return
            }

            setStockArticles( res.body )

        } catch (error) {
            toast( error.toString() )
            return
        }
    }

    const openArticle = async ( article ) => {
        const newWindow = window.open( article.url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null

        //record that the user click on the article
        await addArticleToRecentlyView( article )
    }

    return (
        <div style={{paddingTop:25}} id="list-item-2" ref={ props.newRef }>
            { !stockArticles &&
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            }

            { stockArticles && 
                <React.Fragment>
                    <div className="d-flex" style={{width:'100%'}}>
                        <span className="h4">Articles</span>
                        <div className='hover-cursor mx-4 mt-2' onClick={ () => {
                            //handleWatchlistRoute( watchlistDetails.id )
                        }} style={{color:'#24A556',fontSize:'.9rem'}}>
                            <b>Read all</b>
                        </div>
                    </div>
                    <div style={{maxHeight:300,overflowY:'auto',paddingTop:10}}>
                        { stockArticles.map( ( news, index ) => {
                            return (
                                <div key={index} style={{borderBottom:'2px solid #45465C',minHeight:'5rem',color:'white'}}>
                                    <div className="">
                                        <span className='hover-cursor' onClick={ () => {
                                            //window.open( news.url, "_blank")
                                            openArticle( news ) 
                                        }}>{ news.title }</span>
                                        <span className='mx-2 hover-cursor'> 
                                            {/* <PlusSquare onClick={ saveArticleHandler } size={14} /> */}
                                        </span>
                                    </div>
                                    <div className="mt-3">
                                        <span style={{marginRight:'1rem'}} className="mt-2">{ news.symbol }</span> 
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.site }</span>
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.publishedDate.toLocaleString() }</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </React.Fragment>
            }
        </div>
    )
}
import React, { useState } from 'react'
import PortfolioContext from './PortfolioContext'
import * as Realm from "realm-web"
import _ from 'lodash'
import { GetUserPortfolios, GetPortfolioPositions, GetPortfolioDetails, GetPortfolioTaxLots, GetPortfolioStockDetails,UpdateUserPortfolioPositionCount, AddStockToPortfolio, DeleleTaxLotsFromStockPosition, DeleteStockFromPortfolio, GetPortfolioStockById, AddTaxLotsToStockPosition, DeleteOneTaxLotFromStockPostion, EditPortfolioDetails, AddNewPortfolio, GetStockPriceAndChangesPercentage, GetDividendHistory } from "../../utils/realmFunctions"

const PortfolioProvider = props => {

    const [ pagePortfolio, setPagePortfolio ] = useState( false )
    const [ taxLots, setTaxLots ] = useState( false )
    const [ userPortfolios, setUserPortfolios ] = useState( false )
    const [ portfolioPositions, setPortfolioPositions ] = useState( false )
    const [ portfolioSummary, setPortfolioSummary ] = useState( { totalCostBasis: 0, totalQty: 0, totalCurrentValue: 0, totalGainLoss: 0, totalAnnualIncome: 0 } )
    const [ taxLotsSummary, setTaxLotsSummary ] = useState( { totalCostBasis: 0, totalQty: 0, totalCurrentValue: 0, totalGainLoss: 0, totalAnnualIncome: 0 } )

    
    const deletePortfolio = async ( portfolioId, userId ) => {
        //find the portfolioStockId's associated with this portfolio
        // let portfolioStockIds = await portfolioStocks.find( { portfolioId: portfolioId } )
        // portfolioStockIds = portfolioStockIds.map( id => id._id.toString() )

        // //delete all the portfolio transactions
        // await portfolioTrans.deleteMany( { portfolioStockId: { $in: portfolioStockIds } } )

        // //delete all the portfolio stock records
        // await portfolioStocks.deleteMany( { portfolioId: portfolioId } )

        // //delete the main portfolio record
        // await portfolioData.deleteOne( { _id: new Realm.BSON.ObjectId( portfolioId ) } )

        // getUserPortfolios( userId )
    }

    const deleteTaxLotFromPortfolio = async ( portfolioTransId, portfolioStockId, portfolioId ) => {
        // await DeleteOneTaxLotFromStockPostion( portfolioTransId )
        // await getTaxLots( portfolioStockId )
        // await getPortfolioPositions( portfolioId )
    }

    const deletePositionFromPortfolio = async ( portfolioStockId, portfolioId ) => {
        //Delete tax lots
        // await DeleleTaxLotsFromStockPosition( portfolioStockId )
        // await DeleteStockFromPortfolio( portfolioStockId )
        // await UpdateUserPortfolioPositionCount( portfolioId, -1 )
        // await getUserPortfolios()
        // await getPortfolioPositions( portfolioId )
    }

    const getPortfolioPositions = async( portfolioId ) => {
        // try {
        //     //This gets the name of the portfolio
        //     let portfolioDetailsById = await GetPortfolioDetails( portfolioId )

        //     if( portfolioDetailsById.status !== 200 ) {
        //         //handle error
        //     }

        //     //This gets the records in PortfolioStocks
        //     let portfolioPositionsArray = await GetPortfolioPositions( portfolioId )

        //     if( portfolioPositionsArray.status !== 200 ) {
        //         //handle error
        //     }

        //     //Calculate position summary
        //     //1) Get all the tax lots for each portfolioStock
        //     let portfolioPositionsId = portfolioPositionsArray.body.map( a => a._id.toString() )
        //     let portfolioPositionsBySymbol = portfolioPositionsArray.body.map( a => a.symbol )

        //     let portfolioStockTaxLots = await GetPortfolioTaxLots( portfolioPositionsId )

        //     if( portfolioStockTaxLots.status !== 200 ) {
        //         //handle error
        //     }

        //     //2) For each stock, get current stock prices, calculate total stats
        //     let stockSummaries = {}

        //     //Get stock prices
        //     let stockPrices = await GetStockPriceAndChangesPercentage( portfolioPositionsBySymbol )

        //     if ( stockPrices.status !== 200 ) {
        //         //handle error
        //     }
            
        //     for( let taxLot of portfolioStockTaxLots.body ) {
        //         //find the stock symbol
        //         let stockSymbol = portfolioPositionsArray.body.find( a => a._id.toString() == taxLot.portfolioStockId ).symbol
                
        //         if( !stockSummaries[ stockSymbol ] ) stockSummaries[ stockSymbol ] = { qty: 0, averageCostBasis: 0, totalCostBasis: 0, annualIncome: 0, totalGainLoss: 0, currentValue: 0 }

        //         stockSummaries[ stockSymbol ].qty += taxLot.transQty
        //         stockSummaries[ stockSymbol ].totalCostBasis += taxLot.transTotalCostBasis
        //     }

        //     let stockPrice = 0

        //     portfolioPositionsArray.body.forEach( ( portfolio, index ) => {
        //         //get the stock's current price
        //         stockPrice = stockPrices.body.find( a => a.symbol == portfolio.symbol )
        //         stockPrice = stockPrice.price

        //         if( !stockSummaries[ portfolio.symbol ] ) {
        //             portfolioPositionsArray.body[ index ].stats = { qty: 0, averageCostBasis: 0, totalCostBasis: 0, annualIncome: 0, totalGainLoss: 0, currentValue: 0, currentPrice: stockPrice } 
        //         } else {
        //             portfolioPositionsArray.body[ index ].stats = stockSummaries[ portfolio.symbol ]
        //             portfolioPositionsArray.body[ index ].stats.currentValue = stockPrice * portfolioPositionsArray.body[ index ].stats.qty
        //             portfolioPositionsArray.body[ index ].stats.totalGainLoss = portfolioPositionsArray.body[ index ].stats.currentValue - portfolioPositionsArray.body[ index ].stats.totalCostBasis
        //             portfolioPositionsArray.body[ index ].stats.currentPrice = stockPrice
        //         }
        //     })
            
        //     //Get the dividend history for the portfolio stocks
        //     let portfolioPostionsDividendHistory = await GetDividendHistory( portfolioPositionsBySymbol )

        //     if( portfolioPostionsDividendHistory.status !== 200 ) {
        //         //handle error
        //     }

        //     console.log( portfolioPostionsDividendHistory )

        //     let annualDividend = 0
        //     for( let stock of portfolioPositionsArray.body ) {
        //         let dividendHistory = await fetch( `https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ stock.symbol }?from=2020-01-01&to2021-01-01&apikey=d9d145971ed7f03fdf986723390ab9d0` )
        //         dividendHistory = await dividendHistory.json()
        //         let lastDividend = dividendHistory.historical.slice( 0, 4 )
        //         annualDividend = 0
        //         lastDividend.forEach( div => {
        //             annualDividend += div.adjDividend
        //         })

        //         stock.stats.annualIncome = annualDividend * stock.stats.qty
        //         stock.stats.annualDividend = annualDividend
        //     }
            
        //     let totalCostBasis = 0
        //     let totalCurrentValue = 0
        //     let totalGainLoss = 0
        //     let totalAnnualIncome = 0

        //     for( let stock of portfolioPositionsArray.body ) {
        //         totalCostBasis += stock.stats.totalCostBasis
        //         totalCurrentValue += stock.stats.currentValue
        //         totalGainLoss += stock.stats.totalGainLoss
        //         totalAnnualIncome += stock.stats.annualIncome

        //         stock.stats.effectiveDividendYield = ( ( stock.stats.annualIncome / stock.stats.totalCostBasis ) * 100 ).toFixed( 2 )
        //         stock.stats.currentDividendYield = ( ( stock.stats.annualDividend / stock.stats.currentPrice ) * 100 ).toFixed( 2 )
        //     }

        //     //Calculate the exposures to: marketCap, sector, industry, country
        //     let exposures = await calculatePortfolioExposures( portfolioPositionsArray.body, totalCurrentValue )
            
        //     //Let's calculate the income data
        //     setPagePortfolio( portfolioDetailsById.body )
        //     setPortfolioPositions( portfolioPositionsArray.body )
        //     setPortfolioSummary( { 
        //         totalCostBasis: totalCostBasis,
        //         totalCurrentValue: totalCurrentValue,
        //         totalGainLoss: totalGainLoss,
        //         totalAnnualIncome: totalAnnualIncome,
        //         exposureDetails: exposures
        //      } )
        // } catch ( e ) {
        //     throw e
        // }
    }

    const calculatePortfolioExposures = async( stockList, totalValue ) => {
        let exportDetails = { industry: {}, sector: {}, country: {}, summary: {} }

        //Check if any of the holdings are an ETF.  If so, get all of the holdings.

        //For each stock in the list, get their profile details
    }

    const getUserPortfolios = async ( userId ) => {
        try {
            let userPortfolioSearch = await GetUserPortfolios()

            if( userPortfolioSearch.status !== 200 ) {
                //handle error
            }

            setUserPortfolios( userPortfolioSearch.body )
        } catch ( e ) {

        }
    }

    const getPortfolio = async ( portfolioId ) => {
        try {
            // //Add the portfolio to the users list of portfolios
            // let result = await userData.findOne( { userName: "an691894" } )

            // if( !result ) {
            //     alert("Could not find user record")
            //     return
            // }
            
            // result = result.myPortfolios.find( a => a.id == portfolioId )
            
            // if( !result ) {
            //     alert("Could not find portfolio id for user")
            //     return
            // }

            // setPagePortfolio( result )
        } catch ( e ) {

        }
    }

    const sumByObjectKey = ( list, key ) => {
        let total = 0
        list.forEach( amt => {
            total += Number( amt[ key ] )
        })

        return total.toFixed( 2 )
    }

    const getTaxLots = async ( portfolioStockId ) => {
        try {
            let portfolioTransactions = await GetPortfolioTaxLots( portfolioStockId )

            let stockSymbol = await GetPortfolioStockById( portfolioStockId )

            //summarize quantity and total cost
            let totalQty = 0
            let totalCostBasis = 0
            let averageCostBasis = 0
            let totalGainLoss = 0
            let totalAnnualIncome = 0
            let totalCurrentValue = 0

            //2) For each stock, get current stock prices,  calculate total stats
            let stockSummaries = {}

            //Get stock prices
            let stockPrices = await fetch( `https://financialmodelingprep.com/api/v3/quote/${ stockSymbol.symbol }?apikey=d9d145971ed7f03fdf986723390ab9d0` )

            stockPrices = await stockPrices.json()
            stockPrices = stockPrices[ 0 ].price

            //Get dividend information
            let dividendHistory = await fetch( `https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ stockSymbol.symbol }?from=2020-01-01&to2021-01-01&apikey=d9d145971ed7f03fdf986723390ab9d0` )
            dividendHistory = await dividendHistory.json()
            let lastDividend = dividendHistory.historical.slice( 0, 4 )
            let annualDividend = 0
            lastDividend.forEach( div => {
                annualDividend += div.adjDividend
            })

            //calc the total value, gain/loss, and income for each tax lot
            for( let lot of portfolioTransactions ) {
                lot.currentValue = ( lot.transQty * stockPrices ).toFixed( 2 )
                lot.gainLoss = ( lot.currentValue - lot.transTotalCostBasis ).toFixed( 2 )
                lot.annualIncome = ( lot.transQty * annualDividend ).toFixed( 2 )
                lot.effectiveDividendYield = ( ( annualDividend / lot.transCostBasis ) * 100 ).toFixed( 2 )
                lot.currentDividendYield = ( ( annualDividend / stockPrices ) * 100 ).toFixed( 2 )
            }


            totalQty = sumByObjectKey( portfolioTransactions, 'transQty' )
            totalCostBasis = sumByObjectKey( portfolioTransactions, 'transTotalCostBasis' )
            totalGainLoss = sumByObjectKey( portfolioTransactions, 'gainLoss' )
            totalAnnualIncome = sumByObjectKey( portfolioTransactions, 'annualIncome' )
            totalCurrentValue = sumByObjectKey( portfolioTransactions, 'currentValue' )

            //setPortfolioSummary( { totalQty: totalQty, totalCostBasis: totalCostBasis, averageCostBasis: averageCostBasis } )
            setTaxLotsSummary( { totalCostBasis: totalCostBasis, totalQty: totalQty, totalCurrentValue: totalCurrentValue, totalGainLoss: totalGainLoss, totalAnnualIncome: totalAnnualIncome, totalEffectiveDividendYield: ((totalAnnualIncome/totalCostBasis)*100).toFixed(2) } )
            setTaxLots( portfolioTransactions )

        } catch ( e ) {
            console.log( 'here we araklj')
            alert( e.toString() )
        }
    }

    const addStockToPortfolio = async ( ticker, portfolioId ) => {
        try {            
            //Update the record
            let result = await AddStockToPortfolio( ticker.symbol, portfolioId )
            
            //increment the userPortfolio record
            await UpdateUserPortfolioPositionCount( portfolioId, 1 )
            
            if( !result ) {
                alert("Could not add ticker to portfolio.")
                return
            }

            await getPortfolioPositions( portfolioId )
            return true
        } catch ( e ) {
            throw e
        }
    }

    const addTaxLotsToPosition = async ( taxLotsInput, symbol ) => {
        //get the portfolioStockId
        let portfolioStockId = await GetPortfolioStockDetails( symbol, pagePortfolio._id.toString() )

        taxLotsInput.forEach( ( lot, index ) => {
            taxLotsInput[ index ].portfolioStockId = portfolioStockId._id.toString()
        })
        
        await AddTaxLotsToStockPosition( taxLotsInput )
        await getTaxLots( portfolioStockId._id.toString() )
        await getPortfolioPositions( pagePortfolio._id.toString() )
        
    }

    const addTaxLotToPosition = async ( date, quantity, price, fee, total ) => {
        try {
            // let tempTaxLots = { ...taxLots }

            // tempTaxLots.taxLots.push( { date: date.value, quantity: quantity.value, price: price.value, fee: fee.value, total: total.value } )

            // //update the record in the db
            // //Add the portfolio to the users list of portfolios
            // let result = await userData.findOne( { userName: "an691894" } )

            // //find index of our portfolio
            // let portfolioIndex = result.myPortfolios.findIndex( a => a.id == pagePortfolio.id )

            // //find index of our position
            // let positionIndex = result.myPortfolios[ portfolioIndex ].myPositions.findIndex( a => a.symbol === taxLots.symbol )
            // result.myPortfolios[ portfolioIndex ].myPositions[ positionIndex ].taxLots = tempTaxLots.taxLots

            // result = await userData.updateOne( { userName: 'an691894' }, result )

            // return true
        } catch ( e ) {
            alert( e.toString() )
            return false
        }
    }

    const addPortfolio = async ( portfolio ) => {
        try {
            //Make sure a value exists
            if( !portfolio || !portfolio.value || portfolio.value === '' ) {
                alert("Please enter a Portfolio name")
                return
            }

            //Add the portfolio to the users list of portfolios
            let result = await AddNewPortfolio( portfolio.value )

            if( !result ) {
                alert("Could not find user record to update")
                return
            }

            await getUserPortfolios()
            
        } catch ( e ) {

        }
    }

    const editPortfolio = async ( originalName, newName, portfolioId ) => {
        try {
            //Make sure a value exists
            if( !newName || !newName.value || newName.value === '' ) {
                alert("Please enter a Portfolio name")
                return
            }

            //Find index of the record
            await EditPortfolioDetails( portfolioId, newName )
            await getUserPortfolios()
        } catch ( e ) {
            throw e.toString()
        }
    }

    return (
        <PortfolioContext.Provider // this is where we expose state values and functions to the rest of the application
            value={{
                state: { // for organization reasons I like keep all state values inside a state object
                    pagePortfolio,
                    taxLots,
                    userPortfolios,
                    portfolioPositions,
                    portfolioSummary,
                    taxLotsSummary
                },
                getPortfolio,
                addPortfolio,
                editPortfolio,
                setPagePortfolio,
                addStockToPortfolio,
                addTaxLotToPosition,
                getTaxLots,
                getUserPortfolios,
                getPortfolioPositions,
                addTaxLotsToPosition,
                deletePositionFromPortfolio,
                deleteTaxLotFromPortfolio,
                deletePortfolio
            }}
        >
            { props.children }
        </PortfolioContext.Provider>
    )
}

export default PortfolioProvider;
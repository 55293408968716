
const incomeStatementTable = {
    acceptedDate: { show: false },
    calendarYear: { show: true, group: 'overview', keyField: true, name: 'calendarYear', localName: 'CY' },
    cik: { show: false },
    costAndExpenses: { show: true, group: "other expenses" },
    costOfRevenue: { show: true, group: 'gross profit', name: 'costOfRevenue', localName: 'Cost of Revenue', order: 1 },
    date: { show: true, group: 'overview', name: 'date', localName: 'Date' },
    depreciationAndAmortization: { show: true, group: 'net income', name: 'depreciationAndAmortization', localName: 'Depreciation', order: 4 },
    ebitda: { show: true, group: 'earnings', name: 'ebitda', localName: 'EBITDA', order: 2 },
    ebitdaratio: 0.6226817652182767,
    eps: { show: true, group: 'earnings', name: 'eps', localName: 'EPS', order: 0, keyField: true },
    epsdiluted: { show: true, group: 'earnings', name: 'epsdiluted', localName: 'EPS Diluted', order: 1 },
    fillingDate: { show: true, group: 'overview', name: 'fillingDate', localName: 'Filing Date' },
    generalAndAdministrativeExpenses: { show: true, group: 'operating income', name: 'generalAndAdministrativeExpenses', localName: 'SG&A Expenses', order: 2  },
    grossProfit: { show: true, group: 'gross profit', name: 'grossProfit', localName: 'Gross Profit', keyField: true },
    grossProfitRatio: 0,
    incomeBeforeTax: { show: true, group: 'net income', name: 'incomeBeforeTax', localName: 'EBIT', order: 3 },
    incomeBeforeTaxRatio: 0.4769121124020046,
    incomeTaxExpense: { show: true, group: 'net income', name: 'incomeTaxExpense', localName: 'Income Tax Expense', order: 4 },
    interestExpense: { show: true, group: 'net income', name: 'interestExpense', localName: 'Interest Expense', order: 1 },
    interestIncome: { show: true, group: 'net income', name: 'interestIncome', localName: 'Interest Income', order: 0 },
    netIncome: { show: true, group: 'net income', name: 'netIncome', localName: 'Net Income', keyField: true, order: 6 },
    netIncomeRatio: 0.3778295643517314,
    operatingExpenses: { show: true, group: 'operating income', name: 'operatingExpenses', localName: 'Operating Expenses', order: 3  },
    operatingIncome: { show: true, group: 'operating income', name: 'operatingIncome', localName: 'Operating Income', keyField: true, order: 2 },
    operatingIncomeRatio: 0,
    otherExpenses: { show: true, group: 'net income', name: 'otherExpenses', localName: 'Other Expenses', order: 2 },
    period: { show: true, group: 'overview', name: 'period', localName: 'Period' },
    reportedCurrency: { show: true, group: 'overview', name: 'reportedCurrency', localName: 'Currency' },
    researchAndDevelopmentExpenses: { show: true, group: 'net income', name: 'researchAndDevelopmentExpenses', localName: 'R&D Expense', order: 5 },
    revenue: { show: true, group: 'gross profit', name: 'revenue', localName: 'Revenue', order: 0 },
    sellingAndMarketingExpenses: { show: true, group: 'operating income', name: 'sellingAndMarketingExpenses', localName: 'S&M Expenses', order: 0 },
    sellingGeneralAndAdministrativeExpenses: { show: true, group: 'operating income', name: 'sellingGeneralAndAdministrativeExpenses', localName: 'S&G Expenses', order: 1 },
    symbol: "LOB",
    totalOtherIncomeExpensesNet: 0,
    weightedAverageShsOut: { show: true, group: 'earnings', name: 'weightedAverageShsOut', localName: 'Shares Outstanding', order: 4 },
    weightedAverageShsOutDil: { show: true, group: 'earnings', name: 'weightedAverageShsOutDil', localName: 'Shares Outstanding Diluted', order: 5 },
}

const balanceSheetTable = {
    acceptedDate: { show: true, group: 'overview', name: 'acceptedDate', localName: 'Accepted Date' },
    accountPayables: { show: true, group: 'liability', name: 'accountPayables', localName: 'Accounts Payable' },
    accumulatedOtherComprehensiveIncomeLoss: -1920000000,
    calendarYear: { show: true, group: 'overview', name: 'calendarYear', localName: 'Calendar Year', keyField: true },
    capitalLeaseObligations:  { show: true, group: 'liability', name: 'capitalLeaseObligations', localName: 'Capital Lease Obligations' },
    cashAndCashEquivalents:  { show: true, group: 'asset', name: 'cashAndCashEquivalents', localName: 'Cash and Equivalents' },
    cashAndShortTermInvestments: 1603000000,
    cik: { show: true, group: 'overview', name: 'cik', localName: 'CIK' },
    commonStock: { show: true, group: 'stockHolderEquity', name: 'commonStock', localName: 'Common Stock' },
    date: { show: true, group: 'overview', name: 'date', localName: 'Date' },
    deferredRevenue: { show: true, group: 'liability', name: 'deferredRevenue', localName: 'Total Deferred Revenue' },
    deferredRevenueNonCurrent: { show: true, group: 'liability', name: 'deferredRevenueNonCurrent', localName: 'Non Current Deferred Revenue' },
    deferredTaxLiabilitiesNonCurrent: { show: true, group: 'liability', name: 'deferredTaxLiabilitiesNonCurrent', localName: 'Non Current Deferred Taxes' },
    fillingDate: { show: true, group: 'overview', name: 'fillingDate', localName: 'Filing Date' },
    goodwill: { show: true, group: 'asset', name: 'goodwill', localName: 'Goodwill' },
    goodwillAndIntangibleAssets: { show: true, group: 'asset', name: 'goodwillAndIntangibleAssets', localName: 'Goodwill & Intangibles' },
    intangibleAssets: { show: true, group: 'asset', name: 'intangibleAssets', localName: 'Intangibles' },
    inventory: { show: true, group: 'asset', name: 'inventory', localName: 'Inventory' },
    longTermDebt: { show: true, group: 'liability', name: 'longTermDebt', localName: 'Long Term Debt' },
    longTermInvestments: { show: true, group: 'asset', name: 'longTermInvestments', localName: 'Long Term Investment' },
    minorityInterest: { show: true, group: 'equity', name: 'minorityInterest', localName: 'Minority Interest' },
    netDebt: { show: true, group: 'liability', name: 'netDebt', localName: 'Net Debt' },
    netReceivables: { show: true, group: 'asset', name: 'netReceivables', localName: 'Net Receivables' },
    otherAssets: { show: true, group: 'asset', name: 'otherAssets', localName: 'Other Assets' },
    otherCurrentAssets: { show: true, group: 'asset', name: 'otherCurrentAssets', localName: 'Current Assets' },
    otherCurrentLiabilities: { show: true, group: 'liability', name: 'otherCurrentLiabilities', localName: 'Other Current Liabilities' },
    otherLiabilities: { show: true, group: 'liability', name: 'otherLiabilities', localName: 'Other Liabilities' },
    otherNonCurrentAssets: { show: true, group: 'asset', name: 'otherNonCurrentAssets', localName: 'Other Non-Current Assets' },
    otherNonCurrentLiabilities: { show: true, group: 'liability', name: 'otherNonCurrentLiabilities', localName: 'Other Non-Current Liabilities' },
    othertotalStockholdersEquity: { show: true, group: 'equity', name: 'othertotalStockholdersEquity', localName: 'Other Total Stockholder Equity' },
    period: { show: true, group: 'overview', name: 'period', localName: 'Period' },
    preferredStock: { show: true, group: 'equity', name: 'preferredStock', localName: 'Preferred Stock' },
    propertyPlantEquipmentNet: { show: true, group: 'asset', name: 'propertyPlantEquipmentNet', localName: 'PP&E' },
    reportedCurrency: { show: true, group: 'overview', name: 'reportedCurrency', localName: 'Currency' },
    retainedEarnings: { show: true, group: 'equity', name: 'retainedEarnings', localName: 'Retained Earnings' },
    shortTermDebt: { show: true, group: 'liability', name: 'shortTermDebt', localName: 'Short Term Debt' },
    shortTermInvestments: { show: true, group: 'asset', name: 'shortTermInvestments', localName: 'Short Term Investments' },
    symbol: { show: true, group: 'overview', name: 'symbol', localName: 'Symbol' },
    taxAssets: { show: true, group: 'assets', name: 'taxAssets', localName: 'Tax Assets' },
    taxPayables: { show: true, group: 'liability', name: 'taxPayables', localName: 'Tax Payables' },
    totalAssets: { show: true, group: 'asset', name: 'totalAssets', localName: 'Total Assets' },
    totalCurrentAssets: { show: true, group: 'asset', name: 'totalCurrentAssets', localName: 'Total Current Assets' },
    totalCurrentLiabilities: { show: true, group: 'liability', name: 'totalCurrentLiabilities', localName: 'Total Current Liabilities' },
    totalDebt: { show: true, group: 'liability', name: 'totalDebt', localName: 'Total Debt' },
    totalEquity: { show: true, group: 'equity', name: 'totalEquity', localName: 'Total Equity' },
    totalInvestments: { show: true, group: 'asset', name: 'totalInvestments', localName: 'Total Investments' },
    totalLiabilities: { show: true, group: 'liability', name: 'totalLiabilities', localName: 'Total Liabilities' },
    totalLiabilitiesAndStockholdersEquity: { show: true, group: 'equity', name: 'totalLiabilitiesAndStockholdersEquity', localName: 'Total Liabilities and Equity' },
    totalLiabilitiesAndTotalEquity: { show: true, group: 'equity', name: 'totalLiabilitiesAndTotalEquity', localName: 'Total Liabilities and Equity' },
    totalNonCurrentAssets: { show: true, group: 'asset', name: 'totalNonCurrentAssets', localName: 'Total Non Current Assets' },
    totalNonCurrentLiabilities: { show: true, group: 'liability', name: 'totalNonCurrentLiabilities', localName: 'Total Non Current Liabilities' },
    totalStockholdersEquity: { show: true, group: 'equity', name: 'totalStockholdersEquity', localName: 'Total S/E' },
}

const cashFlowTable = {
    acceptedDate: { show: true, group: 'overview', name: 'acceptedDate', localName: 'Accepted Date' },
    accountsPayables: { show: true, group: 'operations', name: 'accountsPayables', localName: 'Accounts Payable' },
    accountsReceivables: { show: true, group: 'operations', name: 'accountsReceivables', localName: 'Accounts Receivable' },
    acquisitionsNet: { show: true, group: 'investments', name: 'acquisitionsNet', localName: 'Net Acquisitions' },
    calendarYear: { show: true, group: 'overview', name: 'calendarYear', localName: 'Calendar Year', keyField: true },
    capitalExpenditure: { show: true, group: 'investments', name: 'capitalExpenditure', localName: 'Capital Expenditure' },
    cashAtBeginningOfPeriod: { show: true, group: 'summary', name: 'cashAtBeginningOfPeriod', localName: 'Cash at Beginning of Period' },
    cashAtEndOfPeriod: { show: true, group: 'summary', name: 'cashAtEndOfPeriod', localName: 'Cash at End of Period' },
    changeInWorkingCapital: { show: true, group: 'operations', name: 'changeInWorkingCapital', localName: 'Change in Working Capital' },
    cik: { show: true, group: 'overview', name: 'cik', localName: 'CIK' },
    commonStockIssued: { show: true, group: 'investments', name: 'commonStockIssued', localName: 'Common Stock Issued' },
    commonStockRepurchased: { show: true, group: 'investments', name: 'commonStockRepurchased', localName: 'Common Stock Repurchased' },
    date: { show: true, group: 'overview', name: 'date', localName: 'Date' },
    debtRepayment: { show: true, group: 'financing', name: 'debtRepayment', localName: 'Debt Repayment' },
    deferredIncomeTax: { show: true, group: 'operations', name: 'deferredIncomeTax', localName: 'Deferred Income Tax' },
    depreciationAndAmortization: { show: true, group: 'operations', name: 'depreciationAndAmortization', localName: 'Depreciation & Amortization' },
    dividendsPaid: { show: true, group: 'financing', name: 'dividendsPaid', localName: 'Dividends Paid' },
    effectOfForexChangesOnCash: { show: true, group: 'summary', name: 'effectOfForexChangesOnCash', localName: 'FX Adjustments' },
    fillingDate: { show: true, group: 'overview', name: 'fillingDate', localName: 'Filing Date' },
    freeCashFlow: { show: true, group: 'summary', name: 'freeCashFlow', localName: 'Free Cash Flow' },
    inventory: { show: true, group: 'operations', name: 'inventory', localName: 'Inventory' },
    investmentsInPropertyPlantAndEquipment: { show: true, group: 'investments', name: 'investmentsInPropertyPlantAndEquipment', localName: 'Investments in PP&E' },
    netCashProvidedByOperatingActivities: { show: true, group: 'operations', name: 'netCashProvidedByOperatingActivities', localName: 'Net Cash from Operating Activities', keyField: true },
    netCashUsedForInvestingActivites: { show: true, group: 'investments', name: 'netCashUsedForInvestingActivites', localName: 'Net Cash Used by Investing Activities', keyField: true },
    netCashUsedProvidedByFinancingActivities: { show: true, group: 'financing', name: 'netCashUsedProvidedByFinancingActivities', localName: 'Net Cash from Financing Activities', keyField: true },
    netChangeInCash: { show: true, group: 'summary', name: 'netChangeInCash', localName: 'Net Change in Cash', keyField: true },
    netIncome: { show: true, group: 'operations', name: 'netIncome', localName: 'Net Income' },
    operatingCashFlow: { show: true, group: 'operations', name: 'operatingCashFlow', localName: 'Operating Cash Flow' },
    otherFinancingActivites: { show: true, group: 'investments', name: 'otherFinancingActivites', localName: 'Other Financing Activities' },
    otherInvestingActivites: { show: true, group: 'investments', name: 'otherInvestingActivites', localName: 'Other Investing Activities' },
    otherNonCashItems: { show: true, group: 'operations', name: 'otherNonCashItems', localName: 'Other Non Cash Items' },
    otherWorkingCapital: { show: true, group: 'operations', name: 'otherWorkingCapital', localName: 'Other Working Capital' },
    period: { show: true, group: 'overview', name: 'period', localName: 'Period' },
    purchasesOfInvestments: { show: true, group: 'investments', name: 'purchasesOfInvestments', localName: 'Purchase of Investments' },
    reportedCurrency: { show: true, group: 'overview', name: 'reportedCurrency', localName: 'Currency' },
    salesMaturitiesOfInvestments: { show: true, group: 'investments', name: 'salesMaturitiesOfInvestments', localName: 'Sales of Investments' },
    stockBasedCompensation: { show: true, group: 'operations', name: 'stockBasedCompensation', localName: 'Stock Based Compensation' },
    symbol: { show: true, group: 'overview', name: 'symbol', localName: 'Symbol' },
}

const incomeTable = {
    "dividendPerShareTTM" : { location: 'ratios', name: 'Dividend' } ,
    "dividendYielPercentageTTM" : { location: 'ratios', name: 'Dividend Yield' },
    "payoutRatioTTM" : { location: 'ratios', name: 'Payout Ratio' },
    "dividendPaidAndCapexCoverageRatioTTM": { location: 'ratios', name: 'Dividend and Capex Coverage'}
}

const valuationTable = {
    "priceToBookRatioTTM" : 'Price to Book Value',
    "priceToSalesRatioTTM" : 'Price to Sales',
    "priceEarningsRatioTTM" : 'Price to Earnings',
    "priceToFreeCashFlowsRatioTTM" : 'Price to Free Cash Flow',
    "priceToOperatingCashFlowsRatioTTM" : 'Price to Operating Cash Flow',
    "priceCashFlowRatioTTM" : 'Price to Cash Flow',
    "priceEarningsToGrowthRatioTTM" : 'Price to Earnings Growth',
    "priceSalesRatioTTM" : 'Price to Sales Ratio',
    "enterpriseValueMultipleTTM" : 'Enterprise Value',
    "priceFairValueTTM" : 'Price to Fair Value'
}

const economicIndicators = {
    GDP: { name: 'GDP' },
    realGDP: { name: 'Real GDP' },    
    nominalPotentialGDP: { name: 'Nominal Potential GDP' },
    realGDPPerCapita: { name: 'Real GDP Per Capital' },
    federalFunds: { name: 'Federal Funds Rate' },
    CPI: { name: 'CPI' },
    inflationRate: { name: 'Inflation Rate' },
    inflation: { name: 'Inflation' },
    retailSales: { name: 'Retail Sales' },
    consumerSentiment: { name: 'Consumer Sentiment' },
    durableGoods: { name: 'Durable Goods' },
    unemploymentRate: { name: 'Unemployment Rate' },
    totalNonfarmPayroll: { name: 'Total Non-farm Payroll' },
    initialClaims: { name: 'Initial Claims' },
    industrialProductionTotalIndex: { name: 'Industrial Production Index' },
    newPrivatelyOwnedHousingUnitsStartedTotalUnits: { name: 'Housing Starts' },
    totalVehicleSales: { name: 'Total Vehicle Sales' },
    retailMoneyFunds: { name: 'Retail Money Funds' },
    smoothedUSRecessionProbabilities: { name: 'US Recession Probability' },
    '3MonthOr90DayRatesAndYieldsCertificatesOfDeposit': { name: '90 Day CD Rate' },
    commercialBankInterestRateOnCreditCardPlansAllAccounts: { name: 'Credit Card Rates' },
    '30YearFixedRateMortgageAverage': { name: '30yr Mortgage Rate' },
    '15YearFixedRateMortgageAverage': { name: '15yr Mortgage Rate' }
}

const earningsAndFinancials = {
    netIncomePerShare: { value: 'EPS', background: "#14BBC6" },
    freeCashFlowPerShare: { value: 'Free Cash Flow', background: "#7EA8CE" },
    bookValuePerShare: { value: 'Book Value', background: "#DE7056" },
    revenuePerShare: { value: 'Revenue per share', background: "#28AF76" },
}

const exchangeOptions = [
    {
        value: 'nyse',
        label: 'NYSE'
    },
    {
        value: 'nasdaq',
        label: 'NASDAQ'
    },
    {
        value: 'amex',
        label: 'AMEX'
    },
    {
        value: 'euronext',
        label: 'EURONEXT'
    },
    {
        value: 'etf',
        label: 'ETF'
    },
    {
        value: 'mutual_fund',
        label: 'Mutual Fund'
    },
]

const sectorOptions = [
    {
        value: 'consumer_cyclical',
        label: 'Consumer Cyclical'
    },
    {
        value: 'energy',
        label: 'Energy'
    },
    {
        value: 'technology',
        label: 'Technology'
    },
    {
        value: 'industrials',
        label: 'Industrials'
    },
    {
        value: 'financial_services',
        label: 'Financial Services'
    },
    {
        value: 'communication_services',
        label: 'Communication Services'
    },
    {
        value: 'consumer_defensive',
        label: 'Consumer Defensive'
    },
    {
        value: 'healthcare',
        label: 'Healthcare'
    },
    {
        value: 'real_estate',
        label: 'Real Estate'
    },
    {
        value: 'utilities',
        label: 'Utilities'
    },
    {
        value: 'industrial_goods',
        label: 'Industrial Goods'
    },
    {
        value: 'financial',
        label: 'Financial'
    },
    {
        value: 'services',
        label: 'Services'
    },
    {
        value: 'conglomerates',
        label: 'Conglomerates'
    },
]

module.exports = {
    valuationTable,
    incomeTable,
    economicIndicators,
    incomeStatementTable,
    balanceSheetTable,
    cashFlowTable,
    earningsAndFinancials,
    exchangeOptions,
    sectorOptions
}
import React, { useState, useEffect, useRef } from 'react'
import { ChevronsLeft } from 'react-feather'

export default function StockDetailsPageMenu ( props ) {

    const { priceChartRef, summaryRef, newsRef, ratingsRef, exposureRef, visibleSection, setVisibleSection } = props

    const setMenuOptionClasses = ( section ) => {
        if( section === visibleSection ) {
            return 'align-middle fw-bold my-auto'
        }

        return 'align-middle fw-bold my-auto'
    }

    const setChevron  = ( option ) => {
        if( option === visibleSection ) {
            return <ChevronsLeft />
        }
    }

    const scrollTo = ( ele, section ) => {
        //ele.scrollIntoView()
        console.log( window.pageYOffset )
        let { top } = ele.getBoundingClientRect()

        top += window.pageYOffset - 175

        window.scrollTo({ top: top, behavior: 'smooth' } )
        setVisibleSection( section )
    };



    if( !visibleSection ) {
        return <div></div>
    }

    return (
        <div style={{marginRight:25,zIndex:99}} className="sticky-top sticky-offset">
            <div class="sticky-top sticky-offset" style={{backgroundColor:"#1f2944",borderRadius:'.5rem',paddingLeft:'1.5rem'}}>
                <div style={{height:'2.5rem'}} className="pt-2">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( priceChartRef.current, 'PriceChart' )
                    }} class={ setMenuOptionClasses( 'PriceChart' ) }>Price { setChevron( 'PriceChart' ) }  </span>
                </div>
                <div style={{height:'2.5rem'}} className="pt-2">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( summaryRef.current, 'Summary' )
                    }} class={ setMenuOptionClasses( 'Summary' ) }>Summary { 'Summary' === visibleSection && <ChevronsLeft />}  </span>
                </div>
                <div style={{height:'2.5rem'}} className="">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( props.newsRef.current, 'News' )
                    }} class={ setMenuOptionClasses( 'News' ) } >News { 'News' === visibleSection && <ChevronsLeft />} </span>
                </div>
                <div style={{height:'2.5rem'}} className="">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( props.earningsAndFinancialsRef.current, 'EarningsAndFinancials' )
                    }} class={ setMenuOptionClasses( 'EarningsAndFinancials' ) } >Earnings & Financials { 'EarningsAndFinancials' === visibleSection && <ChevronsLeft />} </span>
                </div>
                <div style={{height:'2.5rem'}} className="">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( ratingsRef.current, "Ratings" )
                    }} class={ setMenuOptionClasses( 'Ratings' ) } >Ratings { 'Ratings' === visibleSection && <ChevronsLeft />} </span>
                </div>
                <div style={{height:'2.5rem'}} className="">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( props.stockPeersRef.current, 'Peers' )
                    }} class={ setMenuOptionClasses( 'Peers' ) } >Peers { 'Peers' === visibleSection && <ChevronsLeft />} </span>
                </div>
                <div style={{height:'2.5rem'}} className="pb-2">
                    <span style={{cursor:'pointer'}} onClick={() => {
                        scrollTo( exposureRef.current, 'Exposure' )
                    }} class={ setMenuOptionClasses( 'Exposure' ) } >Exposure { 'Exposure' === visibleSection && <ChevronsLeft />} </span>
                </div>
            </div>
        </div>
    )

}
import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import Select from "react-dropdown-select"
import SearchBar from '../components/SearchBar'
// import HomepageStockSearchView from '../components/HomepageStockSearchView'
// import HomepageWatchlist from '../components/HomepageWatchlist'
// import HomepagePortfolioSummary from '../components/HomepagePortfolioSummary'
import HomepageMarketNews from '../components/HomepageMarketNews'
import HomepageUpcomingEarnings from '../components/HomepageUpcomingEarnings'
// import HomepageIndexs from '../components/HomepageIndexs'
// import HomepageCalendar from '../components/HomepageCalendar'
import HomepageSectorPerformance from '../components/HomepageSectorPerformance'
// import HomepageIndustryPriceToEarnings from '../components/HomepageIndustryPriceToEarnings'
// import HomepageSectorPriceToEarnings from '../components/HomepageSectorPriceToEarning'
// import HomepageTreasuries from '../components/HomepageTreasuries'
// import HomepageEconomicIndicators from '../components/HomepageEconomicIndicators'
// import HomepageCommodities from '../components/HomepageCommodities'
// import HomepageCurrencies from '../components/HomepageCurrencies'
// import HomepageCryptos from '../components/HomepageCryptos'
// import HomepageMarketRiskPremium from '../components/HomepageMarketRiskPremium'
import HomepageDividendCalendar from '../components/HomepageDividendCalendar'
import HomepageWatchlist from '../components/HomepageWatchlist'
import HomepageIndexs from '../components/HomepageIndexs'
import HomepageMarketMovers from '../components/HomepageMarketMovers'

export default function Homepage( props ) {

    return (
        <main style={{marginTop:150,marginRight:'10%',marginLeft:'10%'}}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5375200381154577" crossOrigin="anonymous"></script>
            
            {/* INDEXES */}
            <HomepageIndexs
                { ...props }
            />

            <HomepageWatchlist
                { ...props }
                userContext={ props.userContext }
                globalContext={ props.globalContext }
            />

            <div className="d-flex mt-4">
                <HomepageMarketMovers { ...props } />
                <HomepageMarketNews { ...props } />
                {/* <HomepageSectorPerformance { ...props } /> */}

                {/* <div style={{overflowY:'auto'}}>
                    <HomepageIndexs { ...props } />
                    <HomepageTreasuries { ...props } />
                </div> */}
                <div className=''>
                    {/* <HomepageSectorPerformance { ...props } /> */}
                    {/* <HomepageMarketNews { ...props } /> */}
                </div>
            </div>
            <div className="d-flex de">
                <div className=''>
                    {/* <HomepageSectorPerformance { ...props } /> */}
                </div>
                <div className=''>
                    <div className='d-flex de'>
                        {/* <HomepageCalendar { ...props } /> */}
                        {/* <HomepageUpcomingEarnings { ...props } /> */}
                    </div>
                    {/* <div className="">
                        <HomepageEconomicIndicators { ...props } />
                    </div> */}
                </div>
                <div style={{ maxHeight:300, overflowY:'auto' }}>
                    {/* <HomepageDividendCalendar { ...props } /> */}
                </div>
            </div>
            <div className='d-flex de'>
                {/* <div className='mx-2' style={{maxHeight:300,overflowY:'auto'}}>
                    <HomepageCommodities { ...props } />
                </div> */}
                {/* <div className='mx-2' style={{ maxHeight:300,overflowY:'auto'}}>
                    <HomepageCurrencies { ...props } />
                </div> */}
                {/* <div className='mx-2' style={{ maxHeight:300,overflowY:'auto'}}>
                    <HomepageCryptos { ...props } />
                </div> */}
            </div>
            <div className='d-flex de'>
                {/* <div className='mx-2' style={{ maxHeight:300, overflowY:'auto' }}>
                    <HomepageMarketRiskPremium { ...props } />
                </div> */}
                
            </div>
        </main>
    )
}
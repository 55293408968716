import React, { useState } from 'react'
import UserContext from './UserContext'
import * as Realm from "realm-web"
import moment from 'moment'
import _ from 'lodash'
import { GetUserPrimaryWatchlistId, GetWatchlistStocks, GetUserCalendarEvents, GetUserWatchlists, GetUserArticles, FindStockInWatchlist, AddEventToUserCalendar, GetUserData, GetWatchlistStockPriceDetails, AddStockToWatchlist, DeleteStockFromWatchlist, DeleteUserWatchlist, SetPrimaryWatchlist, UpdateWatchlistDetails, AddNewWatchlist, AddArticleToRecentlyViewed, GetRecentlyViewedArticles, DeleteUserArticle } from '../../utils/realmFunctions'

const REALM_APP_ID = process.env.REACT_APP_REALM_ID // e.g. myapp-abcde
const app = new Realm.App({ id: REALM_APP_ID })
const userData = false

const UserProvider = props => {

    const [ user, setUser ] = useState( false )
    const [ allUsersWatchlists, setAllUsersWatchlists ] = useState( false )
    const [ allUserWatchlistStocks, setAllUserWatchlistStocks ] = useState( false )
    const [ primaryWatchListId, setPrimaryWatchlistId ] = useState( false )
    const [ userPrimaryWatchlist, setUserPrimaryWatchlist ] = useState( false )
    const [ watchlistDetails, setWatchlistDetails ] = useState( false )
    const [ userArticles, setUserArticles ] = useState( false )
    const [ userCalendarEvents, setUserCalendarEvents ] = useState( false )
    const [ userWatchlistsByStockPresence, setUserWatchlistsByStockPresence ] = useState( false )
    const [ primaryWatchlistDetails, setPrimaryWatchlistDetails] = useState( false )
    const [ recentlyViewed, setRecentlyViewed ] = useState( [] )

    //Homepage Watchlist
    const [ homepageWatchlistDetails, setHomepageWatchlistDetails ] = useState( false )

    const getRecentlyViewedArticles = async() => {

        let res = await GetRecentlyViewedArticles()

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setRecentlyViewed( res.body )
    }

    const addArticleToRecentlyView = async( article ) => {
        let res = await AddArticleToRecentlyViewed( article )
        
        if( res.status !== 200 ) {
            return { error: res.error }
        }

        return res
    }

    const deleteUserWatchlist = async ( watchlistId ) => {

        let res = await DeleteUserWatchlist( watchlistId ) 

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        loadUserWatchlists()
        loadUserPrimaryWatchlist()

        return res
    }

    const savePosition = async ( stock, index ) => {
        let tempUser = { ...user }
        let myStocks = [ ...user.myStocks ]

        //get our values
        let price = document.getElementById( `${ stock }Price` )
        let quantity = document.getElementById( `${ stock }Quantity` )
        let date = document.getElementById( `${ stock }Date` )

        if ( !price || !quantity || !date || !price.value || !quantity.value || !date.value ) return

        myStocks[ index  ].positions.push( { purchasePrice: Number( price.value ), purchaseQuantity: Number( quantity.value ), purchaseDate: moment( date.value, 'MM/DD/YYYY' ).format() } )

        setUser( await userData.findOneAndReplace( { userName: 'an691894' }, { ...tempUser, myStocks: myStocks }, { returnNewDocument: true } ) )

        price.value = ''
        quantity.value = ''
        date.value = ''
    }

    const deleteFromMyArticles = async ( articleKey ) => {
        let res = await DeleteUserArticle( { articleKey: articleKey } )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        getUserArticles()
    }

    const deletePosition = async ( symbolIndex, positionIndex ) => {
        let tempUser = { ...user }
        let myStocks = [ ...user.myStocks ]
        myStocks[ symbolIndex ].positions.splice( positionIndex, 1 )
        setUser( await userData.findOneAndReplace( { userName: 'an691894' }, { ...tempUser, myStocks: myStocks }, { returnNewDocument: true } ) )
    }

    const editWatchlist = async ( watchlistName, primary, watchlistId ) => {
        if( primary ) {
            let primaryId = await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserWatchlists' ).findOne( { userId: app.currentUser.customData.userId, primary: true } )

            await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserWatchlists' ).updateOne( { _id: new Realm.BSON.ObjectId( primaryId._id.toString() ) }, { $set: { primary: false } } )
        }

        let res = await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserWatchlists' ).updateOne( { _id: new Realm.BSON.ObjectId( watchlistId ) }, { $set: { name: watchlistName, primary: primary } } )

        loadUserWatchlists()
        loadWatchlistStocks( watchlistId )
        loadUserPrimaryWatchlist()
    }

    const addNewWatchlist = async ( watchlistName, primary ) => {

        let res = await AddNewWatchlist( watchlistName )
        
        if( res.status !== 200 ) {
            return { error: res.error } 
        }

        loadUserWatchlists()
        loadUserPrimaryWatchlist()

        return res
    }

    const loadUserWatchlists = async() => {

        let userWatchlists = await GetUserWatchlists()

        if( userWatchlists.status !== 200 ) {
            //handle the error
        }

        await setAllUsersWatchlists( userWatchlists.body )
        return userWatchlists
    }

    const getUserWatchlistsAndDetermineIfSelectedStockInWatchlist = async ( symbol ) => {
        let watchlists = false

        if( !allUsersWatchlists ) {
            watchlists = await loadUserWatchlists()

            if( watchlists.status !== 200 ) {
                //handle error
            }

            watchlists = watchlists.body
        } else {
            watchlists = [ ...allUsersWatchlists ]
        }

        if( !watchlists ) return []

        //Now for each watchlist, determine if the selected stock is in the watchlist
        for( let watchlist of watchlists ) {
            let symbolExists = await FindStockInWatchlist( watchlist._id.toString(), symbol )

            if( symbolExists.status !== 200 ) {
                return false
            }

            symbolExists = symbolExists.body

            if( !symbolExists ) {
                watchlist.symbolAlreadyExists = false
                watchlist.symbol = symbol
            } else {
                watchlist.symbolAlreadyExists = true
                watchlist.symbol = symbol
                watchlist.watchlistStockId = symbolExists._id.toString()
            }
        }

        setUserWatchlistsByStockPresence( watchlists )
        return watchlists

    }

    const loadUserWatchlistStocks = async( refresh ) => {
        let res = await loadUserWatchlists()

        if( res.status !== 200 ) {
            //handle the error
        }

        //Create an array of the watchlist _id's
        //let idList = res.body.map( a => a._id.toString() )

        //let stocks = await GetWatchlistStocks( idList, true )
        //return stocks
    }

    const refreshApp = async() => {
        await app.currentUser.refreshProfile()
        await app.currentUser.refreshCustomData()
    }

    const loadWatchlistStocks = async ( oId ) => {
        let results = false

        if( !oId ) {
            results = await GetWatchlistStockPriceDetails( false, true )
        } else {
            results = await GetWatchlistStockPriceDetails( [ oId ], false )
        }

        if ( results !== 200 ) {
            //handle error 
        }
 
        setWatchlistDetails( { stocks: results.body, id: results.id, details: results.details } )
    }

    const loadUserPrimaryWatchlist = async() => {
        try {
            let stockPrices = await GetWatchlistStockPriceDetails( false, true )

            if( stockPrices.status !== 200 ) {
                //handle error
            }
            
            setPrimaryWatchlistId( stockPrices.primaryId )
            setPrimaryWatchlistDetails( stockPrices.primaryWatchlistDetails )
            setUserPrimaryWatchlist( stockPrices.body )
        } catch ( e ) {
            console.log( e.toString() )
        }
    }

    const setPrimaryWatchlist = async( watchlistId ) => {
        let res = await SetPrimaryWatchlist( watchlistId )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        loadUserPrimaryWatchlist()
        loadUserWatchlists()
        loadWatchlistStocks( watchlistId )

        return res
    }

    const addToPrimaryWatchlist = async( str ) => {
        try {
            if( !str || str === '' ) {
                alert( 'Please search for a stock...' )
                return
            }

            let updateQuery = { userId: app.currentUser.customData.userId, symbol: str, watchlistId: primaryWatchListId._id.toString() }
            
            await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'WatchlistStocks' ).insertOne( updateQuery )
            await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserWatchlists' ).updateOne( 
                { _id: new Realm.BSON.ObjectId( primaryWatchListId._id ) },
                { $inc: { stocks: 1 } }
            )
            await loadUserPrimaryWatchlist()
        } catch ( e ) {
            console.log( e.toString() )
        }
        
    }

    const removeFromUserCalendar = async( id ) => {
        await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserCalendar' ).findOneAndDelete( { _id: id } )
        await getUserCalendarEvents()
    }

    const removeFromPrimaryWatchlist = async ( oId ) => {
        await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'WatchlistStocks' ).deleteOne( { _id: new Realm.BSON.ObjectId( oId ) } )
        await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserWatchlists' ).updateOne( 
            { _id: new Realm.BSON.ObjectId( primaryWatchListId._id ) },
            { $inc: { stocks: -1 } }
        )

        loadUserPrimaryWatchlist()
        loadUserWatchlists()
    }

    const removeFromWatchlist = async( watchlistStockId, watchlistId ) => {
        let res = await DeleteStockFromWatchlist( watchlistStockId, watchlistId )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        console.log( watchlistDetails )
        console.log( watchlistId )

        if( watchlistDetails.id === watchlistId ) {
            loadWatchlistStocks( watchlistId )
        }

        // loadUserWatchlists()
        // loadUserPrimaryWatchlist()

        return res
    }

    const addToWatchlist = async ( stockSymbol, watchlistId ) => {
        let res = await AddStockToWatchlist( stockSymbol, watchlistId )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }


        loadUserPrimaryWatchlist()
        loadUserWatchlists()
        loadWatchlistStocks( watchlistId )

        return res
    }

    const saveArticle = async ( article ) => {
        try { 
            await app.currentUser.mongoClient('mongodb-atlas').db( 'StockTracker' ).collection( 'UserArticles' ).insertOne( { 
                ...article,
                userId: app.currentUser.customData.userId,
                added: moment().format( 'YYYY-MM-DD HH:mm:SS' ),
                articleKey: article.url
            })
            getUserArticles()
        } catch ( e ) {
            console.log( e.toString())
        }
    }

    const getUserArticles = async () => {
        let userArticlesArray = await GetUserArticles()

        if( userArticlesArray.status !== 200 ) {
            //handle error
        }

        setUserArticles( userArticlesArray.body )
    }

    const addToUserCalendar = async( event, type ) => {
        let obj = { ...event }
        obj.eventType = type
        obj.show = true

        if ( type == 'dividend' ) {
            obj.date = event.paymentDate
        }

        delete obj.eps
        delete obj.epsEstimated
        delete obj.revenue
        delete obj.revenueEstimated

        await AddEventToUserCalendar( obj )
        getUserCalendarEvents()
    }

    const getUserCalendarEvents = async () => {
        
        let currentUser = await GetUserData()

        if( currentUser.userId === '620c47942989689076b82826' ) {
            setUserCalendarEvents( [] )
            return
        }

        let events = await GetUserCalendarEvents()
        setUserCalendarEvents( events )
    }

    const updateWatchlistDetails = async ( watchlistId, name ) => {
        let res = await UpdateWatchlistDetails( watchlistId, name )

        if ( res.status !== 200 ) {
            return { error: res.error } 
        }

        loadUserWatchlists()

        return res
    }

    return (
        <UserContext.Provider // this is where we expose state values and functions to the rest of the application
            value={{
                state: { // for organization reasons I like keep all state values inside a state object
                    user,
                    allUsersWatchlists,
                    allUserWatchlistStocks,
                    userArticles,
                    userPrimaryWatchlist,
                    watchlistDetails,
                    primaryWatchListId,
                    userCalendarEvents,
                    userWatchlistsByStockPresence,
                    primaryWatchlistDetails,
                    homepageWatchlistDetails,
                    recentlyViewed
                },
                updateWatchlistDetails,
                addToPrimaryWatchlist: ( str ) => addToPrimaryWatchlist( str ),
                savePosition: ( str, bool ) => savePosition( str, bool ),
                deletePosition: ( str, char ) => deletePosition( str, char ),
                loadUserWatchlists,
                saveArticle: ( obj ) => saveArticle( obj ),
                getUserArticles: () => getUserArticles(),
                loadUserPrimaryWatchlist,
                removeFromPrimaryWatchlist,
                loadUserWatchlistStocks,
                loadWatchlistStocks,
                removeFromWatchlist,
                addToWatchlist,
                addNewWatchlist,
                editWatchlist,
                deleteFromMyArticles,
                deleteUserWatchlist,
                addToUserCalendar,
                getUserCalendarEvents,
                getUserWatchlistsAndDetermineIfSelectedStockInWatchlist,
                removeFromUserCalendar,
                refreshApp,
                setHomepageWatchlistDetails,
                setPrimaryWatchlist,
                setRecentlyViewed,
                getRecentlyViewedArticles,
                addArticleToRecentlyView,
                setWatchlistDetails
            }}
        >
            { props.children }
        </UserContext.Provider>
    )
}

export default UserProvider;
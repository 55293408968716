import React, { useState} from 'react'
import $ from 'jquery'
import { toast } from 'react-toastify'

export default function SignIn( props ) {
    
    const [ errors, setErrors ] = useState( false )
    const { createUserAccount, logInGoogleUser, signUpGoogleUser } = props.globalContext

    const createAccountHandler = async () => {
        let firstName = document.getElementById( 'signUpFirstName' ).value
        let lastName = document.getElementById( 'signUpLastName' ).value
        let email = document.getElementById( 'signupEmail' ).value
        let password = document.getElementById( 'signupPassword' ).value
        let passwordConfirmation = document.getElementById( 'signupPasswordConfirmation' ).value

        if( errors ) setErrors( false )

        if( !email || email === '' || !password || password === '' || !passwordConfirmation || passwordConfirmation === '' || !firstName || firstName === '' || !lastName || lastName === '' ) {
            setErrors( 'Please enter all fields' )
            return
        }

        if( password !== passwordConfirmation ) {
            setErrors( 'Entered passwords do not match' )
            return
        }
        
        let res = await createUserAccount( email, password, firstName, lastName )

        if( res && res.error ) {
            setErrors( res.error )
            return
        }
        
        //If signup was successfull, dismiss the modal, and show a welcome message
        $( '#signUpModal' ).modal('toggle')

        document.getElementById( 'signUpFirstName' ).value = ''
        document.getElementById( 'signUpLastName' ).value = ''
        document.getElementById( 'signupEmail' ).value = ''
        document.getElementById( 'signupPassword' ).value = ''
        document.getElementById( 'signupPasswordConfirmation' ).value = ''

        toast( "You're signed up!  Enjoy the app!" )
    }

    const googleSignUpHandler = async() => {
        let res = await signUpGoogleUser()

        if( !res || res.error ) {
            toast( res.error || "There was an error signing you up." )
            return
        }

        if ( res.newSignup ) {
            $( '#signUpModal' ).modal('toggle')
            toast( 'Welcome!' )
            return
        } else if( !res.newSignup ) {
            $( '#signUpModal' ).modal('toggle')
            toast( 'You are now logged in!' )
            return
        }
        
    }

    const handleModalClose = () => {
        document.getElementById( 'signUpFirstName' ).value = ''
        document.getElementById( 'signUpLastName' ).value = ''
        document.getElementById( 'signupEmail' ).value = ''
        document.getElementById( 'signupPassword' ).value = ''
        document.getElementById( 'signupPasswordConfirmation' ).value = ''
        $( '#signUpModal' ).modal( 'toggle' )
    }

    // 818DA5
    return (
        <div class="modal fade" data-backdrop="static" id="signUpModal" aria-hidden="true" aria-labelledby="signUpModalLabel" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body" style={{backgroundColor:"#162033",borderRadius:10}}>
                        <div>
                            <span className='text-center' style={{color:'#20A5A1',fontSize:'1.5rem'}}>
                                Sign Up
                            </span>  
                        </div>
                        <div className='d-flex mt-4'>
                            <button className='btn btn-primary' onClick={ googleSignUpHandler } id="google-auth">Sign Up With Google</button>
                        </div>
                        <div className='d-flex'>
                            <div className="form-group mt-2 me-1" style={{color:"white",paddingTop:'1rem'}}>
                                <label for="exampleInputEmail1">First Name</label>
                                <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="email" className="form-control" id="signUpFirstName" aria-describedby="emailHelp"></input>
                            </div>
                            <div className="form-group mt-2 ms-1" style={{color:"white",paddingTop:'1rem'}}>
                                <label for="exampleInputEmail1">Last Name</label>
                                <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="email" className="form-control" id="signUpLastName" aria-describedby="emailHelp"></input>
                            </div>
                        </div>
                        <div className="form-group mt-2" style={{color:"white",paddingTop:'1rem'}}>
                            <label for="exampleInputEmail1">Email address</label>
                            <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="email" className="form-control" id="signupEmail" aria-describedby="emailHelp"></input>
                        </div>
                        <div className="form-group mt-2" style={{color:"white"}}>
                            <label for="exampleInputPassword1">Password</label>
                            <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="password" className="form-control" id="signupPassword"></input>
                        </div>
                        <div className="form-group mt-2" style={{color:"white"}}>
                            <label for="exampleInputPassword1">Re-enter password</label>
                            <input style={{backgroundColor:"#7D8AAA",border:'none'}} required type="password" className="form-control" id="signupPasswordConfirmation"></input>
                        </div>
                        <div className='d-flex justify-content-between mt-4'>
                            <button type="button" class="btn btn-jfa-cancel" onClick={handleModalClose}>Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={ () => createAccountHandler() } > Sign Up </button>
                        </div>
                        { errors && <span style={{color:'red'}}>{ errors } </span> }
                    </div>
                </div>
            </div>
        </div>
    )
}
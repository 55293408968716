import React, { useState } from 'react'
import GlobalStateContext from './GlobalStateContext'
import * as Realm from "realm-web"
import _, { add } from 'lodash'
import moment from 'moment'
import { GetLoginStatus, GetUserData, LoginDefaultUser, LoginUser, LogOutUser, GetUserIndicies, Search, CreateUserAccount, CreateDefaultWatchlist, CreateDefaultIndices, LoginGoogleUser, LoginAppleUser, LoginFacebookUser, RemoveUserIndex, AddUserIndex, GetUserWatchlists, GetUserPortfolios, GetPortfolioStocksByPortfolioId, GetStockDetails, DeleteAccount, GetStockPeers, CalculateWatchlistReturn, GetWatchlistExposure, GetHistoricalSectorPerformance, GetMostActiveStocks, GetHighestGainers, GetHighestLosers, GetSocialSentiment, GetEconomicCalendarEvents, GetEconomicIndicatorDetails, GetSectorDetails, GetIndustryDetails, GetPerformanceForAllSectors, GetEarningsAndFinancialsRatios, GetHistoricalDividends, GetHistoricalStockPrices, GetStockQuote, GetIndexConstituents, GetIndexPrices, GetDividendHistory, RunStockScreener, GetUpcomingDividends, GetUpcomingEarnings, GetFmpBalanceSheet, GetFmpIncomeStatements, GetFmpCashFlow, GetKeyRatios, GetMarketNews, GetCommodityPrices, GetCryptoPrices, GetMarketRiskPremiums, GetCurrencyRates, GetMarketStatus, SignUpGoogleUser, EmailAndAccountAlreadyExists, DeleteAppUser, AddUserDataRecordUponSignUp, GetSymbolType, GetStockOverview } from '../../utils/realmFunctions'
import { earningsAndFinancials } from '../../utils/dataKeyMapper'

const app = new Realm.App({ id: process.env.REACT_APP_REALM_ID })

const GlobalStateProvider = props => {
    const [ loggedIn, setLoggedIn ] = useState( undefined )
    const [ user, setUser ] = useState( false )
    const [ isDefaultUser, setIsDefaultUser ] = useState( false )
    const [ error, setError ] = useState( false )
    const [ isSearchOpen, setIsSearchOpen ] = useState( false )
    const [ searchHeldOpenForModal, setSearchHeldOpenForModal ] = useState( false )
    const [ watchlistSectorGraph, setWatchlistSectorGraph ] = useState( false )
    const [ watchlistCountryGraph, setWatchlistCountryGraph ] = useState( false )
    const [ selectedWatchlist, setSelectedWatchlist ] = useState( false )
    const [ highestGainers, setHighestGainers ] = useState( false )
    const [ highestLosers, setHighestLosers ] = useState( false )
    const [ socialSentiment, setSocialSentiment ] = useState( false )

    const [ searchSymbol, setSymbolSearch ] = useState( '' )
    const [ stockDetails, setStockDetails ] = useState( false )
    const [ selectedStock, setSelectedStock ] = useState( false )
    const [ incomeStatement, setIncomeStatement ] = useState( false )
    const [ balanceSheet, setBalanceSheet ] = useState( false )
    const [ cashFlow, setCashFlow ] = useState( false )
    const [ keyFinancialRatios, setKeyFinancialRatios ] = useState( false )
    const [ stockMetrics, setStockMetrics ] = useState( false )
    const [ stockScreenerOptions, setStockScreenerOptions ] = useState( {  } )
    const [ stockScreenerResults, setStockScreenerResults ] = useState( false )
    const [ stockPriceTimeline, setStockPriceTimeline ] = useState( false )
    const [ upcomingEarnings, setUpcomingEarnings ] = useState( false )
    const [ graphData, setGraphData ] = useState( false )
    const [ dividendHistory, setDividendHistory ] = useState( false ) 
    const [ indexes, setIndexes ] = useState( false )
    const [ homepageIndexes, setHomepageIndexes ] = useState( [] )
    const [ indexDetails, setIndexDetails ] = useState( false )
    const [ stockReturn, setStockReturn ] = useState( false )
    const [ allSectorPerformance, setAllSectorPerformance ] = useState( false )
    const [ allIndustryPriceToEarnings, setAllIndustryPriceToEarnings ] = useState( false )
    const [ allSectorPriceToEarnings, setAllSectorPriceToEarnings ] = useState( false )
    const [ sectorDetails, setSectorDetails ] = useState( false )
    const [ treasuries, setTreasuries ] = useState( false )
    const [ economicIndicators, setEconomicIndicators ] = useState( false )
    const [ allIndustryPerformance, setAllIndustryPerformance ] = useState( false )
    const [ economicIndicatorDetails, setEconomicIndicatorDetails ] = useState( false )
    const [ etfDetails, setEtfDetails ] = useState( false )
    const [ allCommodityPrices, setAllCommodityPrices ] = useState( false )
    const [ allCurrencyPrices, setAllCurrencyPrices ] = useState( false )
    const [ allCryptoPrices, setAllCryptoPrices ] = useState( false )
    const [ commodityDetails, setCommodityDetails ] = useState( false )
    const [ currencyDetails, setCurrencyDetails ] = useState( false )
    const [ cryptocurrencyDetails, setCryptocurrencyDetails ] = useState( false )
    const [ allMarketRiskPremiums, setAllMarketRiskPremiums ] = useState( false )
    const [ marketStatus, setMarketStatus ] = useState( false )
    const [ industryDetails, setIndustryDetails ] = useState( false )
    const [ earningsHistory, setEarningsHistory ] = useState( false )
    const [ economicCalendar, setEconomicCalendar ] = useState( false )
    const [ dividendCalendar, setDividendCalendar ] = useState( false )
    const [ homepageWatchlistValue, setHomepageWatchlistValue ] = useState( false )
    const [ symbolsForMarketNews, setSymbolsForMarketNews ] = useState( false )
    const [ mostActive, setMostActive ] = useState( false )
    const [ earningsAndFinancialsRatios, setEarningsAndFinancialsRatios ] = useState( false )
    const [ screenerPreFilterResultsLength, setScreenerPreFilterResultsLength ] = useState( false )
    const [ screenerResultsLength, setScreenerResultsLength ] = useState( false )
    const [ symbolType, setSymbolType ] = useState( false )

    const getSymbolType = async( symbol ) => {
        try {
            let res  = await GetSymbolType( symbol )

            if( res.status !== 200 ) {
                return { error: res.error }
            }

            setSymbolType( { symbol: symbol, type: res.body } )

        } catch (error) {
            return { error: error.toString() }
        }
    }

    const getEconomicCalendarEvents = async( start, end ) => {
        let res = await GetEconomicCalendarEvents( start, end )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }

        setEconomicCalendar( res.body )
    }

    const getUserData = async() => {
        let data = await GetUserData( true )
        setUser( data )
    }

    const checkAuthenticationStatus = async() => {
        try {
            let userAuthStatus = await GetLoginStatus()
            
            if( userAuthStatus ) {
                let userData = await GetUserData()

                //If this is the default user, update isDefaultUser state value
                if( userData && userData.userId == process.env.REACT_APP_DEFAULT_USER ){
                    setIsDefaultUser( true )
                }
            }

            setLoggedIn( userAuthStatus )
        } catch ( e ) {
            setError( e.toString() )
            alert( `Error in the check autentication status: ${ e.toString() }` )
        }
    }

    const loginAsDefaultUser = async() => {
        try {
            await LoginDefaultUser()

            let userData = await GetUserData()

            let loggedInStatus = await checkAuthenticationStatus()

            setLoggedIn( loggedInStatus )
            setUser( userData )
            setIsDefaultUser( true )

        } catch ( e ) {
            setError( e.toString() )
            alert( `Error in the check autentication status: ${ e.toString() }` )
        }
    }

    const openConnectionDatabase = async() => {
        if( !app.currentUser ) {
            await app.logIn( Realm.Credentials.function() )
        }
        setUser( { ...app.currentUser.customData } )
    }

    const createUserAccount = async ( email, password, firstName, lastName ) => {
        try {
            //make sure the email is all undercase
            email = email.toLowerCase()

            //check if an accounting already exists with this email address
            let emailInUse = await EmailAndAccountAlreadyExists( email )

            if( emailInUse.status !== 200 ) {
                return { error: emailInUse.error }
            }

            //If the email already exists, let the user know
            if( emailInUse.userExists ) {
                return { error: "Email address already in use." }
            }
            
            //Otherwise let's create the account
            let createAction = await CreateUserAccount( email, password )

            if( createAction.status !== 200 ) {
                return { error: createAction.error }
            }

            //log out the default user and log in our new user
            if( app.currentUser ) {
                await app.removeUser( app.currentUser )
            }

            let user = await LoginUser( email, password )

            if( user.status !== 200 ) {
                return { error: user.error }
            }

            //Create their full name
            let name = firstName + ' ' + lastName
            let userId = user.userId

            //Their account has been created, now create their default watchlist/indicies/etc..
            let addUserDataRecord = await AddUserDataRecordUponSignUp( userId, name, firstName, 'local-userpass', email )

            if( addUserDataRecord.status !== 200 ) {
                return { error: addUserDataRecord.error }
            }

            let userData = await GetUserData( true )
            
            setLoggedIn( true )
            setUser( userData ) 
            setIsDefaultUser( false )

            return true

        } catch ( e ) {
            return { error: e.toString() }
        }
    }

    const logOut = async () => {
        try {
            let res = await LogOutUser()

            setLoggedIn( true )
            setUser( { ...res } )
            setIsDefaultUser( true )

        } catch ( e ) {
            console.log( e )
        }
    }

    const logInUser = async ( email, password ) => {
        try {

            if( app.currentUser && app.currentUser.isLoggedIn ) {
                await app.currentUser.logOut()
            }

            //ensure the email is all lower case
            email = email.toLowerCase()

            let result = await LoginUser( email, password )
            
            if( result.status !== 200 ) {
                let errorMessage = result.error
                if( errorMessage.indexOf( 'invalid username/password' ) > -1 ) {
                    errorMessage = 'Invalid username and password combination.'
                }

                return { error: errorMessage }
            }

            setLoggedIn( true )
            setIsDefaultUser( false )
            setUser( result.body )

            // return result

        } catch ( e ) {
            throw e.toString()
        }
    }

    const logInGoogleUser = async () => {
        try {
            //If a user is logged in, log them out
            if( app.currentUser && app.currentUser.isLoggedIn ) {
                //await app.removeUser( app.currentUser )
            }
    
            let loginResult = await LoginGoogleUser( setLoggedIn, setUser, setIsDefaultUser )

            console.log( loginResult )

            if ( loginResult.error ) {
                return loginResult
            }

            //Get the user's email address from the response
            let email = loginResult?.profile?.email

            if( !email ) {
                return { error: "An error occurred logging you in." }
            }

            //make sure email is all lower case
            email = email.toLowerCase()

            //Check if this username is already is in use
            let emailInUse = await EmailAndAccountAlreadyExists( email )

            if( emailInUse.status !== 200 ) {
                return { error: emailInUse.error }
            }

            //If the email address is not in use, MDB will automatically create the user account.  We need to trigger the creation of their default watchlist/indicies/etc..
            if( !emailInUse.userExists ) {
                let userId = loginResult.id
                let name = loginResult.profile.name
                let firstName = loginResult.profile.firstName
                let authProvider = loginResult.providerType

                let addUserDataRecord = await AddUserDataRecordUponSignUp( userId, name, firstName, authProvider, email )

                if( addUserDataRecord.status !== 200 ) {
                    return { error: addUserDataRecord.error }
                }
            }

            //If the email is in use and the auth provider is not google, return an error and delete the automatically created user account
            if( emailInUse.userExists && emailInUse.authProvider !== 'oauth2-google') {
                await LogOutUser()
                await DeleteAppUser( loginResult.id )
                return { error: "Email address already in use.  Please try logging in with username and password." }
            }

            //If the email is in use and the auth provider is google, complete the login process.  This will also apply when the email address was not in use
            let userData = await GetUserData( true )
            
            setLoggedIn( true )
            setUser( userData ) 
            setIsDefaultUser( false )

            return { status: 200 }
        } catch (error) {
            return { error: error.toString() }
        }
    }

    const signUpGoogleUser = async() => {
        try {
            var newSignup = true

            if( app.currentUser && app.currentUser.isLoggedIn ) {
                await app.removeUser( app.currentUser )
            }

            let res =  await LoginGoogleUser( setLoggedIn, setUser, setIsDefaultUser )

            if ( res.error ) {
                return res
            }

            //Get the user's email address from the response
            let email = res.profile?.email

            if( !email ) {
                return { error: "An error occurred logging you in." }
            }

            //make sure email is all lower case
            email = email.toLowerCase()

            //Check if this username is already is in use
            let emailInUse = await EmailAndAccountAlreadyExists( email )

            if( emailInUse.status !== 200 ) {
                return { error: emailInUse.error }
            }

            //If the email address is not in use, MDB will automatically create the user account.  We need to trigger the creation of their default watchlist/indicies/etc..
            if( !emailInUse.userExists ) {
                let userId = res.id
                let name = res.profile.name
                let firstName = res.profile.firstName
                let authProvider = res.providerType

                let addUserDataRecord = await AddUserDataRecordUponSignUp( userId, name, firstName, authProvider, email )

                if( addUserDataRecord.status !== 200 ) {
                    return { error: addUserDataRecord.error }
                }
            }

            //If the email is in use and the auth provider is not google, return an error and delete the automatically created user account
            if( emailInUse.userExists && emailInUse.authProvider !== 'oauth2-google') {
                await LogOutUser()
                await DeleteAppUser( res.id )
                return { error: "Email address already in use.  Please try logging in with username and password." }
            }

            //If the email is in use and the authProvide is google, set newSignup flag to false
            if( emailInUse.userExists && emailInUse.authProvider === 'oauth2-google') {
                newSignup = false
            }

            //If the email is in use and the auth provider is google, complete the login process.  This will also apply when the email address was not in use
            let userData = await GetUserData( true )
            
            setLoggedIn( true )
            setUser( userData ) 
            setIsDefaultUser( false )

            return { status: 200, newSignup: newSignup }
        } catch (error) {
            return { error: error.toString() }
        }
    }

    const logInAppleUser = async() => {
        if( await LoginAppleUser() === true ) {
            let result = await GetUserData( true )
            setLoggedIn( true )
            setUser( result )
        } 
    }

    const loginFacebookUser = async() => {
        if( await LoginFacebookUser() === true ) {
            let result = await GetUserData( true )
            setLoggedIn( true )
            setUser( result )
        } 
    }

    const updateSearchSymbol = ( value ) => setSymbolSearch( value ? value.toUpperCase() : value )

    const getEconomicIndicatorDetails = async ( indicator ) => {
        let res = await GetEconomicIndicatorDetails( indicator )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }
        
        setEconomicIndicatorDetails( res.body )
    }


    const getEconomicIndicatorData = async () => {
        setEconomicIndicators( [] )
    }

    const getTreasuriesData = async ( startDate, endDate ) => {
        setTreasuries( [] )
    }

    const getSectorDetails = async ( sector ) => {
        let res = await GetSectorDetails( sector )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }

        setSectorDetails( res.body )
    }

    const getIndustryDetails = async( industry ) => {
        let res = await GetIndustryDetails( industry )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }

        setIndustryDetails( res.body )
    }

    const getAllIndustryPerformance = async() => {
        setAllIndustryPerformance( [] )
    }

    const getAllIndustryPriceToEarnings = async () => {
        setAllIndustryPriceToEarnings( [] )
    }

    const getAllSectorPriceToEarnings = async () => {
        setAllSectorPriceToEarnings( [] )
    }

    const getAllSectorPerformance = async ( today ) => {

        let res = await GetPerformanceForAllSectors( today, marketStatus.isTheStockMarketOpen )

        if( res !== 200 ) {
            //handle error
        }

        setAllSectorPerformance( res.body )
    }

    const getHistoricalSectorPerformance = async () => {
        let res = await GetHistoricalSectorPerformance()

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        //Get our unique sectors
        let uniqueSectors = _.uniqBy( res.body, 'sector' ).map( a => a.sector )

        //create the chart data
        //Generate our labels
        let dataPoints = _.uniqBy( res.body, 'date' ).map( a => a.date )

        // //Generate dataset for each sector
        let dataSets = []

        for( let sector of uniqueSectors ) {
            let dataSet = { label: sector, data: [], borderColor: '#24A556', fill: false, backgroundColor: "RGB(240, 12R8, 128)" }
            let startingBalance = 100

            //get the sectors records
            let sectorRecords = res.body.filter( a => a.sector === sector )
            
            //for each sector record, calculate the NAV
            for( let sectorRecord of sectorRecords ) {
                startingBalance = startingBalance * ( 1 + ( parseFloat( sectorRecord.changesPercentage ) / 100 ) )
                dataSet.data.push( startingBalance )
            }
            //dataSet.backgroundColor = "RGB(240, 128, 128)"
            dataSets.push( dataSet )
        }

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: dataSets
            },
            options: {
                elements: {
                    point: {
                        radius: 1
                    }
                },
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const getUserIndexes = async ( userId ) => {
        const userIndexesData = await GetUserIndicies()
        return userIndexesData
    }

    const removeUserIndex = async ( userIndexId ) => {
        let result = await RemoveUserIndex( userIndexId )
        await getMajorIndexes()
    }

    const addUserIndex = async ( indexName ) => {
        let result = await AddUserIndex( indexName )
        await getMajorIndexes()
    }

    const calculateReturn = async ( stockSymbol, startingAmount, startingDate, endingDate ) => {
        //Goal is to calculate total return over given amount of time.  Return is inclusive of dividends, but DRIP is not assumed.

        if( !startingDate ) startingDate = moment().add( -2, 'years' ).format( 'YYYY-MM-DD' )
        if( !startingAmount || startingAmount < 0 ) startingAmount = '1000'
        if( !endingDate ) endingDate = moment().add( -3, 'day').format( 'YYYY-MM-DD' )

        startingAmount = Number( startingAmount.replace( '$', '' ).replace( /,/gi, '' ) )

        //Get stocks prices for the date range
        let stockPrices = await loadHistoricalStockPrices( stockSymbol, startingDate, endingDate )
        let taxLots = []

        //Get starting price and ending price
        let endingPrice = stockPrices[ Object.keys( stockPrices )[0] ]
        let startingPrice = stockPrices[ Object.keys( stockPrices )[ Object.keys( stockPrices ).length - 1 ] ]

        const startingUnits = startingAmount / startingPrice

        taxLots.push( { lotType: 'principle', datePurchased: startingDate, quantity: startingUnits, price: startingPrice, costBasis: startingPrice * startingUnits } )

        //calculate the total return
        taxLots[ 0 ].totalReturn = ( startingUnits * endingPrice ) - startingAmount

        //Get dividend history
        let dividendHistory = await loadHistoricalStockDividends( stockSymbol, startingDate, endingDate )

        //Filter out any dividends with payment dates greater than the endingDate
        //dividendHistory = dividendHistory.filter( a => moment( a.paymentDate, 'YYYY-MM-DD' ).isSameOrBefore( moment( endingDate, 'YYYY-MM-DD' ) ) )

        for( let dividend of dividendHistory ) {
            //Calculate total dividend payout
            let dividendPayout = dividend.adjDividend * sumByObjectKey( taxLots, 'quantity' )

            //Determine the transaction date
            let paymentDate = ''
            if( !dividend.paymentDate || dividend.paymentDate === '' ) {
                paymentDate = dividend.date
            } else {
                paymentDate = dividend.paymentDate
            }

            //If payment date is greater than end date
            if ( moment( paymentDate, 'YYYY-MM-DD' ).isAfter( moment( endingDate, 'YYYY-MM-DD' ) ) ) {
                continue
            }
            
            //how many shares did we buy on that day?
            let sharesPurchased = dividendPayout / getStockPriceByDay( stockPrices, paymentDate )
            
            //Create tax lot and calculate return
            taxLots.push( { 
                lotType: 'drip',
                datePurchased: paymentDate, 
                quantity: sharesPurchased,
                price: stockPrices[ paymentDate ], 
                totalReturn: ( sharesPurchased * endingPrice ) - dividendPayout, 
                costBasis: dividendPayout 
            } )
        }

        let returnCalc = ( Number( sumByObjectKey( taxLots, 'totalReturn') ) + Number( sumByObjectKey( taxLots, 'costBasis') ) ).toFixed( 2 )
        setStockReturn( returnCalc )
    }

    const getStockPriceByDay = ( priceList, date, modifier ) => {
        let price = false
        let i = 0
        if( !modifier ) modifier = 1
        do {
            if( !priceList[ date ] ) date = moment( date, 'YYYY-MM-DD' ).add( modifier, 'd' ).format( 'YYYY-MM-DD')
            else price = priceList[ date ]
            i++
        } while (
            i < 10 && price === false
        )

        return price
    }

    const sumByObjectKey = ( list, key ) => {
        let total = 0
        list.forEach( amt => {
            total += amt[ key ]
        })

        return total.toFixed( 2 )
    }

    const loadHistoricalStockDividends = async ( stockSymbol, startDate, endDate ) => {
        let stockDividends = await GetHistoricalDividends( stockSymbol, startDate, endDate )

        if( stockDividends.status !== 200 ) {
            return { error: stockDividends.error }
        }

        return stockDividends.body.reverse()
    }

    const loadHistoricalStockPrices = async( stockSymbol, startingDate, endingDate ) => {

        let res = await GetHistoricalStockPrices( false, stockSymbol, startingDate, endingDate )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        let stockPricesObject = {}

        //convert to object
        res.body.forEach( date => {
            stockPricesObject[ date.date ] = date.close
        })
  
        return stockPricesObject

    }

    const getIndexDetails = async ( indexSymbol, userId ) => {
        let res = await GetStockQuote( encodeURI( indexSymbol ) )

        let constituents = await GetIndexConstituents( indexSymbol )

        if( constituents.status !== 200 ) {
            return { error: constituents.error }
        }

        res[ 0 ].members = constituents.body
        
        //Get historical price data
        let priceData = GetHistoricalStockPrices( -1825, indexSymbol )
        
        if( priceData.status !== 200 ) {
            return { error: priceData.error }
        }

        priceData = priceData.body
        priceData = priceData.historical.slice( 0, 1000 ).sort( ( a , b ) => a.date > b.date )

        let priceChart = createPriceChartGraph( priceData )

        let userIndexes = await getUserIndexes()
        let userIndexesSymbols = userIndexes.map( a => a.index )

        res.forEach( ( indexSymbol, index ) => {
            if( userIndexesSymbols.includes( indexSymbol.symbol ) ) {
                res[ index ].userIndex = true
                res[ index ].userIndexId = userIndexes.find( a => a.index == indexSymbol.symbol )._id.toString()
            }
        })

        res[ 0 ].historicalPrices = priceData
        res[ 0 ].priceChart = priceChart
        setIndexDetails( res[0] )
    }

    const tickerSearch = async ( ticker ) => {
        try {
            if( !ticker || !ticker.value || ticker.value === '' ) {
                alert( 'Please enter a ticker')
                return
            }
            let res = []

            return res

        } catch ( e ) {
            return { error: e.toString() }
        }
    }

    const getMajorIndexes = async () => {

        let res = await GetIndexPrices( false, true )

        if( res.status != 200 ) {
            return { error: res.error }
        }

        setHomepageIndexes( res.body )
        return res.body
    }

    const savePosition = async ( stock, index ) => {
        let tempUser = { ...user }
        let myStocks = [ ...user.myStocks ]

        //get our values
        let price = document.getElementById( `${ stock }Price` )
        let quantity = document.getElementById( `${ stock }Quantity` )
        let date = document.getElementById( `${ stock }Date` )

        if ( !price || !quantity || !date || !price.value || !quantity.value || !date.value ) return

        myStocks[ index  ].positions.push( { purchasePrice: Number( price.value ), purchaseQuantity: Number( quantity.value ), purchaseDate: moment( date.value, 'MM/DD/YYYY' ).format() } )

        //setUser( await userData.findOneAndReplace( { userName: 'an691894' }, { ...tempUser, myStocks: myStocks }, { returnNewDocument: true } ) )

        price.value = ''
        quantity.value = ''
        date.value = ''
    }

    const deletePosition = async ( symbolIndex, positionIndex ) => {
        let tempUser = { ...user }
        let myStocks = [ ...user.myStocks ]
        myStocks[ symbolIndex ].positions.splice( positionIndex, 1 )
        //setUser( await userData.findOneAndReplace( { userName: 'an691894' }, { ...tempUser, myStocks: myStocks }, { returnNewDocument: true } ) )
    }

    const getDividendHistory = async ( limitToUserWatchlistAndPortfolio, daysPast ) => {

        if( !daysPast ) {
            daysPast = -180
        }

        let start = moment().add( daysPast, 'days' ).format( 'YYYY-MM-DD' )
        let end = moment().format( 'YYYY-MM-DD' )

        //Load dividend history for all stocks
        let res = await GetDividendHistory( false, start, end, true )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setDividendHistory( res.body.slice( 0, 100 ) )
    }

    const getDividendCalendar = async( limitToUserWatchlistAndPortfolio ) => {
        let start = moment().format( 'YYYY-MM-DD' )
        let end = moment().add( 180, 'days' ).format( 'YYYY-MM-DD' )

        //Load dividend history for all stocks
        let res = await GetUpcomingDividends( start, end, limitToUserWatchlistAndPortfolio )
        
        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setDividendCalendar( res.body.slice( 0, 100 ) )
    }
    
    const getUpcomingEarnings = async () => {
        let res = await GetUpcomingEarnings( false, false, false )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setUpcomingEarnings( res.body )
    }

    const getEarningsHistory = async() => {
        let start = moment().add( -30, 'd' ).format( 'YYYY-MM-DD' )
        let end = moment().add( 365, 'd' ).format( 'YYYY-MM-DD' )

        let res = await GetUpcomingEarnings( start, end, false )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setEarningsHistory( res.body )
    }

    const executeSearch = async ( searchTerm ) => {

        if( !searchTerm || searchTerm === '' ) {
            alert( 'Please enter a term to search' ) 
            return
        }

        let results = await Search( searchTerm )

        return results.body
    }

    const getIncomeStatement = async ( quarterly, symbol ) => {
        try {
            let period = quarterly ? 'quarterly' : 'annual'

            let res = await GetFmpIncomeStatements( symbol, period )
            
            if( res.status === 200 ) {
                return { error: res.error }
            }

            res.body.forEach( year => {
                delete year.link
                delete year.finalLink
            } )

            setIncomeStatement( res.body )
        } catch ( e ) {
            return { error: e.toString() }
        }
    }

    const getBalanceSheet = async ( quarterly, symbol ) => {
        try {
            let period = quarterly ? 'quarter' : 'annual'

            let res = await GetFmpBalanceSheet( symbol, period )
            
            if( res.status === 200 ) {
                return { error: res.error }
            }

            res.body.forEach( year => {
                delete year.link
                delete year.finalLink
            } )

            setBalanceSheet( res.body )
        } catch ( e ) {
            console.log( e.toString() )
        }
    }

    const getCashFlow = async ( quarterly, symbol ) => {
        try {
            let period = quarterly ? 'quarter' : 'annual'

            let res = await GetFmpCashFlow( symbol, period )

            if( res.status === 200 ) {
                return { error: res.error }
            }

            res.body.forEach( year => {
                delete year.link
                delete year.finalLink
            } )

            setCashFlow( res.body )
        } catch ( e ) {
            console.log( e.toString() )
        }
    }

    const getKeyFinancialRatios = async ( quarterly, symbol ) => {
        try {
            let period = quarterly ? 'quarter' : 'annual'

            let res = await GetKeyRatios( symbol, period )

            if( res.status !== 200 ) {
                return { error: res.error }
            }

            res.body.forEach( year => {
                delete year.link
                delete year.finalLink
            } )

            setKeyFinancialRatios( res.body )
        } catch ( e ) {
            return { error: e.toString() }
        }
    }

    const getStockMetrics = async ( quarterly, symbol ) => {
        try {
            let period = quarterly ? 'quarter' : 'annual'

            let res = await GetEarningsAndFinancialsRatios( -720, symbol, period )
            
            if( res.status !== 200 ) {
                return { error: res.error }
            }

            res.body.forEach( year => {
                delete year.link
                delete year.finalLink
            } )

            setStockMetrics( res.body )
        } catch ( e ) {
            return { error: e.toString() }
        }
    }

    const getStockScreenerResults = async( resultsLengthOnly ) => {
        try {
            let query = ''
            let obj = { ...stockScreenerOptions }

            //Add the query parameters for price filter
            if( stockScreenerOptions.price && !stockScreenerOptions.priceRange ) {
                obj.priceRange = 'priceMoreThan'
                query += `priceMoreThan=${ stockScreenerOptions.price }&`
            } else if( stockScreenerOptions.price ) {
                query += `${ stockScreenerOptions.priceRange }=${ stockScreenerOptions.price }&`
            }

            //Add the query parameters for dividend filter
            if( stockScreenerOptions.dividend && !stockScreenerOptions.dividendRange ) {
                obj.dividendRange = 'dividendMoreThan'
                query += `dividendMoreThan=${ stockScreenerOptions.dividend }&`
            } else if( stockScreenerOptions.dividend ) {
                query += `${ stockScreenerOptions.dividendRange }=${ stockScreenerOptions.dividend }&`
            }

            //Add the query parameters for exchange
            if( stockScreenerOptions.exchange ) {
                query += `exchange=${ stockScreenerOptions.exchange }&`
            }

            //Add the query parameters for sector
            if( stockScreenerOptions.sector ) {
                query += `sector=${ stockScreenerOptions.sector }&`
            }

            //Add the query parameters for market cap
            if( stockScreenerOptions[ 'marketCap' ] && stockScreenerOptions[ 'marketCap' ].length > 0 ) {
                //combine the options
                let capRanges = {
                    small: { low: 0, high: 2000000000 },
                    mid: { low: 2000000001, high: 10000000000 },
                    large: { low: 10000000001, high: 200000000000 },
                    mega: { low: 200000000001, high: 10000000000000 },
                }

                let lowEnd = 0
                let highEnd = 0

                stockScreenerOptions[ 'marketCap' ].forEach( ( cap, index ) => {
                    if ( index === 0 ) {
                        lowEnd = capRanges[ cap ].low
                        highEnd = capRanges[ cap ].high
                        return
                    }

                    if( capRanges[ cap ].low < lowEnd ) {
                        lowEnd = capRanges[ cap ].low
                    }

                    if( capRanges[ cap ].high > highEnd ) {
                        highEnd = capRanges[ cap ].high
                    }
                })

                query += `marketCapLowerThan=${ highEnd }&`
                query += `marketCapMoreThan=${ lowEnd }&`
            }

            let res = await RunStockScreener( query, resultsLengthOnly )

            if( res.status !== 200 ) {
                return { error: res.error }
            }

            if( resultsLengthOnly ) {
                setScreenerPreFilterResultsLength( res.body )
                return
            }

            setStockScreenerResults( res.body.slice( 0, 150 ) )
            setScreenerResultsLength( res.body.length )

        } catch ( e ) {
            throw e
        }
    }

    const getStockPriceTimelineData = async( searchSymbol ) => {
        let res = await GetHistoricalStockPrices( -3650, searchSymbol, false, false ) 

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setStockPriceTimeline( res.body )
    }

    const getEtfDetails = async ( symbol ) => {
        let aggregatedEtfDetails = {}

        //Get information from stock price endpoint
        let etfPriceDetails = await fetch( `https://financialmodelingprep.com/api/v3/quote/${ symbol }?apikey=d9d145971ed7f03fdf986723390ab9d0` )
        etfPriceDetails = await etfPriceDetails.json()
        aggregatedEtfDetails.etfPriceDetails = etfPriceDetails

        //Get profile data


        //Get the ETF's sector weightings
        let etfSectorWeightings = await fetch( `https://financialmodelingprep.com/api/v3/etf-sector-weightings/${ symbol }?apikey=d9d145971ed7f03fdf986723390ab9d0` )
        etfSectorWeightings = await etfSectorWeightings.json()
        aggregatedEtfDetails.etfSectorWeightings = etfSectorWeightings

        //get historical stock data
        let historicalStockPrices = await fetch ( `https://financialmodelingprep.com/api/v3/historical-price-full/${ symbol }?serietype=line&from=${moment().add( -5, 'years').format( 'YYYY-MM-DD' ) }&apikey=d9d145971ed7f03fdf986723390ab9d0` )
        historicalStockPrices = await historicalStockPrices.json()
        aggregatedEtfDetails.historicalStockPrices = historicalStockPrices.historical.reverse()

        //prepare stock price graph data
        let priceChart = createPriceChartGraph( aggregatedEtfDetails.historicalStockPrices )
        aggregatedEtfDetails.priceChart = priceChart

        //get stock news
        let stockNews = await fetch( `https://financialmodelingprep.com/api/v3/stock_news?tickers=${ symbol }&limit=50&apikey=d9d145971ed7f03fdf986723390ab9d0` )
        stockNews = await stockNews.json()
        aggregatedEtfDetails.stockNews = stockNews

        //get the ETF's stock holdings
        let etfStockHoldings = await fetch( `https://financialmodelingprep.com/api/v3/etf-holder/${ symbol }?apikey=d9d145971ed7f03fdf986723390ab9d0` )
        etfStockHoldings = await etfStockHoldings.json()
        aggregatedEtfDetails.etfStockHoldings = etfStockHoldings

        //get the ETF's dividend history
        let etfDividendHistory = await fetch( `https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ symbol }?apikey=d9d145971ed7f03fdf986723390ab9d0` )
        etfDividendHistory = await etfDividendHistory.json()

        if( !etfDividendHistory || !etfDividendHistory.historical ) {
            aggregatedEtfDetails.etfDividendHistory = []
        } else {
            aggregatedEtfDetails.etfDividendHistory = etfDividendHistory.historical
        }

        setEtfDetails( aggregatedEtfDetails )
    }

    const createYieldCurveGraph = ( treasuryRateData ) => {
        //Generate our labels
        let labels = []

        Object.keys( treasuryRateData[ 0 ] ).forEach( term => {
            if( term === 'date') return
            labels.push( term )
        })

        // labels.reverse()

        //Generate datasets
        delete treasuryRateData[ 0 ].date

        let dataSet = { label: "Rates", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }

        dataSet.data = Object.keys( treasuryRateData[ 0 ] ).map( a => {
            return treasuryRateData[ 0 ][ a ]
        })

        //dataSet.data

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: labels,
                datasets: [ dataSet ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                }
            }
        }
        return graphData
    }

    const createRateChartGraph = ( treasuryRateData, term ) => {
        //Generate our labels
        let labels = []

        //treasuryRateData = treasuryRateData.reverse()

        treasuryRateData.forEach( ( a, index ) => {
            labels.push( a.date )
        })

        //Generate datasets
        let dataSet = { label: "Rate", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }

        dataSet.data = treasuryRateData.map( a => {
            return a[ term ]
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: labels,
                datasets: [ dataSet ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                }
            }
        }

        return graphData

    }

    const createEconomicIndicatorGraph = ( indicatorData, startDate ) => {

        //indicatorData.reverse()

        if( startDate && startDate != 'all' ) {
            indicatorData = economicIndicatorDetails.data.filter( a => moment( a.date, 'YYYY-MM-DD' ).isAfter( moment( startDate, 'YYYY-MM-DD' ) ) )
        } else {
            //stockPriceData = [ ...stockDetails.historicalStockPrices ] 
        }

        //Generate our labels
        let dataPoints = indicatorData.map( ( a, index ) => {
            return a.date
        })

        //Generate datasets
        let dataSet = { label: "Value", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }
        
        dataSet.data = indicatorData.map( a => {
            return a.value
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const createPayoutChart = ( payoutData ) => {
        //Generate our labels
        let dataPoints = payoutData.map( ( a, index ) => {
            return a.date
        })

        //Generate datasets
        let dataSet = { label: "Dividends", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }

        dataSet.data = payoutData.map( a => {
            return a.adjDividend
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }
        console.log( graphData)
        return graphData
    }

    const createEpsChartGraph = ( epsData ) => {
        //Generate our labels
        let dataPoints = epsData.map( ( a, index ) => {
            return a.date
        })

        //Generate datasets
        let dataSet = { label: "EPS", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }

        dataSet.data = epsData.map( a => {
            return a.adjDividend
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const createPriceChartGraph = ( stockPriceData, startDate ) => {        
        //Generate our labels
        let dataPoints = stockPriceData.map( ( a, index ) => {
            if( index == 0 || index % 10 == 0 ) return a.date
            else return ""
        })

        //Generate datasets
        let dataSet = { label: "Price", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }
        
        dataSet.data = stockPriceData.map( a => {
            return a.close
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const createEtfPriceChartGraph = ( stockPriceData, startDate ) => {

        if( startDate ) {
            stockPriceData = etfDetails.historicalStockPrices.filter( a => moment( a.date, 'YYYY-MM-DD' ).isAfter( moment( startDate, 'YYYY-MM-DD' ) ) )
        }

        //Generate our labels
        let dataPoints = stockPriceData.map( ( a, index ) => {
            if( index == 0 || index % 10 == 0 ) return a.date
            else return ""
        })

        //Generate datasets
        let dataSet = { label: "Price", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }
        
        dataSet.data = stockPriceData.map( a => {
            return a.close
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const createBasicGraph = ( priceData, startDate ) => {

        if( startDate ) {
            priceData = priceData.filter( a => moment( a.date, 'YYYY-MM-DD' ).isAfter( moment( startDate, 'YYYY-MM-DD' ) ) )
        }

        //Generate our labels
        let dataPoints = priceData.map( ( a, index ) => {
            if( index == 0 || index % 10 == 0 ) return a.date
            else return ""
        })

        //Generate datasets
        let dataSet = { label: "Price", data: [], borderColor: '#24A556', fill: false, backgroundColor: '#181F32' }
        
        dataSet.data = priceData.map( a => {
            return a.close
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                      ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '#24A556',
                      }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData
    }

    const getAllCommodityPrices = async () => {
        let res = await GetCommodityPrices()

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setAllCommodityPrices( res.body ) 
    }

    const getAllCurrencyPrices = async () => {
        let res = await GetCurrencyRates()
        
        if( res.status !== 200 ) {
            return { error: res.error }
        }
        
        setAllCurrencyPrices( res.body )
    }

    const getAllCryptoPrices = async () => {
        let res = await GetCryptoPrices()
        
        if( res.status !== 200 ) {
            return { error: res.error }
        }
        
        setAllCryptoPrices( res.body )
    }

    const getCommodityDetails = async ( commodity ) => {
        let res = await GetHistoricalStockPrices( -1825, commodity, false, false )

        if( res.status !== 200 ) {
            return { error: res.error }
        }
        
        setCommodityDetails( { 
            indicator: commodity,
            data: res.body
        } )
    }

    const getCurrencyDetails = async ( currency ) => {
        let res = await GetHistoricalStockPrices( -1825, currency, false, false )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setCurrencyDetails( { 
            indicator: currency,
            data: res.body
        } )
    }

    const getCryptocurrencyDetails = async ( currency ) => {
        let res = await GetHistoricalStockPrices( -1825, currency, false, false )

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setCryptocurrencyDetails( {
            indicator: currency,
            data: res.body
        })
    }

    const getAllMarketRiskPremiums = async() => {
        let res = await GetMarketRiskPremiums()

        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setAllMarketRiskPremiums( res.body )
    }

    const getMarketStatus = async () => {
        let res = await GetMarketStatus()
        
        if( res.status !== 200 ) {
            return { error: res.error }
        }

        setMarketStatus( res.body )
    }

    const deleteAccount = async() => {
        let status = await DeleteAccount()

        setUser( await GetUserData( true ) )
        setIsDefaultUser( true )
        setLoggedIn( true )
        return status
    }

    const calculateWatchlistReturn = async ( watchlistId, timeFrame ) => {
        let stockPriceData = await CalculateWatchlistReturn( watchlistId, timeFrame )

        if( stockPriceData.status !== 200 ) {
            //handle error
            return { error: stockPriceData.error }
        }

        //calculate return => for each stock, purchase units, then calculate return for each friday
        let totalValue = [  ]
        let totalInvestment = 100000

        stockPriceData.body.forEach( ( stock, index ) => {
            let beginningBalance = totalInvestment / stockPriceData.body.length

            //Loop over the stock prices
            stock.historical.forEach( ( day, index ) => {
                //if this is the start, buy our initial units
                if( index === 0 ) {
                    totalValue.push( { date: day.date, value: beginningBalance } )
                } else {
                    let previousDayPrice = stock.historical[ index - 1 ]
                    let currentDayPrice = stock.historical[ index ]

                    //calculate return
                    let periodReturnPercent = ( ( currentDayPrice.close - previousDayPrice.close ) / previousDayPrice.close ) + 1
                    let periodReturn = beginningBalance * periodReturnPercent

                    totalValue.push( { date: currentDayPrice.date, value: periodReturn } )
                    beginningBalance = periodReturn
                }
            })
        })
        
        //get unique dates
        let uniqueDates = _.uniqBy( totalValue, a => a.date )
        let dailyTotals = []
        
        uniqueDates.forEach( day => {
            let valuesForDay = totalValue.filter( a => a.date === day.date )
            let total = 0
            valuesForDay.forEach( a => {
                total += a.value
            })
            dailyTotals.push( { date: day.date, value: total })
        })

        if( dailyTotals && dailyTotals.length > 0 ) {
            setHomepageWatchlistValue( dailyTotals[ dailyTotals.length - 1 ].value.toFixed( 2 ) )
        } else {
            setHomepageWatchlistValue( '0.00' )
        }

        //create graph data
        //Generate our labels
        let dataPoints = dailyTotals.map( ( a, index ) => {
            return a.date
        })

        //Generate datasets
        let dataSet = { label: "Value", data: [], borderColor: '#24A556', fill: true, backgroundColor: '#181F32' }

        dataSet.data = dailyTotals.map( a => {
            return a.value
        })

        //create the options
        let graphData = {
            type: 'line',
            data: {
                labels: dataPoints,
                datasets: [ dataSet ]
            },
            options: {
                scales: {
                    x: {
                    ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        color: '24A556',
                        radius: 1
                    }
                    }
                },
                legend: {
                    display: false
                }
            }
        }

        return graphData

    }

    const getWatchlistExposure = async( watchlistId ) => {
        let res = await GetWatchlistExposure( [ watchlistId ] )

        if ( res.status !== 200 ) {
            return { error: res.error }
        }

        //generate graphs
        let sectorGraph = createSectorGraph( res.body.sector )
        let countryGraph = createCountryGraph( res.body.country )
        setWatchlistSectorGraph( sectorGraph )
        setWatchlistCountryGraph( countryGraph )
        return { sectorGraph: sectorGraph, countryGraph: countryGraph }
    }

    const createCountryGraph = ( countryData ) => {
        const graphData = {
            type: 'doughnut',
            data: {
                labels: Object.keys( countryData ).map( a => a ),
                datasets: [{
                  label: 'Country Exposure',
                  data: Object.keys( countryData ).map( a => `${ Number( Number( countryData[ a ].percent ) * 100 ).toFixed( 2 ) }` ),
                  backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    "RGB(240, 128, 128)",
                    "RGB(230, 230, 250)",
                    "RGB(143, 188, 139)",
                    "RGB(205, 133, 63)"
                  ],
                  hoverOffset: 4
                }]
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                    },
                    margin: {
                        left: 0,
                    }
                },
                legend: {
                    display: false,
                    position: 'chartArea',
                    align: 'center',
                    labels: {
                        boxWidth: 10,
                        usePointStyle: true
                    }
                },
                title: {
                    display: true,
                    text: 'Country Exposure',
                }
            }
        }

        return graphData
    }

    const createSectorGraph = ( sectorsData ) => {
        const graphData = {
            type: 'doughnut',
            data: {
                labels: Object.keys( sectorsData ).map( a => a ),
                datasets: [{
                  label: 'Sector Exposure',
                  data: Object.keys( sectorsData ).map( a => `${ Number( Number( sectorsData[ a ].percent ) * 100 ).toFixed( 2 ) }` ),
                  backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    "RGB(240, 128, 128)",
                    "RGB(230, 230, 250)",
                    "RGB(143, 188, 139)",
                    "RGB(205, 133, 63)"
                  ],
                  hoverOffset: 4
                }]
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    margin: {
                        left: 0
                    }
                },
                legend: {
                    display: false,
                    position: 'left',
                    align: 'start',
                    labels: {
                        boxWidth: 10,
                        usePointStyle: true
                    }
                },
                title: {
                    display: true,
                    text: 'Sector Exposure',
                }
            }
        }

        return graphData
    }

    const getMostActive = async() => {
        let res = await GetMostActiveStocks()

        setMostActive( res.body )
    }

    const getHighestGainers = async() => {
        let res = await GetHighestGainers()

        setHighestGainers( res.body )
    }

    const getHighestLosers = async() => {
        let res = await GetHighestLosers()

        setHighestLosers( res.body )
    }

    const getSocialSentiment = async() => {
        let res = await GetSocialSentiment()

        res.body = res.body.sort( ( a, b ) => a.twitterImpressions > b.twitterImpressions )

        setSocialSentiment( res.body )
    }

    const getEarningsAndFinancialsRatios = async( timeFrame, symbol ) => {
        try {
            let earningsAndFinancials = await GetEarningsAndFinancialsRatios( timeFrame, symbol )
            
            if( earningsAndFinancials.status !== 200 ) {
                return { error: earningsAndFinancials.error }
            }

            setEarningsAndFinancialsRatios( earningsAndFinancials.body )

        } catch ( e ) {

        }
    }

    const createEarningsAndFinancialChart = ( dataPoints ) => {

            //format the data into a chart
            //1) Create the labels
            let labels = earningsAndFinancialsRatios.map( a => a.date )
            
            //2) For each bar to be included, create an object with label, datapoints, and other options
            let chartData = {
                labels: labels
            }

            let datasets = []
            dataPoints.forEach( ( dataPoint, index ) => {
                
                let obj ={
                    label: earningsAndFinancials[ dataPoint ].value,
                    data: earningsAndFinancialsRatios.map( a => a[ dataPoint ].toFixed( 2 ) ),
                    borderColor: 'black',
                    backgroundColor: earningsAndFinancials[ dataPoint ].background,
                    hidden: index > 1 ? true : false
                }
                datasets.push( obj )
            })

            chartData.datasets = datasets

            let earningsAndFinancialChart = {
                type: 'bar',
                data: chartData
            }
            
            return earningsAndFinancialChart
    }

    return (
        <GlobalStateContext.Provider // this is where we expose state values and functions to the rest of the application
            value={{
                state: { // for organization reasons I like keep all state values inside a state object
                    searchSymbol,
                    selectedStock,
                    incomeStatement,
                    balanceSheet,
                    cashFlow,
                    keyFinancialRatios,
                    stockMetrics,
                    stockScreenerOptions,
                    stockScreenerResults,
                    stockPriceTimeline,
                    graphData,
                    loggedIn,
                    user,
                    upcomingEarnings,
                    dividendHistory,
                    stockDetails,
                    indexes,
                    homepageIndexes,
                    indexDetails,
                    stockReturn,
                    allSectorPerformance,
                    allIndustryPriceToEarnings,
                    allSectorPriceToEarnings,
                    sectorDetails,
                    treasuries,
                    economicIndicators,
                    allIndustryPerformance,
                    economicIndicatorDetails,
                    etfDetails,
                    allCommodityPrices,
                    allCurrencyPrices,
                    allCryptoPrices,
                    commodityDetails,
                    currencyDetails,
                    cryptocurrencyDetails,
                    allMarketRiskPremiums,
                    marketStatus,
                    app,
                    industryDetails,
                    earningsHistory,
                    economicCalendar,
                    dividendCalendar,
                    isDefaultUser,
                    isSearchOpen,
                    searchHeldOpenForModal,
                    homepageWatchlistValue,
                    watchlistSectorGraph,
                    watchlistCountryGraph,
                    selectedWatchlist,
                    symbolsForMarketNews,
                    mostActive,
                    highestGainers,
                    highestLosers,
                    socialSentiment,
                    earningsAndFinancialsRatios,
                    screenerResultsLength,
                    screenerPreFilterResultsLength,
                    symbolType
                },
                getSocialSentiment,
                setSymbolsForMarketNews,
                setSearchHeldOpenForModal,
                setSelectedWatchlist,
                setSelectedStock,
                updateSearchSymbol: ( char ) => updateSearchSymbol( char ),
                getIncomeStatement: ( bool, str ) => getIncomeStatement( bool, str ),
                getBalanceSheet: ( bool, str ) => getBalanceSheet( bool, str ),
                getCashFlow: ( bool, str ) => getCashFlow( bool, str ),
                getKeyFinancialRatios: ( bool, str ) => getKeyFinancialRatios( bool, str ),
                getStockMetrics: ( bool, str ) => getStockMetrics( bool, str),
                setGraphData: ( obj ) => setGraphData( obj ),
                setStockScreenerOptions: ( obj ) => setStockScreenerOptions( obj ),
                getStockPriceTimelineData: () => getStockPriceTimelineData(),
                getStockScreenerResults,
                getUpcomingEarnings: () => getUpcomingEarnings(),
                savePosition: ( str, bool ) => savePosition( str, bool ),
                deletePosition: ( str, char ) => deletePosition( str, char ),
                getDividendHistory,
                tickerSearch,
                getMajorIndexes,
                getIndexDetails,
                removeUserIndex,
                addUserIndex,
                calculateReturn,
                getAllSectorPerformance,
                getAllIndustryPriceToEarnings,
                getAllSectorPriceToEarnings,
                getSectorDetails,
                getTreasuriesData,
                createRateChartGraph,
                createYieldCurveGraph,
                executeSearch,
                createPriceChartGraph,
                getEconomicIndicatorData,
                getAllIndustryPerformance,
                getEconomicIndicatorDetails,
                createEconomicIndicatorGraph,
                calculateWatchlistReturn,
                getEtfDetails,
                createEtfPriceChartGraph,
                getAllCommodityPrices,
                getAllCurrencyPrices,
                getAllCryptoPrices,
                getCommodityDetails,
                createBasicGraph,
                getCurrencyDetails,
                getCryptocurrencyDetails,
                getAllMarketRiskPremiums,
                getMarketStatus,
                openConnectionDatabase,
                createUserAccount,
                logOut,
                logInUser,
                loginAsDefaultUser,
                checkAuthenticationStatus,
                getUserData,
                getIndustryDetails,
                getEarningsHistory,
                getEconomicCalendarEvents,
                logInGoogleUser,
                logInAppleUser,
                loginFacebookUser,
                getDividendCalendar,
                deleteAccount,
                createEpsChartGraph,
                setIsSearchOpen,
                getWatchlistExposure,
                getHistoricalSectorPerformance,
                getMostActive,
                getHighestGainers,
                getHighestLosers,
                getEarningsAndFinancialsRatios,
                createEarningsAndFinancialChart,
                setScreenerResultsLength,
                signUpGoogleUser,
                getSymbolType,
                createPayoutChart
            }}
        >
            { props.children }
        </GlobalStateContext.Provider>
    )
}

export default GlobalStateProvider;
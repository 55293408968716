import React, { useEffect } from 'react'
import { ArrowRightCircle, Heart } from 'react-feather'

export default function Indexes( props ) {

    const { indexes } = props.globalContext.state
    const { getMajorIndexes, removeUserIndex, addUserIndex } = props.globalContext
    const { user } = props.userContext.state

    useEffect( () => {
        if( user && !indexes ) getMajorIndexes( user._id.toString() )
    })

    if( !indexes ) return <main>Loading...</main>

    return (
        <main style={{marginTop:65,marginLeft:300}}>
            <div>
                <div>
                    <span>Major Indexes</span>
                </div>
                <div className="col-12 row">
                    { indexes.map( index => {
                        return (
                                <div class="card" style={{width:'12rem'}}>
                                    <div class="card-body">
                                        <h5 class="card-title"> 
                                            { index.name } 
                                            <ArrowRightCircle size={ 16 } style={{cursor:"pointer"}} onClick={ () => props.history.push( encodeURI( `/indexes/${ index.symbol }` ) ) } />

                                            { index.userIndex && <Heart color='white' fill='red' onClick={ () => removeUserIndex( index.userIndexId.toString() ) } /> }

                                            { !index.userIndex && <Heart color='black' fill='white' onClick={ () => addUserIndex( index.symbol )} /> }
                                            </h5>
    
                                        <h6 class="card-text">Price: { index.price } </h6>
                                        <h6 class="card-text">Change: { index.change.toFixed( 2 ) }pts </h6>
                                        <h6 className="card-text">Percent: { Number( index.changesPercentage ).toFixed( 2 ) }%</h6>
                                    </div>
                                </div>
                                )
                            })}
                        )
                </div>
            </div>
        </main>
    )

}
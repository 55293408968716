import React, { useState, useEffect } from 'react'
import { GetStockOverview } from '../utils/realmFunctions'
import { ArrowRight } from 'react-feather'

export default function StockDetailsStockOverview( props ) {

    const [ stockOverview, setStockOverview ] = useState( false )

    useEffect( () => {
        getStockOverview()
    }, [ props.match.params.stockSymbol ] )

    async function getStockOverview() {
        try {
            if( stockOverview ) setStockOverview( false )

            let overview = await GetStockOverview( props.match.params.stockSymbol )

            if( overview.status !== 200 ) {
                //handle error
                setStockOverview( [] )
            }

            setTimeout( () => {
                setStockOverview( overview.body )
            }, 500)
        } catch( e ) {
            throw e
        }        
    }

    if( !stockOverview ) return (
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    )

    const protectValue = ( value ) => {
        if( !value ) return ''
        return value.toLocaleString()
    }

    return (
        <div className=''>
            <div>
                { stockOverview.description }
            </div>
            {/* <div className="invoice-header">
                <ul className="big-fields mt-0">
                    <li className="">
                        <img style={{width:50,height:50}} src={ stockOverview.image }></img>
                    </li>
                    <li>
                        <span>CEO</span>
                        <h6> { stockOverview.ceo } </h6>
                    </li>
                    <li>
                        <span>Headquarters</span>
                        <h6> { stockOverview.city }, { stockOverview.state } </h6>
                    </li>
                    <li>
                        <span>Sector</span>
                        <h6> { stockOverview.sector } </h6>
                    </li>
                    <li>
                        <span>IPO Date</span>
                        <h6> { stockOverview.ipoDate } </h6>
                    </li>
                    <li>
                        <span>Employees</span>
                        <h6> { protectValue( stockOverview.fullTimeEmployees ) } </h6>
                    </li>
                    <li>
                        <span>ISIN</span>
                        <h6> { stockOverview.cik } </h6>
                    </li>
                    <li>
                        <span>CIK</span>
                        <h6> { stockOverview.isin } </h6>
                    </li>
                    <li>
                        <span>CUSIP</span>
                        <h6> { stockOverview.cusip } </h6>
                    </li>
                    <li>
                        <span>Website</span>
                        <h6> { stockOverview.website } </h6>
                    </li>
                </ul>
            </div> */}

            <div className='hover-cursor mt-2' onClick={ () => {
                //handleWatchlistRoute( watchlistDetails.id )
            }} style={{color:'#24A556',fontSize:'.9rem'}}>
                <b>Read more</b>
            </div>
        </div>
    )
}
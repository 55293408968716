import React, { useState, useEffect } from 'react'
import { CheckCircle, Plus, PlusCircle } from 'react-feather'
import { toast } from 'react-toastify'
import { GetStockHeader } from '../utils/realmFunctions'
import $ from 'jquery'

export default function StockDetailsHeader( props ) {

    const [ stockHeader, setStockHeader ] = useState( false )
    const { userWatchlistsByStockPresence } = props.userContext.state
    const { getUserWatchlistsAndDetermineIfSelectedStockInWatchlist, addToWatchlist } = props.userContext
    const { isDefaultUser } = props.globalContext.state

    useEffect( () => {
        getStockHeader()
    }, [ props.match.params.stockSymbol ] )

    const addToWatchListHandler = async ( watchlistId ) => {

        if( isDefaultUser ) {
            console.log( 'here' )
            $( '#signUpModal' ).modal( 'toggle' )
            return
        }

        await addToWatchlist( props.match.params.stockSymbol, watchlistId )
        await getUserWatchlistsAndDetermineIfSelectedStockInWatchlist( props.match.params.stockSymbol )
        toast( 'Added to watchlist!' )
    }

    async function getStockHeader() {

        if( stockHeader ) setStockHeader( false )

        let header = await GetStockHeader( props.match.params.stockSymbol )

        if( header.status !== 200 ) {
            //handle error
            setStockHeader( [] )
        }

        await getUserWatchlistsAndDetermineIfSelectedStockInWatchlist( props.match.params.stockSymbol )

        setTimeout( () => {
            setStockHeader( header.body )
        }, 500)
        
    }

    if( !stockHeader ) return (
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    )
    
    return (
        <div style={{width:'100%',marginTop:30}} className="d-flex" data-offset="100" ref={ props.priceChartRef }>
            <div style={ { minWidth:'60%'} }>
                <div className='row'>
                    <div className="d-flex">
                        <span className="h5" style={{marginRight:'2rem'}}>
                            <b>{ stockHeader.name }</b>
                        </span>
                        <span className="symbol-container-primary" style={{paddingTop:'1vh'}}>
                            { stockHeader.symbol }
                        </span>
                    </div>
                    <div className="d-flex" style={{marginTop:10}} >
                        <span className="h5">
                            ${ stockHeader.price.toFixed( 2 ) }
                        </span>
                        <span className="h5" style={{marginLeft:15,marginRight:15}}>
                            |
                        </span>
                        <span className='h6'>
                            <text style={{color: stockHeader.changes > 0 ? 'green' : 'red' }}>${ stockHeader.changes.toFixed( 2 ) }</text>
                        </span>
                        <span className="h5" style={{marginLeft:15,marginRight:15}}>
                            |
                        </span>
                        <span className='h6'>
                            <text style={{color: stockHeader.changesPercentage > 0 ? 'green' : 'red' }}>{ stockHeader.changesPercentage }%</text>
                        </span>
                    </div>
                    <div className='d-flex' style={{marginBottom: 25,zIndex:100}}>
                        <div class="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-jfa-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                                <Plus/> Add to Watchlist
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style={{width:'100%',color:"#C2D7E4"}}>
                                { userWatchlistsByStockPresence && userWatchlistsByStockPresence.map( watchlist => {
                                    return (
                                        <React.Fragment>
                                            <li className='d-flex justify-content-between'>
                                                { watchlist.symbolAlreadyExists && 
                                                    <div className='d-flex justify-content-between dropdown-item flex-nowrap w-100'>
                                                        <span class="">
                                                            { watchlist.name }
                                                        </span>
                                                        <span className=''>
                                                            { watchlist.symbolAlreadyExists && <CheckCircle color='#20A5A1' /> }
                                                        </span>
                                                    </div>
                                                    
                                                }
                                                { !watchlist.symbolAlreadyExists && 
                                                    <span class="dropdown-item" onClick={ () => addToWatchListHandler( watchlist._id.toString() )} >
                                                        { watchlist.name }
                                                        { !watchlist.symbolAlreadyExists && <PlusCircle /> }
                                                    </span>
                                                }
                                            </li>
                                        </React.Fragment>
                                    )
                                })}
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style={ { width:'40%',backgroundColor:"#1f2944",padding:"10px 10px 10px 10px",borderRadius:10} }>
                <div className='d-flex'>
                    <div className="col-12">
                        <label for="customRange2" class="">52 Week Range:</label>
                        <input type="range" class="form-range" value={ stockHeader.price } min={ stockHeader.minRange } max={ stockHeader.maxRange } id="customRange2" disabled></input>
                        <label style={{float:'left'}}>{ stockHeader.minRange }</label>
                        <label style={{float:'right'}}>{ stockHeader.maxRange }</label>
                    </div>
                </div>
                
                <div style={{borderRadius:'.3rem'}}>
                    <div className='d-flex text-center pb-2' style={{width:'100%',borderBottom:'1px solid #2e3c5b'}}>
                        <span style={{width:'50%'}}>Volume: { stockHeader.volume.toLocaleString() }</span>
                        <span style={{width:'50%'}}>Average Volume: { stockHeader.avgVolume.toLocaleString() }</span>
                    </div>
                    <div className='d-flex text-center mt-2' style={{width:'100%'}}>
                        <span style={{width:'50%'}}>Price to Earnings: { stockHeader.pe?.toLocaleString() }</span>
                        <span style={{width:'50%'}}>Dividend Yield: { stockHeader.dividendYielPercentageTTM?.toLocaleString() }%</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ArrowRight, User } from 'react-feather'
import SearchBar from '../components/SearchBar'
import VestifyLogo from '../images/vestify-logo-v1.png'
import $ from 'jquery'

export default withRouter(MenuBarComponent)

function MenuBarComponent( props ){

    const { user, isDefaultUser } = props.globalContext.state
    const { logOut } = props.globalContext

    const openLoginModal = () => {
        $( '#signInModal' ).modal( 'toggle' )
        $('#signInModal').modal({
            keyboard: false,
            backdrop: 'static'
        })
    }

    const openSignUpModal = () => {
        $( '#signUpModal' ).modal( 'toggle' )
        $('#signUpModal').modal({
            keyboard: false
        })
    }

    return ( 
        <nav className="navbar fixed-top" style={{backgroundColor:"#181F32"}}>
            <div style={{ minWidth:'80%',marginLeft:'10%',marginRight:'10%',minHeight:50,borderBottom:'2px solid #45465C'}} >
                <div className="d-flex justify-content-between" style={{marginBottom:0,paddingBottom:0,opacity:100}}>
                    <div className="" style={{color:"#24A556",marginTop:20}}>
                        <div onClick={ () => props.history.push( '/' )}>
                            <div className="h4 mt-2" style={{letterSpacing:'.05rem'}}>
                                <img src={VestifyLogo} height={50} />
                            </div>
                        </div>
                    </div>
                    <div className='' style={{ width:'50%',color:'white',paddingRight:0,paddingLeft:0 }}>
                        <div className='d-flex'>
                            <div style={{marginTop:20,width:'75%'}}>
                                <SearchBar onClick={ () => props.history.push( '/' )} { ...props } globalContext={ props.globalContext } />
                            </div>
                           <div style={{marginLeft:20,backgroundColor:'#20A5A1',paddingTop:0,marginTop:0,borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingLeft:10,paddingRight:10,width:'25%'}}>
                                { !isDefaultUser &&
                                    <span className="text-center pt-3" >
                                        <User size={24} className="my-3" style={{marginRight:8}} />
                                        <span onClick={ () => props.history.push( '/user-profile' ) } className="hover-cursor">{ user.firstName }</span>
                                        <span className="mx-2">|</span>
                                        <ArrowRight onClick={ logOut } color={'white'} fill={'white'} className="hover-cursor" style={{color:'black'}}>Sign out</ArrowRight>
                                    </span>
                                } 
                                {
                                    isDefaultUser && 
                                        <div className='d-flex justify-content-between pt-3'>
                                            <div className="text-center  hover-cursor" onClick={() => openLoginModal()}>
                                                Log In
                                            </div>
                                            <div className='border-end'></div>
                                            <div className="text-center hover-cursor" onClick={ () => openSignUpModal()}>
                                                Sign Up
                                            </div>
                                        </div>
                                        
                                    
                                }
                           </div>
                        </div>
                        <div className="d-flex mt-2" style={{justifyContent: 'space-between'}}>
                            <span onClick={ () => props.history.push( '/watchlists' ) } className="col-2 hover-cursor" style={{minHeight:50,paddingTop:'.25rem'}}>Watchlists</span>
                            <span onClick={ () => props.history.push( '/stock-screener' ) }  className="col-2 hover-cursor" style={{minHeight:50,paddingTop:'.25rem'}}>Screener</span>
                            <span onClick={ () => props.history.push( '/market-news' ) }  className="col-2 hover-cursor" style={{minHeight:50,paddingTop:'.25rem'}}>News</span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
import React, { useState, useEffect } from 'react'
import ETFDetails from '../ETFDetails'
import StockDetails from '../StockDetails'

export default function SymbolDetails ( props ) {

    const { symbolType } = props.globalContext.state
    const { getSymbolType } = props.globalContext


    useEffect( () => {
        
        if( !symbolType || symbolType.value !== props.match.params.stockSymbol ) {
            getSymbolType( props.match.params.stockSymbol )
        }

    }, [ props.match.params.stockSymbol ] )

    if( !symbolType ) {
        return (
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        )
    }

    if( symbolType && symbolType.symbol !== props.match.params.stockSymbol ) {
        return (
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        )
    }

    if( symbolType && symbolType.type === 'etf' ) {
        return <ETFDetails { ...props } />
    }

    if( symbolType && symbolType.type === 'stock' ) {
        return <StockDetails { ...props } />
    }

    return <div></div>
}
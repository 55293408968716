import React, { useState, useEffect } from 'react'
import { ResetPassword } from '../utils/realmFunctions'
import { toast } from 'react-toastify'

export default function UserProfile( props ) {

    const { user, isDefaultUser } = props.globalContext.state
    const [ changePasswordLoading, setChangePasswordLoading ] = useState( false )

    if( !user ) return <main></main>

    const resetPasswordHandler = async() => {
        setChangePasswordLoading( true )

        let res = await ResetPassword()

        if( res.error ) {
            toast( res.error )
            setChangePasswordLoading( false )
            return
        }

        setChangePasswordLoading( false )
        toast( 'An email has been sent with instructions to change your password.')
        return
    }

    return (
        <main style={{marginTop:150,marginRight:'10%',marginLeft:'10%',color:'white'}}>
            <div>
                <h4>User Profile</h4>
            </div>
            <div className="d-flex">
                <div className="invoice-header mt-2">
                    <ul className="big-fields">
                        <li className="">
                            <span>Name</span>
                            { user.name }
                        </li>
                        <li className="">
                            <span>User Id</span>
                            { user.userId }
                        </li>
                        <li className="">
                            <span>User Email</span>
                            { user.userEmail }
                        </li>
                        <li className="">
                            <span>Auth Provider</span>
                            { user.authProvider }
                        </li>
                    </ul>
                </div>
            </div>
            <div className='d-flex'>
                {/* { !isDefaultUser && <button data-toggle="modal" data-target="#deleteAccountModal" className='btn btn-primary'>Delete Account</button> } */}

                { user.authProvider && user.authProvider === 'email' && !changePasswordLoading &&
                    <button onClick={ resetPasswordHandler } className='mx-2 btn btn-primary'>Change Password</button>
                }

                { user.authProvider && user.authProvider === 'email' && changePasswordLoading &&
                    <button className='mx-2 btn btn-primary'>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                }
            </div>
        </main>
    )
}
import React, { useState, useEffect } from 'react'
import { GetEtfExposure } from '../utils/realmFunctions'

export default function StockDetailsEtfExposure( props ) {

    const [ etfExposure, setEtfExposure ] = useState( false )

    useEffect( () => {
        getEtfExposure()
    }, [ props.match.params.stockSymbol ] )

    async function getEtfExposure() {
        try {
            if( etfExposure ) setEtfExposure( false )

            let exposure = await GetEtfExposure( props.match.params.stockSymbol )

            if( exposure.status !== 200 ) {
                //handle error
                setEtfExposure( [] )
            }

            setTimeout( () => {
                setEtfExposure( exposure.body )
            }, 500)
        } catch ( e ) {
            setEtfExposure( [] )
        }
    }

    if( !etfExposure ) return (
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    )
    
    return (
        <div style={{maxHeight:250,overflowY:'auto'}} ref={ props.exposureRef }>
            <div className="d-flex">
                <span className="h4 col-10">ETF Exposure</span>
                {/* <span className="h6 col-2 text-right hover-cursor" style={{paddingTop:10}}>View All</span> */}
            </div>   

            <table className="table table-bordered">
                <thead>
                    <th className='w-25 mx-2 pe-4'>
                        <span className='mx-2'>Symbol</span>
                    </th>
                    <th className='w-25'>
                        <span className='mx-2'>Name</span>
                    </th>
                    <th className='w-25'>
                        <span className='mx-2'>Percent</span>
                    </th>
                </thead>
                <tbody className='table table-bordered' style={{color:"#A2ACC3",maxHeight:200,overflowY:'auto'}}>
                    { etfExposure.map( etf => {
                        return (
                            <tr >
                                <td className="col-1">{ etf.etfSymbol }</td>
                                <td className="col-6">{ etf.etfName }</td>
                                <td className="col-3">{ etf.weightPercentage }</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import { toast } from 'react-toastify'

export default function SignIn( props ) {
    
    const [ errors, setErrors ] = useState( false )
    const { logInUser, logInGoogleUser } = props.globalContext

    const signInHandler = async () => {
        let email = document.getElementById( 'loginEmail' )
        let password = document.getElementById( 'loginPassword' )

        if( !email.value || !password.value || email.value === '' || password.value === '' ) {
            setErrors( 'Please enter email and password' )
            return
        }

        let res = await logInUser( email.value, password.value )
        
        if( res && res.error ) {
            setErrors( res.error )
            return
        }

        $( '#signInModal' ).modal('toggle')
        toast( 'You are now logged in...' )
    }

    const googleSignInHandler = async() => {
        let res = await logInGoogleUser()

        if( res.error ) {
            setErrors( res.error )
            return
        }
            
        $( '#signInModal' ).modal('toggle')
        toast( 'Welcome!' )
    }

    const handleModalClose = () => {
        document.getElementById( 'loginEmail' ).value = ''
        document.getElementById( 'loginPassword' ).value = ''
        $( '#signInModal' ).modal( 'toggle' )
    }

    return (
        <div className="modal fade" data-backdrop="static" id="signInModal" aria-hidden="true" aria-labelledby="signInModalLabel" tabIndex="-1" ref={ props.signInModal }>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body" style={{backgroundColor:"#162033",borderRadius:10}}>
                        <div>
                            <span className='text-center' style={{color:'#20A5A1',fontSize:'1.5rem'}}>
                                Sign In
                            </span>  
                        </div>
                        <div className='d-flex mt-4'>
                            <button className='btn btn-primary' onClick={ googleSignInHandler } id="google-auth">Sign In With Google</button>
                        </div>
                        <div>
                            <label htmlFor="exampleInputEmail1" style={{color:"white",paddingTop:'1rem'}}>Email address</label>
                            <input required type="email" style={{backgroundColor:"#7D8AAA",border:'none'}} className="form-control" id="loginEmail" aria-describedby="emailHelp"></input>
                        </div>
                        <div>
                            <label htmlFor="exampleInputPassword1" style={{color:"white",paddingTop:'1rem'}}>Password</label>
                            <input required type="password" style={{backgroundColor:"#7D8AAA",border:'none'}} className="form-control" id="loginPassword"></input>
                        </div>
                        <div className='d-flex justify-content-between mt-4'>
                            <button type="button" className="btn btn-jfa-cancel hover-cursor" onClick={handleModalClose}>Cancel</button>
                            <button type="button" className="btn btn-primary hover-cursor" onClick={ () => signInHandler() } > Log In </button>
                        </div>
                        { errors && <span style={{color:'red'}}>{ errors } </span> }
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import _ from 'lodash'

export default function StockGraphs( props ){
    const { searchSymbol, stockPriceTimeline } = props.globalContext.state
    const { getStockPriceTimelineData } = props.globalContext
    const [ graphData, setGraphData ] = useState( false )

    useEffect( () => {
        if( !searchSymbol ) {
            alert( 'Please enter a stock ticker...' )
            props.history.push('/')
            return
        }

        if( searchSymbol && !stockPriceTimeline ) getStockPriceTimelineData()
        if( searchSymbol && stockPriceTimeline && stockPriceTimeline.symbol != searchSymbol ) getStockPriceTimelineData()

        if( graphData ) {
            var ctx = document.getElementById('myChart').getContext('2d');
            var myChart = new Chart(ctx, graphData)
        }

        if( !graphData && searchSymbol && stockPriceTimeline && stockPriceTimeline.symbol == searchSymbol ) createGraph()
    })

    function createGraph(){
        let datasets = [ { label: 'Price', data: [] } ]
        
        stockPriceTimeline.historical.slice( 0,365 ).forEach( day => {
            datasets[0].data.push( day.close )
        })

        _.reverse( datasets[0].data )

        let data = {
            type: 'line',
            data: {
                labels: datasets[0].data.map( (v, index) => index),
                datasets: datasets
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
        console.log( data )
        setGraphData( data )
        //props.history.push('/graph')
    }

    return (
        <main>
            <canvas id="myChart" width="1200" height="800"></canvas>
        </main>
    )
}
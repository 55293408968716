import React, { useState, useEffect, useRef } from 'react'
import StockDetailsPriceChart from '../components/StockDetailsPriceChart'
import StockDetailsEpsChart from '../components/StockDetailsEpsChart'

export default function StockDetailsCharts( props ) {

    const [ chartToShow, setChartToShow ] = useState( 'priceChart' )
    const [ priceTimeFrame, setPriceTimeFrame ] = useState( -365 )
    const [ epsTimeFrame, setEpsTimeFrame ] = useState( -365 )

    const setWatchlistReturnChartStyles = ( selectedChart ) => {
        if( selectedChart === chartToShow ) {
            return 'text-center stockDetailsSelectedChartType fw-bold hover-cursor active' 
        }

        return 'text-center hover-cursor fw-bold'
    }

    const setChartTimeframeStyles = ( time ) => {
        
        if( chartToShow === 'priceChart' && time === priceTimeFrame ) {
            return 'text-center stockDetailsSelectedChartType fw-bold hover-cursor active' 
        }

        if( chartToShow === 'epsChart' && time === epsTimeFrame ) {
            return 'text-center stockDetailsSelectedChartType fw-bold hover-cursor active' 
        }

        return 'text-center hover-cursor fw-bold'
    }

    return (
        <div>
            <div className='d-flex' style={{borderBottom: '1px solid #45465C',color:'white',verticalAlign:'bottom', marginBottom:25 }}>
                <div style={{width:'25%'}} className="d-flex justify-content-between">
                    <span onClick={ () => setChartToShow( 'priceChart' ) } className={setWatchlistReturnChartStyles( 'priceChart' )}>PRICE</span>
                    <span onClick={ () => setChartToShow( 'epsChart' )} className={setWatchlistReturnChartStyles( 'epsChart' )}>EARNINGS PER SHARE</span>
                </div>
                
                <div style={{width:'50%'}} className="d-flex justify-content-around">
                    { chartToShow === 'priceChart' &&
                        <React.Fragment>
                            <span onClick={ () => setPriceTimeFrame( -30 ) } className={ setChartTimeframeStyles( -30 ) }>1M</span>
                            <span onClick={ () => setPriceTimeFrame( -90 ) } className={ setChartTimeframeStyles( -90 ) }>3M</span>
                            <span onClick={ () => setPriceTimeFrame( -180 ) } className={ setChartTimeframeStyles( -180 ) }>6M</span>
                            <span onClick={ () => setPriceTimeFrame( -365 ) } className={ setChartTimeframeStyles( -365 ) }>1Y</span>
                            <span onClick={ () => setPriceTimeFrame( -1825 ) } className={ setChartTimeframeStyles( -1825 ) }>5Y</span>
                        </React.Fragment>
                    }

                    { chartToShow === 'epsChart' &&
                        <React.Fragment>
                            <span onClick={ () => setEpsTimeFrame( -365 ) } className={ setChartTimeframeStyles( -365 ) }>1Y</span>
                            <span onClick={ () => setEpsTimeFrame( -730 ) } className={ setChartTimeframeStyles( -730 ) }>2Y</span>
                            <span onClick={ () => setEpsTimeFrame( -1095 ) } className={ setChartTimeframeStyles( -1095 ) }>3Y</span>
                            <span onClick={ () => setEpsTimeFrame( -1825 ) } className={ setChartTimeframeStyles( -1825 ) }>5Y</span>
                        </React.Fragment>
                    }
                </div>
                
            </div>

            {/* SHOW THE CHARTS */}
            { chartToShow === 'priceChart' && <StockDetailsPriceChart { ...props } timeFrame={ priceTimeFrame } globalContext={ props.globalContext }  /> }
            { chartToShow === 'epsChart' && <StockDetailsEpsChart { ...props } timeFrame={ epsTimeFrame } globalContext={ props.globalContext } epsTimeFrame={epsTimeFrame} /> }


        </div>
    )
}
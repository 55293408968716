import React, { useState, useEffect } from 'react'

export default function DividendsCalendar( props ) {

    const { dividendHistory, dividendCalendar } = props.globalContext.state
    const { getDividendHistory, getDividendCalendar } = props.globalContext

    useEffect( () => {
        if( !dividendHistory ) getDividendHistory()
    }, [ dividendHistory ] )

    useEffect( () => {
        if( !dividendCalendar ) getDividendCalendar()
    }, [ dividendCalendar ] )

    return (
        <main style={{marginLeft:300, marginTop:65}}>
            <div>
                <h4>Dividend Calendar</h4>
            </div>
            <div className='d-flex'>
                <div className='mx-2' style={{maxHeight:'70rem',overflowY:'auto'}}>
                    <span>Upcoming Dividends</span>
                    <button className='btn btn-primary btn-sm' onClick={ () => getDividendCalendar( true ) } >My Stocks</button>
                    <button className='btn btn-primary btn-sm' onClick={ () => getDividendCalendar() } >Reset</button>
                    <table className='table table-sm'>
                        <thead>
                            <th>Symbol</th>
                            <th>Payment</th>
                            <th>Record Date</th>
                            <th>Payment Date</th>
                        </thead>
                        <tbody >
                            { dividendCalendar && dividendCalendar.map( dividend => {
                                return (
                                    <tr>
                                        <td>{ dividend.symbol }</td>
                                        <td>{ dividend.adjDividend }</td>
                                        <td>{ dividend.recordDate || dividend.date }</td>
                                        <td> { dividend.paymentDate || dividend.date } </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='mx-2' style={{maxHeight:'70rem',overflowY:'auto'}}>
                    <span>Dividend History</span>
                    <button className='btn btn-primary btn-sm' onClick={ () => getDividendHistory( true, -720 ) } >My Stocks</button>
                    <button className='btn btn-primary btn-sm' onClick={ () => getDividendHistory() } >Reset</button>
                    <table className='table table-sm'>
                        <thead>
                            <th>Symbol</th>
                            <th>Payment</th>
                            <th>Record Date</th>
                            <th>Payment Date</th>
                        </thead>
                        <tbody>
                            { dividendHistory && dividendHistory.map( dividend => {
                                return (
                                    <tr>
                                        <td>{ dividend.symbol }</td>
                                        <td>{ dividend.adjDividend }</td>
                                        <td>{ dividend.recordDate }</td>
                                        <td> { dividend.paymentDate } </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </main>
    )
}
import React, { useState, useEffect } from 'react'
import Chart from 'chart.js'
import { GetHistoricalStockPrices } from '../utils/realmFunctions'

export default function StockDetailsPriceChart( props ) {
    const { timeFrame } = props

    const [ priceChart, setPriceChart ] = useState( false )

    const { createPriceChartGraph } = props.globalContext

    useEffect( () => {
        getHistoricalStockPrices()
    }, [ timeFrame, props.match.params.stockSymbol ] )

    const getHistoricalStockPrices = async () => {
        const historicalStockPrices = await GetHistoricalStockPrices( timeFrame, props.match.params.stockSymbol )

        if( historicalStockPrices.status !== 200 ) {
            //handle error
        }

        //Create our price chart data
        let priceChartData = createPriceChartGraph( historicalStockPrices.body )

        //Update the price chart
        if( priceChart ) priceChart.destroy()
        var ctx = document.getElementById( `PriceChart` )
        
        if( !ctx ) return

        ctx.getContext('2d')
        var myChart = new Chart(ctx, priceChartData )
        setPriceChart( myChart )
    }

    return (
        <div style={{width:'50rem'}}>
            <div>
                <canvas id={ `PriceChart` }></canvas>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import FilterBarForMarketNews from '../components/FilterBarForMarketNews'
import { GetMarketNews } from '../utils/realmFunctions'
import { X } from 'react-feather'

export default function MarketNews( props ) {
    const { user, symbolsForMarketNews } = props.globalContext.state
    const { setSymbolsForMarketNews, executeSearch } = props.globalContext
    const { recentlyViewed } = props.userContext.state
    const { getRecentlyViewedArticles, addArticleToRecentlyView } = props.userContext
    const [ marketNews, setMarketNews ] = useState( false )

    useEffect( () => {
        if( !marketNews ) {
            getMarketNews()
            getRecentlyViewedArticles()
        } else if( !recentlyViewed ) {
            getRecentlyViewedArticles()
        }
    }, [ marketNews ] )

    const openArticle = async( article ) => {
        //record that the user click on the article
        await addArticleToRecentlyView( article )

        //open the article
        window.open( article.url , '_blank').focus()

        await getRecentlyViewedArticles()
    }

    useEffect( () => {
        getMarketNews()
    }, [ symbolsForMarketNews ] )

    const getMarketNews = async () => {
        let symbols = false
        let watchlists = false

        if( symbolsForMarketNews ) {
            symbols = symbolsForMarketNews.symbols.map( a => a.symbol )
            watchlists = symbolsForMarketNews.watchlists.map( a => a.value )
        }
        
        let res = await GetMarketNews( 25, false, symbols, watchlists )

        if( res.status !== 200 ) {
            alert( "There was an error!" )
        }

        setMarketNews( res.body )
    }

    const removeItemFromArray = ( index, symbol ) => {
        let type = symbol?.type
        
        if( type && type === 'allWatchlists' ) {
            setSymbolsForMarketNews( { symbols: [ ...symbolsForMarketNews.symbols ], watchlists: [] } )
            return
        } else {
            let array = [ ...symbolsForMarketNews.symbols ]
            array.splice( index, 1 )
            setSymbolsForMarketNews( { symbols: [ array ], watchlists: [  ...symbolsForMarketNews.watchlists ] } )
            return
        }
    }

    return (
        <main style={{marginTop:150,marginLeft:'10%',marginRight:'10%',maxHeight:'100vh'}}>
            <div style={{ color:'#AFB9CC', marginTop:20 }} className='d-flex justify-content-between w-100'>
                {/* <span style={{ color:'#AFB9CC', fontSize:'1.4rem' }}>
                    Market News
                </span> */}
                <FilterBarForMarketNews
                    { ...props }
                    setSymbolsForMarketNews={ setSymbolsForMarketNews }
                    symbolsForMarketNews={ symbolsForMarketNews }
                    executeSearch={ executeSearch }
                    getMarketNews={ getMarketNews }
                />
            </div>
            { symbolsForMarketNews && 
                    <div>
                        { [ ...symbolsForMarketNews.symbols, ...symbolsForMarketNews.watchlists].map( ( symbol, index ) => {
                            return (
                                <span class="badge" style={{backgroundColor:"#45465C"}}>
                                    { symbol.symbol } 
                                    <X onClick={ () => { removeItemFromArray( index, symbol ) } }/> </span>
                            )
                        })}
                    </div>
                }
            
            <div className='d-flex'>
                <div style={{width:'65%',marginRight:25}}>
                    <span style={{ color:'#AFB9CC', fontSize:'1.4rem' }}>
                        Market News
                    </span>
                    <div style={{overflowY:'scroll',maxHeight:'70vh'}}>
                        { marketNews && marketNews.slice(0,25).map( news => {
                            return (
                                <div style={{borderBottom:'2px solid #45465C',minHeight:'5rem',color:'white'}}>
                                    <div className="mt-3">
                                        <span className='hover-cursor' onClick={ () => openArticle( news ) }>{ news.title }</span>
                                    </div>
                                    <div className="mt-3">
                                        <span style={{marginRight:'1rem'}} className="mt-2">{ news.symbol }</span>
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.site }</span>
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.publishedDate.toLocaleString() }</span>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    
                </div>
                <div style={{width:'35%',marginRight:25}}>
                    <span style={{ color:'#AFB9CC', fontSize:'1.4rem' }}>
                        Recently Viewed
                    </span>
                    <div style={{overflowY:'scroll',maxHeight:'70vh'}}>
                        { recentlyViewed && recentlyViewed.slice(0,10).map( news => {
                            return (
                                <div style={{borderBottom:'2px solid #45465C',minHeight:'5rem',color:'white'}}>
                                    <div className="mt-3">
                                        <span className='hover-cursor' onClick={ () => window.open( news.url , '_blank').focus() }>{ news.title }</span>
                                    </div>
                                    <div className="mt-3">
                                        <span style={{marginRight:'1rem'}} className="mt-2">{ news.symbol }</span>
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.site }</span>
                                        <span style={{marginRight:'1rem'}}>|</span>
                                        <span style={{marginRight:'1rem'}}>{ news.publishedDate.toLocaleString() }</span>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>
            
        </main>
    )
}
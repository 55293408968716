import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import 'jquery'
import 'bootstrap'
import 'popper.js'
import './index.css';
import './styles/bootstrap.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContextWrapper from './ContextWrapper';
import './styles/app.scss'
require('dotenv').config()


ReactDOM.render(
  <ContextWrapper>
    <App />
    <ReactTooltip />
  </ContextWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

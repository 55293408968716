import React, { Fragment, useEffect, useState, useRef } from "react";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async'
import { EyeOff, FilePlus, Search } from 'react-feather'
import $ from 'jquery'

const CustomSelect = ( props ) => {
    const globalContext = props.globalContext

    const { executeSearch, setSelectedStock } = globalContext
    const { selectedStock, isDefaultUser } = globalContext.state

    const [ heldOpen, setHeldOpen ] = useState( false )
    const selectRef = useRef( null )
    const redirect = props.history

    useEffect( () => {
        if( globalContext.state.isSearchOpen && heldOpen && !globalContext.state.searchHeldOpenForModal ) {
            //put focus on search bar
            selectRef.current.focus()
            setHeldOpen( false )
        }
    }, [ globalContext.state.searchHeldOpenForModal ] )

    function Option({ innerProps, ...props }) {
        const onClick = e => {
          e.nativeEvent.stopImmediatePropagation();
          e.stopPropagation()
        };
      
        props.innerProps = { ...innerProps, onClick };

        return ( 
            <components.Option { ...props } >
                <div className="d-flex">
                    <div value={ props.data } style={{width:'90%',color: '#45465C'}} onClick={ ( e ) => {
                        selectRef.current.selectOption( props.data )
                        setSelectedStock( props.data )
                        redirect.push( `/stock-details/${ props.value }` )
                        globalContext.setIsSearchOpen( false )
                    } }> { props.label } </div>
                    <button className="btn btn-sm btn-primary" onClick={() => {

                        //Open the sign up modal if current user is default user
                        if( isDefaultUser ) {

                            $( '#signUpModal' ).modal( 'toggle' )
                            $('#signUpModal').modal({
                                keyboard: false
                            })

                        } else {
                            $( '#addSymbolToWatchlistModal' ).modal( 'toggle' )
                            $('#addSymbolToWatchlistModal').modal({
                                keyboard: false
                            })

                            setSelectedStock( props.data )
                            globalContext.setIsSearchOpen( true )
                        }
                        
                        


                        
                    }}> <FilePlus size={10} />Watchlist</button>
                </div>
               
            </components.Option>
        )
    }

    let defaultOptions = [
        {
            "symbol": "AAPL",
            "name": "Apple Inc.",
            "price": 165.29,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "AAPL",
            "label": "AAPL - Apple Inc. - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        },
        {
            "symbol": "MSFT",
            "name": "Microsoft Corporation",
            "price": 279.83,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "MSFT",
            "label": "MSFT - Microsoft Corporation - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        },
        {
            "symbol": "TSLA",
            "name": "Tesla, Inc.",
            "price": 707.73,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "TSLA",
            "label": "TSLA - Tesla, Inc. - NASDAQ",
            "insertedDate": "5/27/2022"
        }
    ]

    if( selectedStock && !defaultOptions.find( a => a.symbol === selectedStock.symbol ) ) {
        defaultOptions.push( selectedStock )
    }

    const styles = {
        control: ( provided ) => ({
            ...provided,
            backgroundColor: '#7D8AAA',
            color: '#45465C',
            border: 'none',
            
        }),
        placeholder: ( styles ) => ({
            ...styles,
            color:"#45465C"
        }),
        default: ( styles => ({
            ...styles,
            color: "#45465C"
        })),
        clearIndicator: ( styles ) => ({
            ...styles,
            color: '#45465C'
        })
    }

    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <Search color={'#45465C'} size={17} />
            </components.DropdownIndicator>
          )
        );
      };

    return (
        <div>
            <AsyncSelect
                defaultOptions={ defaultOptions }
                placeholder={'Search...'}
                isClearable
                styles={styles}
                defaultValue={ selectedStock }
                components={{ Option, DropdownIndicator, IndicatorSeparator: () => null }}
                loadOptions={ ( value ) => executeSearch( value ) }
                onMenuOpen={ () => globalContext.setIsSearchOpen( true ) }
                menuIsOpen={ globalContext.state.isSearchOpen }
                ref={ selectRef }
                onBlur={ () => {
                    if ( $( '#addSymbolToWatchlistModal' ).is(":visible") ) {
                        globalContext.setIsSearchOpen( true ) 
                        globalContext.setSearchHeldOpenForModal( true )
                        setHeldOpen( true )
                    } else {
                        globalContext.setIsSearchOpen( false ) 
                        if( heldOpen ) {
                            setHeldOpen( false )
                        }

                        if( globalContext.searchHeldOpenForModal ) {
                            globalContext.setSearchHeldOpenForModal( false  )
                        }
                    }
                }}
            />
        </div>
    );
};
export default CustomSelect;

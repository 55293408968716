import React, { Fragment, useEffect, useState, useRef } from "react";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'

const CustomSelect = ( props ) => {
    const globalContext = props.globalContext
    const userContext = props.userContext
    const watchlistId = props.watchlistId
    const getWatchlistExposure = props.getWatchlistExposure
    const { addToWatchlist } = userContext
    const { executeSearch } = globalContext
    const [ selectedStock, setSelectedStock ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const isDefaultUser = props.isDefaultUser

    let defaultOptions = [
        {
            "symbol": "AAPL",
            "name": "Apple Inc.",
            "price": 165.29,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "AAPL",
            "label": "AAPL - Apple Inc. - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        },
        {
            "symbol": "MSFT",
            "name": "Microsoft Corporation",
            "price": 279.83,
            "exchange": "NASDAQ Global Select",
            "exchangeShortName": "NASDAQ",
            "type": "stock",
            "value": "MSFT",
            "label": "MSFT - Microsoft Corporation - NASDAQ",
            "insertedDate": "4/16/2022",
            "default": true
        }
    ]


    const styles = {
        control: ( provided ) => ({
            ...provided,
            backgroundColor: '#7D8AAA',
            color: '#45465C',
            border: 'none',
            width:'90%'
        }),
        placeholder: ( styles ) => ({
            ...styles,
            color:"#45465C"
        }),
        default: ( styles => ({
            ...styles,
            color: "#45465C"
        })),
        clearIndicator: ( styles ) => ({
            ...styles,
            color: '#45465C'
        })
    }

    const handleAdd = async ( symbol, watchlistId ) => {

        if( !symbol ) {
            alert( 'Please select a stock to add' )
            return
        }

        setLoading( true )
        let res = await addToWatchlist( symbol, watchlistId )
        
        if ( res.error ) {
            toast( `Error: ${ res.error }` )
            setLoading( false )
            return
        }

        setTimeout( () => {
            toast( 'Stock added!' )
            setLoading( false )
        }, 500)
        
        await getWatchlistExposure( watchlistId )
    }

    const ControlComponent = (props) => {
        return (
                <div styles={ styles.control } class="input-group mb-3 d-flex flex-nowrap">
                    <components.Control { ...props } />
                    
                    { !loading && !isDefaultUser && <span onClick={ ( e ) => {
                            handleAdd( selectedStock.symbol, watchlistId )
                        }} class="input-group-text hover-cursor" style={{backgroundColor:'#28AF61',color:"#162033",border:'none',minWidth:'10%'}} id="basic-addon1">Add</span>
                    }

                    {/* THIS IS USED WHEN IT IS THE DEFAULT USER.  INSTEAD OF ADDING, IT WILL OPEN THE SIGN UP MODAL */}
                    { !loading && isDefaultUser && <span data-toggle="modal" data-target="#signUpModal" class="input-group-text hover-cursor" style={{backgroundColor:'#28AF61',color:"#162033",border:'none',minWidth:'10%'}} id="basic-addon1">Add</span>
                    }

                    { loading && 
                        <span onClick={ ( e ) => {
                            handleAdd( selectedStock.symbol, watchlistId )
                        }} class="input-group-text hover-cursor" style={{backgroundColor:'#28AF61',color:"#162033",border:'none',minWidth:'10%'}} id="basic-addon1">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </span>
                    }
                </div>
        );
    };

    return (
        <div>
            <div class="mb-3">
                <AsyncSelect
                    defaultOptions={ defaultOptions }
                    placeholder={'Search...'}
                    isClearable
                    styles={styles}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Control: ControlComponent }}
                    loadOptions={ ( value ) => executeSearch( value ) }
                    onChange={ ( e ) => setSelectedStock( e ) }
                />
            </div>
        </div>
        
    );
};
export default CustomSelect;
